import './AdminRoles.css';
import { collection, query, where, onSnapshot, getFirestore, setDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ArrowUploadRegular, CheckmarkRegular, DeleteRegular, DismissRegular, EditRegular, ImageSearchRegular, PersonAddRegular, PersonRegular } from "@fluentui/react-icons";
import { useEffect, useRef, useState } from 'react';

const db = getFirestore();

export default function AdminRoles() {
    const [selection, setSelection] = useState(0);
    const [adminList, setAdminList] = useState([]);
    // FORM
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    // DEBUG
    const [errorMessage, setErrorMessage] = useState('');



    useEffect(() => {
        const q = query(collection(db, "admins"), where("level", "==", selection));
        onSnapshot(q, (querySnapshot) => {
            const array = [];
            querySnapshot.forEach((doc) => {
                var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                array.push({
                    key: doc.id,
                    name: doc.data().name,
                    email: doc.data().email,
                    level: doc.data().level,
                    photoURL: doc.data().photoURL
                })
                // array.push(doc.data());
            });
            console.log(array);
            setAdminList(array);
        });
    }, [selection]);

    const addAdmin = async e => {
        e.preventDefault();
        try {
            await setDoc(doc(db, "admins", email), {
                name: name,
                email: email,
                level: selection
            });
            setName('');
            setEmail('');
        } catch (error) {
            console.log(error);
            alert(error.code);
        }

    }



    return (
        <div className="page">
            <h1>Roles de administradores</h1>
            <p>En esta pagina se definen los usuarios administrador con sus respectivos niveles de acceso.</p>

            <section className="admin-roles-cards-container">
                <div className="admin-roles-card" style={selection === 0 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 0</h6></div>
                    <h3>Super Admin</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(0)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 1 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 1</h6></div>
                    <h3>Afiliacion</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(1)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 2 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 2</h6></div>
                    <h3>Polizas</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(2)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 3 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 3</h6></div>
                    <h3>Finanzas</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(3)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 4 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 4</h6></div>
                    <h3>UDO</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(4)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 5 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 5</h6></div>
                    <h3>Seguridad social</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(5)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 6 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 6</h6></div>
                    <h3>Planificador</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(6)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 7 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 7</h6></div>
                    <h3>Prensa</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(7)}>Seleccionar</button>
                </div>
                <div className="admin-roles-card" style={selection === 8 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 8</h6></div>
                    <h3>Legal Admin</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(8)}>Seleccionar</button>
                </div>

                <div className="admin-roles-card" style={selection === 9 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 9</h6></div>
                    <h3>Legal</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(9)}>Seleccionar</button>
                </div>

                <div className="admin-roles-card" style={selection === 99 ? { border: '2px solid var(--color-primary)' } : { border: 'none' }}>
                    <div className='admin-roles-card-caption'><PersonRegular fontSize={18} /><h6>Nivel 99</h6></div>
                    <h3>Sin Acceso</h3>
                    <button className='text-button' style={{ marginLeft: '-0.75rem' }} onClick={() => setSelection(99)}>Seleccionar</button>
                </div>
            </section>

            <h2>Nivel {selection}</h2>

            <form className='admin-roles-form' onSubmit={addAdmin}>
                <div className='input-container'>
                    <input className='input-normal' id="admin-name" type="text" value={name} onChange={e => { setName(e.target.value) }} />
                    <label className='input-label' htmlFor='admin-name'>Nombre</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="admin-email" type="email" value={email} onChange={e => { setEmail(e.target.value) }} />
                    <label className='input-label' htmlFor='admin-email'>Email</label>
                </div>
                <button className='filled-button' type='submit'><PersonAddRegular fontSize={24} />Agregar</button>
            </form>

            <p style={{ color: 'var(--color-error)' }}>{errorMessage}</p>

            <table className='admin-roles-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Foto</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Nivel</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        adminList.map(data =>
                            <AdminRow key={data.key} document={data.key} photoURL={data.photoURL} name={data.name} email={data.email} level={data.level} />
                        )
                    }
                </tbody>
            </table>



            <h3 style={{ marginBottom: '0.5rem' }}>Privilegios</h3>

            <Privilegios level={selection} />

            <p style={{ marginTop: '1rem' }}><small>*Se recomienda que las fotografias tengan formato 1:1, no excedan 100kb de peso y en formato PNG o JPG. Se puede usar esta app para comprimir https://squoosh.app</small></p>
        </div>
    );
}

function AdminRow(props) {
    const [enabled, setEnabled] = useState(false);

    const [name, setName] = useState(props.name);
    const [level, setLevel] = useState(props.level);

    const inputFile = useRef(null);

    const updateDocument = async () => {
        try {
            await updateDoc(doc(db, "admins", props.document), {
                name: name,
                level: level
            });
            setEnabled(false)
        } catch (error) {
            console.log(error);
            console.log(error.code);
            alert(error.code);
        }
    }

    function handleChange(event) {
        var file = event.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, 'admins/' + props.document + '/thumbnail.jpg');

        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            getDownloadURL(ref(storage, snapshot.metadata.fullPath)).then(async url => {
                await updateDoc(doc(db, "admins", props.document), {
                    photoURL: url
                });
                console.log('URL', url);
            })
        });
    }

    return (
        <tr>
            <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleChange} />

            <td><button className="icon-button" onClick={() => setEnabled(!enabled)}> {enabled ? <DismissRegular fontSize={24} /> : <EditRegular fontSize={24} />}</button></td>
            <td>
                <div>
                    {
                        props.photoURL ?
                            <img onClick={() => inputFile.current.click()} className='admin-avatar' src={props.photoURL}></img> :
                            <button className='icon-button' onClick={() => inputFile.current.click()}>
                                <ImageSearchRegular fontSize={24} />
                                {/* <p>Upload an image</p> */}
                            </button>
                    }

                </div>
            </td>
            <td>
                {
                    enabled ?
                        <div className='input-container'>
                            <input className='input-normal' id={document + ' ' + 'id'} type="text" value={name} onChange={e => setName(e.target.value)} />
                        </div> :
                        props.name
                }
            </td>
            <td>{props.email}</td>
            <td>
                {
                    enabled ?
                        <div className='input-container'>
                            <input className='input-normal' id={document + ' ' + 'id'} type="number" value={level} onChange={e => setLevel(parseInt(e.target.value))} />
                        </div> :
                        props.level
                }
            </td>
            <td>
                {
                    enabled && <button className="btn-icon" onClick={updateDocument}><CheckmarkRegular fontSize={24} /></button>
                }
            </td>
        </tr>
    );
}

function Privilegios({ level }) {
    switch (level) {
        case 0:
            return (
                <div>
                    <ul>
                        <li>Acceso total de lectura.</li>
                        <li>Acceso total de escritura.</li>
                    </ul>
                </div>
            );
        case 1:
            return (
                <div>
                    <ul>
                        <li>Acceso total de lectura.</li>
                        <li>Acceso de escritura limitado a afiliacion.</li>
                    </ul>
                </div>
            );
        case 2:
            return (
                <div>
                    <ul>
                        <li>Acceso total de lectura.</li>
                        <li>Acceso de escritura limitado a polizas.</li>
                    </ul>
                </div>
            );
        case 3:
            return (
                <div>
                    <ul>
                        <li>Acceso total de lectura.</li>
                        <li>Acceso de escritura limitado a finanzas.</li>
                    </ul>
                </div>
            );
        case 4:
            return (
                <div>
                    <ul>
                        <li>Acceso unicamente al planificador.</li>
                    </ul>
                </div>
            );
        case 5:
            return (
                <div>
                    <ul>
                        <li>Seguridad social.</li>
                    </ul>
                </div>
            );
        case 99:
            return (
                <div>
                    <ul>
                        <li>Estos usuarios no tienen acceso de escritura de la base de datos.</li>
                    </ul>
                </div>
            );
        default:
            return (
                <p>Selecciona un rol.</p>
            );
    }
}