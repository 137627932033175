import './NavigationDrawer.css';
import {
    HomeRegular,
    HomeFilled,
    DocumentAddRegular,
    DocumentAddFilled,
    AppsListRegular,
    AppsListFilled,
    NewsRegular,
    NewsFilled,
    TagFilled,
    TagRegular,
    PersonAddRegular,
    PersonAddFilled,
    BuildingMultipleRegular,
    BuildingMultipleFilled,
    BuildingRegular,
    BuildingFilled,
    BuildingRetailRegular,
    BuildingRetailFilled,
    PeopleCommunityRegular,
    PeopleCommunityFilled,
    SearchRegular,
    DataAreaRegular,
    DataAreaFilled,
    ShieldTaskRegular,
    ShieldTaskFilled,
    VideoPersonStarRegular,
    VideoPersonStarFilled,
    TaskListSquareLtrRegular,
    TaskListSquareLtrFilled,
    TaskListSquareAddRegular,
    TaskListSquareAddFilled,
    ShareScreenPersonOverlayRegular,
    ShareScreenPersonOverlayFilled,
    DocumentTableRegular,
    DocumentTableFilled,
    DocumentOnePageRegular,
    DocumentOnePageFilled,
    BuildingRetailShieldRegular,
    BuildingRetailShieldFilled,
    ContentSettingsRegular,
    ContentSettingsFilled,
    InfoRegular,
    InfoFilled,
    NumberRowRegular,
    NumberRowFilled,
    CalendarRtlRegular,
    CalendarRtlFilled,
    BriefcaseRegular,
    BriefcaseFilled
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";
import { useContext } from 'react';
import { UserContext } from '../UserContext';

export default function NavigationDrawer() {
    const { userLevel } = useContext(UserContext);
    return (
        <nav className='navigation-drawer' id='navigation-drawer'>


            {
                userLevel !== 6 ?
                    < Link to='/search'>
                        <button className='extended-fab' style={{ width: '100%', marginBottom: '2rem', boxShadow: 'initial' }}>
                            <SearchRegular
                                fontSize={24} />
                            Buscar Perfil
                        </button>
                    </Link> :
                    < Link to='/planificador'>
                        <button className='extended-fab' style={{ width: '100%', marginBottom: '2rem', boxShadow: 'initial' }}>
                            <CalendarRtlRegular
                                fontSize={24} />
                            Registro de actividad
                        </button>
                    </Link>
            }


            <CustomLink
                allowedLevels={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                to="/"
                icon={<HomeRegular fontSize={24} />}
                activeicon={<HomeFilled fontSize={24} />}>
                Inicio
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Herramientas</h5>

            <CustomLink
                allowedLevels={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                to="planificador"
                icon={<CalendarRtlRegular fontSize={24} />}
                activeicon={<CalendarRtlFilled fontSize={24} />}>
                Registro de actividad diaria
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1, 2]}
                to="planillas"
                icon={<DocumentOnePageRegular fontSize={24} />}
                activeicon={<DocumentOnePageFilled fontSize={24} />}>
                Planillas
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1, 2, 3, 4, 5, 8, 9]}
                to="padron"
                icon={<ShareScreenPersonOverlayRegular fontSize={24} />}
                activeicon={<ShareScreenPersonOverlayFilled fontSize={24} />}>
                Padron Nacional
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Afiliacion</h5>

            <CustomLink
                allowedLevels={[0, 1]}
                to="create"
                icon={<PersonAddRegular fontSize={24} />}
                activeicon={<PersonAddFilled fontSize={24} />}>
                Crear afiliado
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1]}
                to="instituciones"
                icon={<BuildingMultipleRegular fontSize={24} />}
                activeicon={<BuildingMultipleFilled fontSize={24} />}>
                Instituciones
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1]}
                to="departamentos"
                icon={<BuildingRegular fontSize={24} />}
                activeicon={<BuildingFilled fontSize={24} />}>
                Departamentos
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1]}
                to="dependencias"
                icon={<BuildingRetailRegular fontSize={24} />}
                activeicon={<BuildingRetailFilled fontSize={24} />}>
                Dependencias
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1]}
                to="promotores"
                icon={<PeopleCommunityRegular fontSize={24} />}
                activeicon={<PeopleCommunityFilled fontSize={24} />}>
                Promotores
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1, 2]}
                to="reportes"
                icon={<DocumentTableRegular fontSize={24} />}
                activeicon={<DocumentTableFilled fontSize={24} />}>
                Reportes
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Financiero</h5>

            <CustomLink
                allowedLevels={[0, 1, 3]}
                to="financiero"
                icon={<DataAreaRegular fontSize={24} />}
                activeicon={<DataAreaFilled fontSize={24} />}>
                Reportes financiero
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 1]}
                to="pagos"
                icon={<DocumentAddRegular fontSize={24} />}
                activeicon={<DocumentAddFilled fontSize={24} />}>
                Subir pagos
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Legal</h5>

            <CustomLink
                allowedLevels={[0, 8, 9]}
                to="legal"
                icon={<BriefcaseRegular fontSize={24} />}
                activeicon={<BriefcaseFilled fontSize={24} />}>
                Departamento legal
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Polizas</h5>

            <CustomLink
                allowedLevels={[0, 2]}
                to="polizas"
                icon={<ShieldTaskRegular fontSize={24} />}
                activeicon={<ShieldTaskFilled fontSize={24} />}>
                Administrador de polizas
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 2, 5]}
                to="generador-ins"
                icon={<BuildingRetailShieldRegular fontSize={24} />}
                activeicon={<BuildingRetailShieldFilled fontSize={24} />}>
                Generador INS
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 2, 5]}
                to="casos-fallecimiento"
                icon={<TaskListSquareLtrRegular fontSize={24} />}
                activeicon={<TaskListSquareLtrFilled fontSize={24} />}>
                Casos de fallecimiento
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 2, 5]}
                to="crear-caso-fallecimiento"
                icon={<TaskListSquareAddRegular fontSize={24} />}
                activeicon={<TaskListSquareAddFilled fontSize={24} />}>
                Crear caso de fallecimiento
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 2, 5]}
                to="solicitudes-poliza"
                state={{ title: 'Records' }}
                icon={<AppsListRegular fontSize={24} />}
                activeicon={<AppsListFilled fontSize={24} />}>
                Solicitudes de poliza
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 2, 5]}
                to="referencias-polizas"
                icon={<NumberRowRegular fontSize={24} />}
                activeicon={<NumberRowFilled fontSize={24} />}>
                Numeros de referencia Poliza
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Asesores sindicales</h5>

            <CustomLink
                allowedLevels={[0, 1, 2, 3, 4, 5]}
                to="soon"
                icon={<ContentSettingsRegular fontSize={24} />}
                activeicon={<ContentSettingsFilled fontSize={24} />}>
                Proximamente...
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>App</h5>

            <CustomLink
                allowedLevels={[0, 7]}
                to="noticias"
                icon={<NewsRegular fontSize={24} />}
                activeicon={<NewsFilled fontSize={24} />}>
                Noticias
            </CustomLink>

            <CustomLink
                allowedLevels={[0, 7]}
                to="convenios"
                icon={<TagRegular fontSize={24} />}
                activeicon={<TagFilled fontSize={24} />}>
                Convenios
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Admin</h5>

            <CustomLink
                allowedLevels={[0]}
                to="admins"
                icon={<VideoPersonStarRegular fontSize={24} />}
                activeicon={<VideoPersonStarFilled fontSize={24} />}>
                Roles de administrador
            </CustomLink>

            <h5 style={{ margin: '1rem 0' }}>Acerca del app</h5>

            <CustomLink
                allowedLevels={[0, 1, 2, 3, 4, 5]}
                to="release-notes"
                icon={<InfoRegular fontSize={24} />}
                activeicon={<InfoFilled fontSize={24} />}>
                Notas de versión
            </CustomLink>

            {/* <hr style={{margin: '1rem  0', borderColor: 'var(--outline)'}}></hr> */}


        </nav >
    )
};

function CustomLink({ allowedLevels, children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    const { userLevel } = useContext(UserContext);
    const isIncluded = allowedLevels.includes(userLevel);

    function selectClass() {
        if (match && isIncluded) {
            return "navigation-drawer-item navigation-drawer-item-match";
        } else if (!match && isIncluded) {
            return "navigation-drawer-item";
        } else if (!isIncluded) {
            return "navigation-drawer-item navigation-drawer-item-disabled"
        }
    }

    return (
        <Link to={to} {...props} className={selectClass()}>
            {match ? props.activeicon : props.icon}
            {children}
        </Link>
    );
}

function HomeItem({ allowedLevels, to, children }) {
    const { userLevel } = useContext(UserContext);
    const isIncluded = allowedLevels.includes(userLevel);
    return (
        <Link to={to} className={isIncluded ? 'home-rail-item' : 'home-rail-item home-rail-item-disabled'}>
            {children}
        </Link >
    );
}