import './App.css';
import { getStorage } from "firebase/storage";
import { firebaseApp } from './firebaseInit';
import React, { useContext, useEffect, useState } from 'react';
// import { Navigate, Route, Routes } from "react-router-dom";

// ROUTES
import Home from './routes/Home';
import Noticias from './routes/Noticias';
import Convenios from './routes/Convenios';
import Create from './routes/Create';
import Instituciones from './routes/Instituciones';
import Departamentos from './routes/Departamentos';
import Dependencias from './routes/Dependencias';
import Promotores from './routes/Promotores';
import Report from './routes/Report';
import ReporteFinanciero from './routes/ReporteFinanciero';
import Perfil from './routes/Profile';
import ProfileSearch from './routes/ProfileSearch';
import Padron from './routes/Padron';
import SubirPagos from './routes/SubirPagos';
import Polizas from './routes/Polizas';
import HistorialPago from './routes/HistorialPago';
import AdminRoles from './routes/AdminRoles';

// NAVIGATION
import TopAppBar from './navigation/TopAppBar';
import NavigationBar from './navigation/NavigationBar';
import NavigationRail from './navigation/NavigationRail';
import NavigationDrawer from './navigation/NavigationDrawer';
import ModalNavigationDrawer from './navigation/ModalNavigationDrawer';

// CONTEXT
import { UserContext } from './UserContext';

import { Link, Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import CasosMuerte from './routes/CasosMuerte';
import done from './assets/done.svg';
import PolizasEnRevision from './routes/PolizaEnRevision';
import Planillas from './routes/Planillas';
import CasosAbiertos from './routes/CasosAbiertos';
import GeneradorINS from './routes/GeneradorINS';
import CasoPoliza from './routes/CasoPoliza';
import axios from 'axios';
import { doc, setDoc, Timestamp, getFirestore, getDoc, updateDoc, collection, connectFirestoreEmulator } from 'firebase/firestore';
import { SearchRegular } from '@fluentui/react-icons';
import csv from './assets/csv.svg';
import Planificador from './Planificador';
import Evento from './routes/Evento';
import CasoLegal from './routes/CasoLegal';
import Legal from './routes/Legal';

const db = getFirestore(firebaseApp);
// connectFirestoreEmulator(db, 'localhost', 8080);

const storage = getStorage(firebaseApp);

export default function App() {
  const { authState, verified } = useContext(UserContext);

  console.log(authState, verified);

  if (!authState || !verified) { return <Navigate to="/signup" replace={true} />; }
  return (
    <div className="app">

      <TopAppBar />

      <ModalNavigationDrawer />

      <aside>
        <NavigationRail />
        <NavigationDrawer />
      </aside>


      <main>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path='noticias' element={<Noticias />} />
          <Route path='convenios' element={<Convenios />} />

          <Route path='create' element={<Create />} />
          <Route path="create/exito" element={<CreateSuccess />} />
          <Route path='instituciones' element={<Instituciones />} />
          <Route path='departamentos' element={<Departamentos />} />
          <Route path='dependencias' element={<Dependencias />} />
          <Route path='promotores' element={<Promotores />} />
          <Route path='reportes' element={<Report />} />

          <Route path='financiero' element={<ReporteFinanciero />} />
          <Route path='pagos' element={<SubirPagos />} />
          <Route path="pagos/exito" element={<Success />} />


          <Route path='profile' element={<Perfil />} />
          <Route path='profile/history' element={<HistorialPago />} />
          <Route path='search' element={<ProfileSearch />} />

          <Route path='planillas' element={<Planillas />} />

          <Route path='polizas' element={<Polizas />} />
          <Route path='casos-fallecimiento' element={<CasosAbiertos />} />
          <Route path='caso' element={<CasoPoliza />} />
          <Route path='crear-caso-fallecimiento' element={<CasosMuerte />} />
          <Route path='solicitudes-poliza' element={<PolizasEnRevision />} />
          <Route path='generador-ins' element={<GeneradorINS />} />
          <Route path='referencias-polizas' element={<ReferenciasPolizas />} />


          <Route path='padron' element={<Padron />} />
          <Route path='admins' element={<AdminRoles />} />

          <Route path='planificador' element={<Planificador />} />
          <Route path='evento' element={<Evento />} />

          {/* LEGAL */}

          <Route path='legal' element={<Legal />} />
          <Route path='caso-legal' element={<CasoLegal />} />

          {/* <Route path='padron' element={<TSE />} /> */}

          <Route path='soon' element={<div className='page'><h1>Proximamente...</h1></div>} />

          <Route path='release-notes' element={<ReleaseNotes />} />

          {/* <Route path='migration' element={<Migration />} /> */}

          {/* <Route path='rules' element={<RulesTest />} /> */}

        </Routes>
      </main>



      {/* <NavigationBar /> */}

    </div>

  );
}

function ReferenciasPolizas() {
  const [listObj, setListObj] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  let navigate = useNavigate();

  useEffect(() => { document.getElementById('file-submit-btn').disabled = true; }, []);

  const csvToArray = (csv) => {
    const lines = csv.split('\r\n');
    const dataLines = lines.slice(1);
    const objects = [];
    for (const line of dataLines) {
      const fields = line.split(';');
      const object = {
        id: fields[0],
        referencia: fields[1],
      };
      objects.push(object);
    }
    return objects;
  }

  const handleFile = (event) => {
    event.preventDefault();
    var file = event.target.files[0];
    setErrorMsg('');

    var extension = event.target.files[0].name.split('.').pop().toLowerCase();

    if (extension === 'csv') {
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target.result;

        var convertedRes = csvToArray(res);

        setListObj(convertedRes);
        console.log(convertedRes);

        document.getElementById('file-submit-btn').disabled = false;
      };

      reader.readAsText(file);
    } else {
      setErrorMsg('Formato de archivo incompatible: ' + extension);
    }
  }

  const uploadToFirebase = () => {

    listObj.forEach(async element => {
      console.log(element.id);
      const userRef = doc(db, "polizas/TO7LtGbsjm4XGgLuJ0Af/usuarios", element.id);

      try {
        await updateDoc(userRef, {
          referencia: element.referencia
        });
        const pRow = document.createElement('code');
        pRow.innerHTML = element.id + ': Documento actualizado con exito';
        document.getElementById('logs').appendChild(pRow);
      } catch (error) {
        console.log(error);
        const pRow = document.createElement('code');
        pRow.innerHTML = element.id + ": " + error.message;
        document.getElementById('logs').appendChild(pRow);
      }
    });

    // navigate('/pagos/exito');
  }
  return (
    <div className='page'>
      <h1>Actualiza numeros de referencia de poliza</h1>

      <p>Para actualizar los numeros de referencia se deberá cargar un archivo CSV de la siguiente manera, separado por ";" con el formato especificado a continuación: Id: Representa la cédula o identificación del afiliado. Referencia: El nuevo numero de referencia.</p>

      <h4 style={{ margin: '1rem 0' }}>Ejemplo:</h4>

      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>referencia</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>503300908</td>
            <td>EFGHIK</td>
          </tr>
          <tr>
            <td>503300908</td>
            <td>ABCXYZ</td>
          </tr>
          <tr>
            <td>503300908</td>
            <td>LKKJSHD</td>
          </tr>
        </tbody>
      </table>

      <ol style={{ margin: '2rem 0' }}>
        <li>Generar CSV</li>
        <li>Cargar con herramienta a continuacion</li>
        <li>Haz click en "Cargar"</li>
      </ol>
      <input style={{ display: 'none' }} type="file" id="files" name="file" onChange={handleFile} />
      <button className='filled-tonal-button' onClick={() => { document.getElementById('files').click(); }}><SearchRegular fontSize={24} />Elige un archivo .csv <img src={csv} height='24px' style={{ marginLeft: '.5rem' }} /></button>
      <h3 style={{ marginTop: '1rem' }}>Elementos cargados: {listObj.length}</h3>

      <button style={{ marginTop: '.5rem' }} id="file-submit-btn" className='filled-button' onClick={uploadToFirebase}>Cargar</button>

      <p style={{ color: 'var(--color-error)' }}>{errorMsg}</p>

      <div id='logs'>
        <h4>Logs:</h4>
      </div>
    </div>
  );
}

function RulesTest() {
  const [estado, setEstado] = useState(true);

  const [filterFrequency, setFilterFrequency] = useState(1200);

  const obtainData = async () => {
    const docRef = doc(db, "cities", "SF");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const writeData = async () => {
    const citiesRef = collection(db, "cities");

    try {
      await setDoc(doc(citiesRef, "SJ"), {
        name: "San Jose", state: "CA", country: "CRC",
        capital: false, population: 860000,
        regions: ["west_coast", "norcal"]
      });
      await setDoc(doc(citiesRef, "SF"), {
        name: "San Francisco", state: "CA", country: "USA",
        capital: false, population: 860000,
        regions: ["west_coast", "norcal"]
      });
      await setDoc(doc(citiesRef, "LA"), {
        name: "Los Angeles", state: "CA", country: "USA",
        capital: false, population: 3900000,
        regions: ["west_coast", "socal"]
      });
      await setDoc(doc(citiesRef, "DC"), {
        name: "Washington, D.C.", state: null, country: "USA",
        capital: true, population: 680000,
        regions: ["east_coast"]
      });
      await setDoc(doc(citiesRef, "TOK"), {
        name: "Tokyo", state: null, country: "Japan",
        capital: true, population: 9000000,
        regions: ["kanto", "honshu"]
      });
      await setDoc(doc(citiesRef, "BJ"), {
        name: "Beijing", state: null, country: "China",
        capital: true, population: 21500000,
        regions: ["jingjinji", "hebei"]
      });
      console.log('Writted');
    } catch (error) {
      console.log(error);
    }
  }


  const updateData = async () => {
    const washingtonRef = doc(db, "cities", "DC");
    try {
      await updateDoc(washingtonRef, {
        capital: estado
      });
      setEstado(!estado);
      console.log('updated', estado);
    } catch (error) {
      console.log(error);
    }


  }

  const playSound = () => {
    // Crea un nuevo contexto de audio
    const audioContext = new AudioContext();

    // Crea un oscilador y configúralo para producir una onda de sierra a 1000 Hz
    const oscillator = audioContext.createOscillator();
    oscillator.frequency.value = 1000;
    oscillator.type = "sawtooth";

    // Crea un filtro pasa bajos y configúralo para cortar a 1200 Hz
    const filter = audioContext.createBiquadFilter();
    filter.frequency.value = 1200;
    filter.type = "lowpass";

    // Conecta el oscilador al filtro y luego el filtro al destino de audio para que se reproduzca en el equipo del usuario
    oscillator.connect(filter);
    filter.connect(audioContext.destination);

    // Obtén una referencia al botón y al slider
    const button = document.getElementById("button");
    const slider = document.getElementById("slider");

    // Asigna un manejador de evento de mousedown al botón para iniciar el oscilador cuando se presione el botón
    button.addEventListener("mousedown", () => {
      oscillator.start();
    });

    // Asigna un manejador de evento de mouseup al botón para detener el oscilador cuando se suelte el botón
    button.addEventListener("mouseup", () => {
      oscillator.stop();
    });

    // Asigna un manejador de evento de input al slider para actualizar la frecuencia del filtro cuando el usuario mueva el slider
    slider.addEventListener("input", () => {
      filter.frequency.value = filterFrequency;
    });
  }



  return (
    <div>
      <h1>Rules Test</h1>
      <button className='filled-tonal-button' onClick={obtainData}>Obtain data</button>
      <button className='filled-tonal-button' onClick={writeData}>Write data</button>
      <button className='filled-tonal-button' onClick={updateData}>Update data</button>

      <button onClick={() => setEstado(!estado)}>Toggle</button>
      <h1>Estado: {estado.toString()}</h1>

      <button className='filled-button' id='button' onClick={playSound}>Play</button>
      <input type="range" min="100" max="10000" value={filterFrequency} id="slider" onChange={e => setFilterFrequency(e.target.value)}></input>

    </div>
  );
}

function ReleaseNotes() {
  return (
    <div className='page'>
      <h1>Notas de versión</h1>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2.6</h2>
          <h4>14 FEB 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Planificador: Se arregla error que no mostraba boton de eliminar</li>
          <li>Planificador: Los eventos no se pueden eliminar ni modificar 24 horas despues de la hora de finalizacion del evento.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2.5</h2>
          <h4>13 FEB 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Perfil: POLIZAS, se integra con Google Drive para la visualizacion de archivos sin la necesidad de salir de la pagina, ademas la actualizacion de archivos vinculados a cada afiliado</li>
          <li>Caso de fallecimiento: Se integra con Google Drive para la visualizacion de archivos sin la necesidad de salir de la pagina, ademas la actualizacion de archivos vinculados a cada caso de fallecimiento</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2.4</h2>
          <h4>2 FEB 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Afiliacion: Se restringe el acceso a beneficiarios de poliza</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2.3</h2>
          <h4>31 ENE 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Roles de administracion: Se creo usuario para la UDO, que tiene acceso al planificador y a la visualizacion de perfiles de afiliados</li>
          <li>Roles de administracion: Se creo rol planificador, tiene acceso al planificador</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2.2</h2>
          <h4>26 ENE 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Planificador: Ahora solo se pueden eliminar eventos antes de 1 dia despues de la finalizacion del evento</li>
          <li>Planificador: Solo el creador del evento puede editarlo</li>
          <li>Reportes: Se agrega la fecha de desafiliacion a reportes por institucion.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2.1</h2>
          <h4>18 ENE 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Crear afiliado: Ahora los correos se escriben solo en mayuscula</li>
          <li>Crear afiliado: Se soluciona error que podia subir incrementos superiores a 1 a la hora de crear un afiliado</li>
          <li>Planillas: MD se soluciona problema al generar la planilla MD.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.2</h2>
          <h4>16 ENE 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>NUEVO modulo planificador: Ahora todos los miembros de la organizacion con una cuenta pueden agendar eventos, agregar participantes y consultar los eventos de otros.</li>
          <li>Nueva pagina de evento: Pagina en la que se visualizan y editan los eventos</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.1.2</h2>
          <h4>14 ENE 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Visualizacion: Arregla error de visualizacion en monitores grandes y de alta resolucion.</li>
          <li>Pagina de inicio: Mejoras visuales en la forma en la que se visualizan los modulos activos e inactivos segun el nivel de acceso.</li>
          <li>Barra de navegacion lateral: Mejoras visuales en la forma en la que se visualizan los modulos activos e inactivos segun el nivel de acceso.</li>
          <li>Riel de navegacion: Para mejorar su utilidad, en pantallas pequenas se muestran solo algunas herramientas.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.1.1</h2>
          <h4>10 ENE 2023</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones. Se agregan funciones importantes de seguridad.</p>
        <ul>
          <li>Numero de referencia: Empieza por el 47921.</li>
          <li>Promotores: Se corrige error que impedia eliminar promotores</li>
          <li>Reportes: Ahora es compatible con observaciones con quiebres de linea.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.1</h2>
          <h4>29 DIC 2022</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones. Se agregan funciones importantes de seguridad.</p>
        <ul>
          <li>Roles de administración: Se implementan los roles de administracion: 0, 1, 2, 3, 4 y 5. Los cuales pueden ser agregados por el administrador y cuenta con sus respectos accesos de escritura y lectura a la base de datos a nivel de servidor.</li>
          <li>Pagina de Inicio: Se inhabilitan los botones a las herramientas segun el nivel de acceso de usuario.</li>
          <li>Generador INS: Ahora si no se selecciona una fecha de inicio se generan todos los afiliados con poliza, funcion que sirve para cobrar polizas.</li>
          <li>Planillas: Se corrige error de CD ocasionado por cambio de DisplayName de la poliza basica.</li>
          <li>Planillas: Correccion error al extraer monto de rebajo de poliza en Municipalidad de San Jose.</li>
          <li>Reportes: Reporte general ahora ordenaa alfabéticamente por apellidos.</li>
          <li>Nueva funcion: Se creo una nueva pagina especifica para actualizar los numeros de referencia de poliza para cada afiliado.</li>
          <li>Generador INS: Se incluye el numero de referencia en el Excel a descargar.</li>
          <li>Logs: En las paginas Subir Pagos y Actualizar numeros de referencia de polizas, se incluyo la nueva funcion Logs en la que se puede verificar el estado de cada uno de los documentos a crear o actualizar, tambien detecta errores individuales.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.0.4</h2>
          <h4>23 DIC 2022</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones. Migracion polizas</p>
        <ul>
          <li>Poliza: Debido a la falta de fecha de inicio de poliza al momento de hacer la migracion, todas las polizas ingresadas en ese momento tienen fecha de inicio el 23 de Diciembre de 2022.</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.0.3</h2>
          <h4>20 DIC 2022</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones. Se agrega la nueva página Roles de Administrador en la que se definen los usuarios administrador con sus respectivos niveles de acceso.</p>
        <ul>
          <li>Nueva página: Roles de Administradores</li>
          <li>Seguridad: Se implementan reglas de seguridad a nivel de bases de datos, para controlar los cambios posibles a realizar por diferentes usuarios.</li>
          <li>Seguridad: Niveles de acceso 0, 1, 2, 3 y 4</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.0.2</h2>
          <h4>29 NOV 2022</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones.</p>
        <ul>
          <li>Planillas: CD separador con ","</li>
          <li>Planillas: CD nombre contadores 'totalregistros', 'totalcuota'</li>
          <li>Planillas: MD Ahora se muestran las fechas de afiliacion y con separador de fecha de afiliacon por "-"</li>
          <li>Planillas: PANI separador de fecha por "-"</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.0.1</h2>
          <h4>17 NOV 2022</h4>
        </div>
        <p>Esta actualización incluye arreglo de errores y mejoramiento en algunas funciones. Además se incluye la nueva página Notas de Versión en la que se incluyen notas del desarrollador para dar siguimiento a los cambios y actualizaciones del app a lo largo de la historia.</p>
        <ul>
          <li>Crear afiliado: Correción funcionamiento campos de texto al dar click</li>
          <li>Reportes financieros: Mejoras en interfaz y experiencia de usuario</li>
          <li>Reportes afiliación: Optimización de rendimiento en instituciones con miles de datos</li>
          <li>Reportes afiliación: Se incluye la fecha de nacimiento</li>
          <li>Reportes afiliación: Ahora se ordena alfabéticamente por apellidos</li>
          <li>Reportes afiliación: Ahora se muestra correctamente el icono de pago al dia</li>
          <li>Planillas: CCSS en formato .txt</li>
          <li>Planillas: Junta de Protección Social en formato .xls</li>
        </ul>
      </div>

      <div className='version-card'>
        <div className='version-card-header'>
          <h2>2.0.0</h2>
          <h4>1 OCT 2022</h4>
        </div>
        <p>En esta actualización se hicieron cambios radicales para el mejoramiento de la experiencia e interfaces de usuario asi como mejoramiento en el rendimiento.</p>
        <ul>
          <li>Nueva Pagina de inicio</li>
          <li>Compatibilidad con Tema Oscuro en sistema Windows o Mac</li>
          <li>Nuevo menu lateral comprimido para que haya mas espacio util en pantallas pequeñas o cuando la ventana se hace estrecha.</li>
          <li>Nueva pagina Noticias</li>
          <li>Nueva pagina Convenios</li>
          <li>Nueva pagina Planillas</li>
          <li>Nueva pagina Generador INS</li>
          <li>Nueva pagina Administrador de Polizas</li>
          <li>Nueva pagina Casos de Fallecimiento</li>
          <li>Nueva pagina Crear Caso de Fallecimiento</li>
          <li>Nuevo sistema en servidor para autenticación de usuarios</li>
          <li>Integración de Pólizas en Perfil</li>
          <li>Integración de Casos de Fallecimiento en Perfil</li>
        </ul>
      </div>

    </div>
  );
}



function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function CreateSuccess() {
  let query = useQuery();
  const ced = query.get("id");

  return (
    <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Afiliado creado con exito</h1>
      {/* <img src={done} width='360px'/> */}
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Link to={{ pathname: '/profile', search: '?id=' + ced }} >
          <button className='btn-contained'>Editar Perfil {ced}</button>
        </Link>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <button className='btn-tonal'>Volver a inicio</button>
        </Link>
      </div>
    </div>
  );
}

function Success() {
  return (
    <div>
      <h1>Datos cargados con exito</h1>
      <img src={done} width='360px' />
      <Link to="/">
        <button className='btn-contained'>Volver a inicio</button>
      </Link>
    </div>
  );
}


function Migration() {
  const [datosFinales, setDatosFinales] = useState([]);

  const handleFile4 = e => {

    e.preventDefault();
    var file = e.target.files[0];
    var reader = new FileReader();

    reader.onload = function (event) {

      // function csvToArray(csv) {
      //   // Separamos el CSV en líneas
      //   const lines = csv.split('\r\n');

      //   // Creamos el array resultante
      //   const result = [];

      //   // Iteramos sobre cada línea
      //   for (const line of lines) {
      //     // Separamos cada línea en sus diferentes celdas
      //     const cells = line.split(',');

      //     // Creamos un objeto para esta línea
      //     const obj = {};

      //     // Asignamos el valor de la Columna A como una propiedad del objeto
      //     obj.columnA = cells[0];

      //     // Creamos un objeto para las columnas B, C y D
      //     // obj.otherColumns = {
      //     //   columnB: cells[1],
      //     //   columnC: cells[2],
      //     //   columnD: cells[3],
      //     //   columnE: cells[4],
      //     //   columnF: cells[5]
      //     // };

      //     let otherColumns = null;

      //     // Creamos un objeto para las columnas B, C y D si alguna de ellas no está vacía
      //     if (cells[1] || cells[2] || cells[3] || cells[4] || cells[5]) {
      //       obj.otherColumns = {
      //         columnB: cells[1] || null,
      //         columnC: cells[2] || null,
      //         columnD: cells[3] || null,
      //         columnE: cells[4] || null,
      //         columnF: cells[5] || null,
      //       };
      //     }

      //     // Añadimos el objeto al array resultante
      //     result.push(obj);
      //   }

      //   return result;
      // }

      function csvToArray(csv) {
        // Separamos el CSV en líneas
        const lines = csv.split('\r\n');

        // Creamos el objeto resultante
        const result = {};

        // console.log(lines);

        // Iteramos sobre cada línea
        for (const line of lines) {
          // Separamos cada línea en sus diferentes celdas
          const cells = line.split(';');

          // Obtenemos el valor de la Columna A
          const columnA = cells[0];

          // Creamos un objeto para las columnas B, C y D si alguna de ellas no está vacía
          let otherColumns = null;
          if (cells[1] || cells[2] || cells[3] || cells[4]) {
            otherColumns = {
              parentesco: cells[1] || null,
              porcentaje: parseInt(cells[2]) || null,
              id: cells[3] || null,
              nombre: cells[4] + ' ' + cells[5] || null
            };
          }

          // Si el valor de la Columna A ya existe en el objeto, combinamos los objetos otherColumns
          if (result[columnA]) {
            if (otherColumns) {
              if (!Array.isArray(result[columnA].otherColumns)) {
                result[columnA].otherColumns = [result[columnA].otherColumns];
              }
              result[columnA].otherColumns.push(otherColumns);
            }
          } else {
            // Si el valor de la Columna A no existe en el objeto, lo añadimos como una propiedad
            // result[columnA] = { columnA, otherColumns };

            // Si el valor de la Columna A no existe en el objeto, lo añadimos como una propiedad
            if (otherColumns) {
              if (Array.isArray(otherColumns)) {
                result[columnA] = { columnA, otherColumns };
              } else {
                result[columnA] = { columnA, otherColumns: [otherColumns] };
              }
            }

          }
        }

        // Convertimos el objeto resultante en un array
        const array = Object.values(result);

        // return array;

        // console.log(array);

        // Eliminamos los objetos que tienen otherColumns como null
        const filteredArray = array.filter(item => item.otherColumns);

        return filteredArray;
      }

      // // Crea una variable con el contenido del archivo CSV como una cadena
      // const csv = `Columna A,Columna B,Columna C,Columna D
      // valor 1,valor 2,valor 3,valor 4
      // valor 1,valor 6,valor 7,valor 8`;

      // // Llama a la función pasando la variable del CSV como argumento
      // const array = csvToArray(csv);

      // // Almacena el resultado de la función en una variable
      // console.log(array);

      ////
      const csv = event.target.result;
      const array = csvToArray(csv);
      console.log(array);
      setDatosFinales(array)

    }

    reader.readAsText(file);

  }

  const writeBeneficiarios = () => {
    datosFinales.forEach(async element => {
      try {
        const polizaRef = doc(db, "polizas/TO7LtGbsjm4XGgLuJ0Af/usuarios", element.columnA);
        await updateDoc(polizaRef, {
          beneficiarios: element.otherColumns
        });
        console.log('Updated')
      } catch (error) {
        console.log(error)
      }

    });
  }

  const handleFile3 = e => {
    function csvToObject(csv) {
      // Separamos el CSV en líneas
      const lines = csv.split('\n');

      // Creamos el objeto resultante
      const result = {};

      // Iteramos sobre cada línea
      for (const line of lines) {
        // Separamos cada línea en sus diferentes celdas
        const cells = line.split(',');

        // Obtenemos el valor de la Columna A y la Columna B
        const columnA = cells[0];
        const columnB = cells[1];

        // Si el valor de la Columna A ya existe en el objeto, lo convertimos en un array
        if (result[columnA]) {
          if (!Array.isArray(result[columnA])) {
            result[columnA] = [result[columnA]];
          }
          // Añadimos el valor de la Columna B al array
          result[columnA].push(columnB);
        } else {
          // Si el valor de la Columna A no existe en el objeto, lo añadimos como una propiedad
          result[columnA] = [columnB];
        }
      }

      return result;
    }

    const csv = 'Cedula,Beneficiario\n123456789,Juan\n123456789,Pedro\n987654321,Maria';
    const obj = csvToObject(csv);
    console.log(obj);
  }

  const handleFile2 = e => {
    e.preventDefault();
    var file = e.target.files[0];
    var reader = new FileReader();

    reader.onload = function (event) {

      const csv = event.target.result;
      const lineas = csv.split('\r\n');
      const cabeceras = lineas[0].split(';');

      const datos = lineas.slice(1).map(linea => {
        const campos = linea.split(';');
        return Object.assign({}, ...cabeceras.map((clave, i) => ({ [clave]: campos[i] })));
      });

      console.log(datos);
      datos.forEach(async element => {
        try {
          await setDoc(doc(db, "polizas/TO7LtGbsjm4XGgLuJ0Af/usuarios", element.Cedula), {
            afiliado: element.Cedula,
            active: true,
            inicio: Timestamp.now()
          });
          console.log('Escrito con exito');
        } catch (error) {
          console.log(error);
        }
      });
    }

    reader.readAsText(file);

  }

  const handleFile = (event) => {
    event.preventDefault();
    var file = event.target.files[0];

    var extension = event.target.files[0].name.split('.').pop().toLowerCase();

    var reader = new FileReader();
    reader.onload = function (event) {
      var res = event.target.result;

      // console.log(res);

      // console.log(res.split("\r\n"));

      var arrayOfObjects = [];

      // console.log(res.split("\r\n"));

      res.split("\r\n").forEach(element => {

        // console.log(element);

        var splitted = element.split('\t');
        var ced = splitted[0]; // A
        var apellidos = splitted[1]; // B
        var nombre = splitted[2]; // C
        var institucion = splitted[3]; // D
        var dependencia = splitted[4]; // E
        var departamento = splitted[5]; // F
        var puesto = splitted[6]; // G
        var nacimiento = splitted[7]; // H
        var fechaAfiliacion = splitted[8]; // I
        var genero = splitted[9]; // J
        var contacto1 = splitted[10]; // K => Oficina
        var contacto2 = splitted[11]; // L => Celular
        var contacto3 = splitted[12]; // M => Casa
        var email = splitted[13]; // N
        var nacionalidad = splitted[14]; // O
        var referencia = splitted[15]; // P
        var estado = splitted[16]; // Q
        var desafiliacion = splitted[17]; // R
        var observaciones = splitted[18]; // S 
        var promotor = splitted[19] // T


        // console.log(splitted[0]);

        // console.log(typeof(splitted[0]));

        // var fecha = fechaAfiliacion.split("/");
        // var dateUSA = fecha[1] + '/' + fecha[0] + '/' + fecha[2];
        // var d = new Date(dateUSA);
        var d = new Date(fechaAfiliacion);

        // console.log(d);

        // var fechaNac = nacimiento.split("/");
        // var dateUSANac = fechaNac[1] + '/' + fechaNac[0] + '/' + fechaNac[2];
        // var dNac = new Date(dateUSANac);
        var dNac = new Date(nacimiento);

        var dDesa = new Date(desafiliacion);

        var obj = {
          id: parseInt(ced),
          cedula: ced,
          apellidos: apellidos,
          nombre: nombre,
          institucion: institucion,
          dependencia: dependencia,
          departamento: departamento,
          puesto: puesto,
          ingreso: (fechaAfiliacion === '') ? '' : Timestamp.fromDate(d),
          genero: genero,
          nacimiento: (nacimiento === '') ? '' : Timestamp.fromDate(dNac),
          contacto1: contacto1,
          contacto2: contacto2,
          contacto3: contacto3,
          email: email,
          nacionalidad: nacionalidad,
          expira: Timestamp.now(),
          referencia: referencia,
          active: (estado === 'A') ? true : false,
          observaciones: observaciones,
          desafiliacion: (desafiliacion === '') ? '' : Timestamp.fromDate(dDesa),
          promotor: promotor,
          sector: '',
          plaza: '',
          poliza: '',
          salario: '',
          polizaRevision: false
        };

        // arrayOfObjects.push({
        //   cedula: ced,
        //   id: parseInt(ced),
        //   apellidos: apellidos,
        //   nombre: nombre,
        //   institucion: institucion,
        //   dependencia: dependencia,
        //   departamento: departamento,
        //   puesto: puesto,
        //   ingreso: Timestamp.fromDate(d),
        //   contacto1: contacto1,
        //   contacto2: contacto2,
        //   email: email,
        //   nacionalidad: nacionalidad,
        //   provincia: response.data.provincia,
        //   canton: response.data.canton,
        //   distrito: response.data.distrito,
        //   expira: Timestamp.now()
        // });

        //var url = 'https://padron.riffraff.digital';
        var url = 'http://localhost:8080';

        axios.get(url, {
          params: {
            cedula: ced
          }
        })
          .then(function (response) {
            // console.log(response.data);
            // if (typeof(response.data.provincia) == 'undefined') { console.log(response.data) }
            if (typeof (response.data.provincia) !== 'undefined') {
              obj.provincia = response.data.provincia;
              obj.canton = response.data.canton;
              obj.distrito = response.data.distrito;
            }
          })
          .catch(function (error) {
            console.log('error', obj.cedula);
          })
          .then(async function (ul) {
            // always executed
            // console.log(obj);
            arrayOfObjects.push(obj);

            try {
              await setDoc(doc(db, "usuarios", obj.cedula), obj);
              console.log('Document escrito');
            } catch (error) {
              console.log('Document error', error, obj.cedula);

            }
            // var docRef = await setDoc(doc(db, "usuarios", obj.cedula), obj);

            // console.log('Document escrito');

          });
        var nombre = splitted[5];
        var apellidos = splitted[1];
        var apellido2 = splitted[7];

        var distelec = splitted[1];



      });

      console.log(arrayOfObjects);

      // setListObj(arrayOfObjects);

      // setReady(true);

    };

    reader.readAsText(file);

  }

  return (
    <div>
      <h1>Migracion</h1>

      <input type="file" id="files" name="file" onChange={handleFile}></input>

      <h3>{datosFinales.length}</h3>
      <h2>Polizas</h2>
      <input type="file" id="files" name="file" onChange={handleFile2}></input>
      <h2>Beneficiarios</h2>
      <input type="file" id="files" name="file" onChange={handleFile4}></input>
      <button className='filled-button' onClick={writeBeneficiarios}>Write beneficiarios</button>
    </div>
  );
}


// const csv = 'nombre,edad,ciudad\nJuan,30,Madrid\nAna,25,Barcelona';

// const lineas = csv.split('\n'); // Divide el CSV en líneas
// const cabeceras = lineas[0].split(','); // Separa las cabeceras

// const datos = lineas.slice(1).map(linea => {
//   const campos = linea.split(','); // Separa los campos de cada línea

//   // Crea un objeto con las cabeceras como claves y los campos como valores
//   return Object.assign({}, ...cabeceras.map((clave, i) => ({ [clave]: campos[i] })));
// });

// console.log(datos); // Imprime [{ nombre: 'Juan', edad: '30', ciudad: 'Madrid' }, { nombre: 'Ana', edad: '25', ciudad: 'Barcelona' }]