import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore, Timestamp, updateDoc, onSnapshot, getDocs, collection, addDoc } from "firebase/firestore";
import './CasosMuerte.css';
import { useNavigate } from "react-router-dom";
import { LinkRegular } from "@fluentui/react-icons";

const db = getFirestore();

export default function CasosMuerte() {
    // L Linked
    const [lCedula, setLCedula] = useState('');
    // Q Quien
    const [qCedula, setQCedula] = useState('');
    const [qNombre, setQnombre] = useState('');
    const [qApellidos, setQApellidos] = useState('');
    const [qParentesco, setQParentesco] = useState('');
    const [qTelefono, setQtelefono] = useState('');
    const [qEmail, setQEmail] = useState('');
    const [fechaReclamo, setFechaReclamo] = useState('');
    // F Fallecido
    const [fCedula, setFCedula] = useState('');
    const [fNombre, setFnombre] = useState('');
    const [fApellidos, setFApellidos] = useState('');
    const [sobreFallecido, setSobreFallecido] = useState('');
    const [fechaFallecimiento, setFechaFallecimiento] = useState('');
    // Causa
    const [causaPrimaria, setCausaPrimaria] = useState('');
    const [causaSecundaria, setCausaSecundaria] = useState('');
    // Pago
    const [referencia, setReferencia] = useState('');
    const [fechaPago, setFechaPago] = useState('');
    const [montoIndemnizacionINS, setMontoIndemnizacionINS] = useState('');
    const [montoIndemnizacionANEP, setMontoIndemnizacionANEP] = useState('');
    const [tipoPago, setTipoPago] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [ampo, setAmpo] = useState("");
    // Observaciones
    const [observaciones, setObservaciones] = useState('');
    // Estado
    const [estado, setEstado] = useState('');
    // Troubleshoot
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    const crearCaso = async () => {
        try {
            setLoading(true);
            var reclamoDate = new Date(fechaReclamo);
            reclamoDate.setDate(reclamoDate.getDate() + 1);

            var pagoDate = new Date(fechaPago);
            pagoDate.setDate(pagoDate.getDate() + 1);

            var fallecimientoDate = new Date(fechaFallecimiento);
            fallecimientoDate.setDate(fallecimientoDate.getDate() + 1);

            var obj = {
                linked: lCedula,
                qCedula: qCedula,
                qNombre: qNombre,
                qApellidos: qApellidos,
                qParentesco: qParentesco,
                qTelefono: qTelefono,
                qEmail: qEmail,
                fCedula: fCedula,
                fNombre: fNombre,
                fApellidos: fApellidos,
                fechaFallecimiento: fechaFallecimiento,
                // fechaReclamo: Timestamp.fromDate(reclamoDate),
                sobreFallecido: sobreFallecido,
                causaPrimaria: causaPrimaria,
                causaSecundaria: causaSecundaria,
                referencia: referencia,
                // fechaPago: Timestamp.fromDate(pagoDate),
                montoIndemnizacionINS: montoIndemnizacionINS,
                montoIndemnizacionANEP: montoIndemnizacionANEP,
                tipoPago: tipoPago,
                numeroDocumento: numeroDocumento,
                ampo: ampo,
                observaciones: observaciones,
                estado: estado,
                created: Timestamp.now()
            }

            if (fechaPago) {
                obj.fechaPago = Timestamp.fromDate(pagoDate);
            }

            if (fechaFallecimiento) {
                obj.fechaFallecimiento = Timestamp.fromDate(fallecimientoDate);
            }

            const docRef = await addDoc(collection(db, "casos"), obj);
            // console.log("Document written with ID: ", docRef.id);
            navigate({ pathname: '/casos-fallecimiento', search: '?id=' + docRef.id.toString() })
        } catch (error) {
            console.log(error);
            setErrorMsg('Hubo un error.');
        } finally {
            setLoading(false);
        }



    }

    // useEffect(() => {
    //     var now = new Date();
    //     var day = ("0" + now.getDate()).slice(-2);
    //     var month = ("0" + (now.getMonth() + 1)).slice(-2);
    //     var today = now.getFullYear() + "-" + month + "-" + day;
    //     setFechaReclamo(today);
    // }, []);

    return (
        <div className="page">
            <h1>Crear caso de Falleciemiento</h1>

            <p>Crea un caso de fallecimiento y posteriormente puedes darle seguimiento en la pagina "Casos de fallecimiento"</p>

            <h4 style={{ margin: '1rem 0', display: 'flex', gap: '0.5rem' }}><LinkRegular fontSize={24} />Afiliado vinculado</h4>

            <div className="caso-form-grid">
                <div className='input-container'>
                    <input className='input-normal' id="caso-lCedula" type="text" value={lCedula} onChange={e => setLCedula(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-lCedula'>Cedula (ID de afiliado)</label>
                </div>
            </div>

            <h4 style={{ margin: '1rem 0' }}>Quien apertura</h4>

            <div className="caso-form-grid">
                <div className='input-container'>
                    <input className='input-normal' id="caso-qCedula" type="text" value={qCedula} onChange={e => setQCedula(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-qCedula'>Cedula</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-qNombre" type="text" value={qNombre} onChange={e => setQnombre(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-qNombre'>Nombre</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-qApellidos" type="text" value={qApellidos} onChange={e => setQApellidos(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-qApellidos'>Apellidos</label>
                </div>
                {/* <div className='input-container'>
                    <input className='input-normal' id="caso-qParentesco" type="text" value={qParentesco} onChange={e => setQParentesco(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-qParentesco'>Parentesco</label>
                </div> */}
                <div className='input-container'>
                    <select id="caso-sobre-fallecido" value={qParentesco} onChange={e => setQParentesco(e.target.value)}>
                        <option value="" disabled>---Parentesco---</option>
                        <option value="PADRE">PADRE</option>
                        <option value="MADRE">MADRE</option>
                        <option value="HERMANO/A">HERMANO/A</option>
                        <option value="HIJO/A">HIJO/A</option>
                        <option value="CONYUGE">CONYUGE</option>
                        <option value="OTROS">OTROS</option>
                    </select>
                    <label className='input-label' htmlFor='caso-sobre-fallecido'></label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-qTelefono" type="text" value={qTelefono} onChange={e => setQtelefono(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-qTelefono'>Telefono</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-qEmail" type="text" value={qEmail} onChange={e => setQEmail(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-qEmail'>Email</label>
                </div>
                {/* <div className='input-container'>
                    <input className='input-normal' id="caso-fechaReclamo" type="date" value={fechaReclamo} onChange={e => setFechaReclamo(e.target.value)} />
                    <label className='input-label' htmlFor='caso-fechaReclamo'>Fecha de reclamo</label>
                </div> */}
            </div>


            <h4 style={{ margin: '1rem 0' }}>Fallecido</h4>

            <div className="caso-form-grid">
                <div className='input-container'>
                    <select id="caso-sobre-fallecido" value={sobreFallecido} onChange={e => setSobreFallecido(e.target.value)}>
                        <option value="" disabled>---Sobre fallecido---</option>
                        <option value="TITULAR">Titular</option>
                        <option value="CONYGUE">Conyugue</option>
                        <option value="HIJOS">Hijos</option>
                        <option value="TERMINAL">Enfermedad terminal</option>
                    </select>
                    <label className='input-label' htmlFor='caso-sobre-fallecido'></label>
                </div>

                <div className='input-container'>
                    <input className='input-normal' id="caso-fCedula" type="text" value={fCedula} onChange={e => setFCedula(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-fCedula'>Cedula</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-fNombre" type="text" value={fNombre} onChange={e => setFnombre(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-fNombre'>Nombre</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-fApellidos" type="text" value={fApellidos} onChange={e => setFApellidos(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-fApellidos'>Apellidos</label>
                </div>

                <div className='input-container'>
                    <input className='input-normal' id="caso-fechaFallecimiento" type="date" value={fechaFallecimiento} onChange={e => setFechaFallecimiento(e.target.value)} />
                    <label className='input-label' htmlFor='caso-fechaFallecimiento'>Fecha de fallecimiento</label>
                </div>
            </div>

            <h4 style={{ margin: '1rem 0' }}>Causa de fallecimiento</h4>

            <div className="caso-form-grid">
                {/* <div className='input-container'>
                    <select id="caso-causa" value={causaPrimaria} onChange={e => setCausaPrimaria(e.target.value)}>
                        <option value="" disabled>---Causa de muerte primaria---</option>
                        <option value="A">Causa 1</option>
                        <option value="B">Causa 2</option>
                        <option value="C">Causa 3</option>
                    </select>
                    <label className='input-label' htmlFor='caso-causa'></label>
                </div> */}
                <div className='input-container'>
                    <input className='input-normal' id="caso-causaPrimaria" type="text" value={causaPrimaria} onChange={e => setCausaPrimaria(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-causaPrimaria'>Causa de muerte Primaria</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-causaSecundaria" type="text" value={causaSecundaria} onChange={e => setCausaSecundaria(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-causaSecundaria'>Causa de muerte Secundaria</label>
                </div>
            </div>

            <h4 style={{ margin: '1rem 0' }}>INS</h4>

            <div className="caso-form-grid">
                <div className='input-container'>
                    <input className='input-normal' id="caso-referencia" type="text" value={referencia} onChange={e => setReferencia(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-referencia'>Numero de referencia pago</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-fechaPago" type="date" value={fechaPago} onChange={e => setFechaPago(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-fechaPago'>Fecha pago INS</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-montoIndemnizacion" type="text" value={montoIndemnizacionINS} onChange={e => setMontoIndemnizacionINS(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-montoIndemnizacion'>Monto indemnizacion INS</label>
                </div>
            </div>

            <h4 style={{ margin: '1rem 0' }}>ANEP</h4>

            <div className="caso-form-grid">
                <div className='input-container'>
                    <input className='input-normal' id="caso-montoIndemnizacion" type="text" value={montoIndemnizacionANEP} onChange={e => setMontoIndemnizacionANEP(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-montoIndemnizacion'>Monto indemnizacion ANEP</label>
                </div>
                <div className='input-container'>
                    <select id="caso-tipo-pago" value={tipoPago} onChange={e => setTipoPago(e.target.value)}>
                        <option value="" disabled>---Selecciona un Tipo de pago---</option>
                        <option value="CHEQUE">Cheque</option>
                        <option value="TANSFERENCIA">Transferencia</option>
                        <option value="DEPOSITO">Deposito</option>
                    </select>
                    <label className='input-label' htmlFor='caso-tipo-pago'></label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-numeroDocumento" type="text" value={numeroDocumento} onChange={e => setNumeroDocumento(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-numeroDocumento'>Numero de documento</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="caso-ampo" type="text" value={ampo} onChange={e => setAmpo(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-ampo'>Ampo</label>
                </div>
            </div>

            <h4 style={{ margin: '1rem 0' }}>Estado caso</h4>

            <div className="caso-form-grid">
                <div className='input-container'>
                    <select id="caso-estado" value={estado} onChange={e => setEstado(e.target.value)}>
                        <option value="" disabled>---Selecciona un Estado de Caso---</option>
                        <option value="CASO EN TRAMITE">CASO EN TRAMITE</option>
                        <option value="CASO EN INS">CASO EN INS</option>
                        <option value="PAGO PENDIENTE">PAGO PENDIENTE</option>
                        <option value="SUCESORIO">SUCESORIO</option>
                        <option value="FINALIZADO">FINALIZADO</option>
                    </select>
                    <label className='input-label' htmlFor='caso-estado'></label>
                </div>
            </div>


            <h4 style={{ margin: '1rem 0' }}>Observaciones</h4>

            <div className='input-container'>
                <textarea id="w3review" style={{ width: '100%' }} name="w3review" rows="6" cols="50" value={observaciones} onChange={e => { setObservaciones(e.target.value) }} />
            </div>

            <p style={{ color: 'var(--color-error)' }}>{errorMsg}</p>

            {
                loading ?
                    <h3>Enviando...</h3> :
                    <button className="filled-button" style={{ margin: '1rem 0' }} onClick={crearCaso}>Enviar</button>
            }

        </div>
    )
}