import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, getFirestore, updateDoc, Timestamp, getDocs, collection, increment, onSnapshot } from "firebase/firestore";
import axios from 'axios';
import './Create.css';

const db = getFirestore();

export default function Create() {
    const [listaInstituciones, setListaInstituciones] = useState([]);
    const [listaDependencias, setListaDependencias] = useState([]);
    const [listaDepartamentos, setListaDepartamentos] = useState([]);

    const [listaPromotores, setListaPromotores] = useState([]);

    const [id, setId] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [genero, setGenero] = useState('');
    const [email, setEmail] = useState('');
    const [provincia, setProvincia] = useState('');
    const [canton, setCanton] = useState('');
    const [distrito, setDistrito] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [dependencia, setDependencia] = useState('');
    const [puesto, setPuesto] = useState('');
    const [salario, setSalario] = useState('');
    const [sector, setSector] = useState('');
    const [plaza, setPlaza] = useState('');
    const [poliza, setPoliza] = useState('');
    const [promotor, setPromotor] = useState('');
    const [telefono, setTelefono] = useState('');
    const [telOficina, setTelOficina] = useState('');
    const [telCasa, setTelCasa] = useState('');
    const [nacimiento, setNacimiento] = useState('');
    const [referencia, setReferencia] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [nacionalidad, setNacionalidad] = useState('COSTA RICA');

    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "referencia", "contador");

            onSnapshot((docRef), (doc) => {
                setReferencia(parseInt(doc.data().ref));
            });
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "instituciones", "lista");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setListaInstituciones(docSnap.data().instituciones.sort());
                setListaDependencias(docSnap.data().dependencias.sort());
                setListaDepartamentos(docSnap.data().departamentos.sort());
            } else {
                console.log("No such document!");
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "promotores"));
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({ id: doc.id, nombre: doc.data().nombre })
            });
            setListaPromotores(array);
        }
        fetchData();
    }, []);

    const list = listaInstituciones.map(data =>
        <option key={data} value={data}>{data}</option>
    )

    const createUser = async e => {
        e.preventDefault();

        setLoading(true);
        setErrorMsg('');



        const docRef = doc(db, "usuarios", id);

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setLoading(false);
            setErrorMsg('Este usuario ya existe, si quieres editarlo, prueba la herramienta "Perfil" ');
        } else {

            try {

                let text = `¿Seguro que deseas agregar a este nuevo afiliado?`;
                if (window.confirm(text) == true) {

                    await updateDoc(doc(db, "referencia", "contador"), {
                        ref: increment(1)
                    });
                    var birth = new Date(nacimiento);
                    birth.setDate(birth.getDate() + 1);

                    var setObj = {
                        id: parseInt(id),
                        cedula: id.toString(),
                        nombre: nombre,
                        apellidos: apellidos,
                        genero: genero,
                        email: email,
                        provincia: provincia.toUpperCase(),
                        canton: canton.toUpperCase(),
                        distrito: distrito.toUpperCase(),
                        institucion: institucion.toUpperCase(),
                        departamento: departamento.toUpperCase(),
                        dependencia: dependencia.toUpperCase(),
                        puesto: puesto.toUpperCase(),
                        salario: parseInt(salario),
                        sector: sector.toUpperCase(),
                        plaza: plaza.toUpperCase(),
                        poliza: poliza.toUpperCase(),
                        ingreso: Timestamp.now(),
                        referencia: referencia,
                        expira: Timestamp.now(),
                        contacto1: telOficina,
                        contacto2: telefono,
                        contacto3: telCasa,
                        promotor: promotor,
                        nacionalidad: nacionalidad,
                        observaciones: observaciones,
                        polizaRevision: true,
                        active: true
                    }

                    if (nacimiento !== '') {
                        setObj.nacimiento = Timestamp.fromDate(birth);
                    }


                    await setDoc(docRef, setObj);
                    setLoading(false);
                    console.log('Creado con exito');
                    navigate({ pathname: '/create/exito', search: '?id=' + id.toString() })
                } else {
                    console.log("usuario ha cancelado");
                    setLoading(false);
                }


            }
            catch (err) {
                setLoading(false);
                setErrorMsg('Hubo un error en la creacion del documento.');
                // console.log(err);
            }


        }

    }

    const requestCedula = async e => {
        var value = e.target.value;
        setId(e.target.value);
        console.log(value);
        if (value.length === 9) {

            const docRef = doc(db, "usuarios", value);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                alert('Este afiliado ya existe: ' + value);
            }

            //var url = 'http://localhost:8080/';
            //var url = 'https://padron.riffraff.digital';
            // var url = 'https://padron.amubri.voto/';
            // var url = 'https://padron.anep.app/';
            var url = 'https://padron-api.vercel.app/api/padron/' + value;
            axios.get(url)
                .then(function (response) {
                    var data = response.data;
                    if (!data) {
                        setErrorMsg('No se encontraron resultados');
                    }
                    setNombre(data.nombre.replace('\ufffd', 'Ñ'));
                    setApellidos(data.apellido1.replace('\ufffd', 'Ñ') + ' ' + data.apellido2.replace('\ufffd', 'Ñ'));
                    setProvincia(data.provincia);
                    setCanton(data.canton);
                    setDistrito(data.distrito);
                    console.log(response.data);
                    setLoading(false);

                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                })
                .then(function () {
                    // always executed
                });
        }
    }

    return (
        <div className='page'>
            <h1>Creacion de afiliados</h1>

            <p style={{ margin: "1rem 0" }}>Si deseas ingresar un nuevo afiliado a la base de datos completa los siguientes formularios, posteriormente oprima el botón “Crear afiliado”. La información de los perfiles puede ser modificada en cualquier momento.</p>

            <form className='create-form' onSubmit={createUser}>

                <h4>Persona</h4>

                <section className='create-form-section'>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-id" type="number" value={id} onChange={requestCedula} />
                        <label className='input-label' htmlFor='profile-id'>ID</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-nombre" type="text" value={nombre} onChange={e => { setNombre(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-nombre'>Nombre</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-apellidos" type="text" value={apellidos} onChange={e => { setApellidos(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-apellidos'>Apellidos</label>

                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-email" type="text" value={email} onChange={e => { setEmail(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-email'>email</label>

                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-nacimiento" type="date" value={nacimiento} onChange={e => { setNacimiento(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-nacimiento'>Fecha de nacimiento</label>

                    </div>

                    <select id="genero" value={genero} onChange={e => setGenero(e.target.value)}>
                        <option value="" disabled>---Selecciona un Genero---</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                    </select>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-celular" type="number" value={telefono} onChange={e => { setTelefono(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-celular'>Celular</label>

                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-telOficina" type="number" value={telOficina} onChange={e => { setTelOficina(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-telOficina'>Tel Oficina</label>

                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-telCasa" type="number" value={telCasa} onChange={e => { setTelCasa(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-telCasa'>Tel Casa</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-nacionalidad" type="text" value={nacionalidad} onChange={e => { setNacionalidad(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-nacionalidad'>Nacionalidad</label>
                    </div>



                </section>

                <h5>Provincia</h5>

                <div className='create-form-section'>

                    <select id="provincia" value={provincia} onChange={e => setProvincia(e.target.value)}>
                        <option value="" disabled>---Selecciona una Provincia---</option>
                        <option value="PUNTARENAS">PUNTARENAS</option>
                        <option value="SAN JOSE">SAN JOSE</option>
                        <option value="HEREDIA">HEREDIA</option>
                        <option value="LIMON">LIMON</option>
                        <option value="CARTAGO">CARTAGO</option>
                        <option value="GUANACASTE">GUANACASTE</option>
                        <option value="ALAJUELA">ALAJUELA</option>
                    </select>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-canton" type="text" value={canton} onChange={e => { setCanton(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-canton'>Canton</label>
                    </div>

                    <div className='input-container'>

                        <input className='input-normal' id="profile-canton" type="text" value={distrito} onChange={e => { setDistrito(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-canton'>Distrito</label>
                    </div>

                </div>



                <h4>Trabajo</h4>

                <section className='create-form-section'>

                    <div className='input-container create-list'>
                        <label className='select-label' htmlFor='institucion'><small>Institucion</small></label>
                        <select id="institucion" value={institucion} onChange={e => setInstitucion(e.target.value)}>
                            <option value="" disabled>---Selecciona una Institucion---</option>
                            {listaInstituciones.map(data =>
                                <option key={data} value={data}>{data}</option>
                            )}
                        </select>
                    </div>


                    <div className='input-container'>
                        <label className='select-label' htmlFor='dependencia'><small>Dependencia</small></label>
                        <select id="dependencia" value={dependencia} onChange={e => setDependencia(e.target.value)}>
                            <option value="" disabled>---Selecciona una Dependencia---</option>
                            {listaDependencias.map(data =>
                                <option key={data} value={data}>{data}</option>
                            )}
                        </select>
                    </div>

                    <div className='input-container'>
                        <label className='select-label' htmlFor='departamento'><small>Departamento</small></label>
                        <select id="departamento" value={departamento} onChange={e => setDepartamento(e.target.value)}>
                            <option value="" disabled>---Selecciona un Departamento---</option>
                            {listaDepartamentos.map(data =>
                                <option key={data} value={data}>{data}</option>
                            )}
                        </select>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-puesto" type="text" value={puesto} onChange={e => { setPuesto(e.target.value.toUpperCase()) }} />
                        <label className='input-label' htmlFor='profile-puesto'>Puesto</label>
                    </div>
                </section>

                <h5>Sector</h5>

                <div>
                    <input type="radio" id="sector1" name="sector" value="privado" onChange={e => { setSector(e.target.value) }} />
                    <label htmlFor="sector1">Privado</label>
                    <br></br>
                    <input type="radio" id="sector2" name="sector" value="publico" onChange={e => { setSector(e.target.value) }} />
                    <label htmlFor="sector2">Publico</label>
                    <br></br>
                    <input type="radio" id="sector3" name="sector" value="pensionado" onChange={e => { setSector(e.target.value) }} />
                    <label htmlFor="sector3">Pensionado</label>
                    <br></br>
                    <input type="radio" id="sector4" name="sector" value="directo" onChange={e => { setSector(e.target.value) }} />
                    <label htmlFor="sector4">Pago directo</label>
                </div>

                <h5>Tipo de plaza</h5>

                <div>
                    <input type="radio" id="plaza1" name="plaza" value="interino" onChange={e => { setPlaza(e.target.value) }} />
                    <label htmlFor="plaza1">Interino</label>
                    <br></br>
                    <input type="radio" id="plaza2" name="plaza" value="propiedad" onChange={e => { setPlaza(e.target.value) }} />
                    <label htmlFor="plaza2">Propiedad</label>
                </div>

                <h5>Poliza</h5>

                <div>
                    <input type="radio" id="age1" name="age" value="ninguno" onChange={e => { setPoliza(e.target.value) }} />
                    <label htmlFor="age1">Ningunno</label>
                    <br></br>
                    <input type="radio" id="age2" name="age" value="basica" onChange={e => { setPoliza(e.target.value) }} />
                    <label htmlFor="age2">Basica</label>
                    <br></br>
                    <input type="radio" id="age3" name="age" value="voluntaria" onChange={e => { setPoliza(e.target.value) }} />
                    <label htmlFor="age3">Voluntaria</label>
                    <br></br>
                </div>

                <h5>Promotor</h5>

                <div className='input-container'>
                    <select id="promotores" value={promotor} onChange={e => setPromotor(e.target.value)}>
                        <option value="">---Sin Promotor---</option>
                        {listaPromotores.map(data =>
                            <optgroup key={data.id} label={data.id}>
                                <option value={data.id}>{data.nombre}</option>
                            </optgroup>
                        )}
                    </select>
                </div>

                <h5>Numero de referencia</h5>

                <div className='input-container'>
                    <input className='input-normal' id="profile-referencia" type="text" value={referencia} onChange={e => { setReferencia(e.target.value.toUpperCase()) }} />
                    <label className='input-label' htmlFor='profile-referencia'>Refencia</label>
                </div>

                <h5>Observaciones</h5>

                <textarea id="w3review" name="w3review" rows="6" cols="50" value={observaciones} onChange={e => { setObservaciones(e.target.value) }} />

                <p><small style={{ color: 'var(--red)' }}>{errorMsg}</small></p>

                {
                    loading ? <h3>Enviando...</h3> : <button type='submit' className='filled-button'>Crear afiliado</button>
                }

            </form>
        </div>
    )
}