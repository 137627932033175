import Chat from '../components/Chat';
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, useContext } from 'react';
import styles from './CasoLegal.module.css';
import { collection, query, onSnapshot, getFirestore, addDoc, Timestamp, doc, orderBy, getDoc, where, updateDoc, setDoc } from "firebase/firestore";
import { UserContext } from '../UserContext';

const db = getFirestore();

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CasoLegal() {
    // CONTEXT
    const { userLevel, userEmail } = useContext(UserContext);
    // 
    const [initialObj, setInitialObj] = useState({});
    // NOTIFICATION
    const [visto, setVisto] = useState(true);
    // AFILIADO
    const [afiliado, setAfiliado] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    // ESTADOS
    const [abogado, setAbogado] = useState('');
    const [proceso, setProceso] = useState('');
    const [accion, setAccion] = useState('');
    const [etapa, setEtapa] = useState('');
    const [estado, setEstado] = useState('');
    // FECHAS
    const [fechaCreacion, setFechaCreacion] = useState('');
    const [fechaImportante, setFechaImportante] = useState('');
    // CASO
    const [demandado, setDemandado] = useState('');
    const [expediente, setExpediente] = useState('');
    // GENERAL
    const [tipo, setTipo] = useState('');
    // INSTITUCIONES
    const [listaInstituciones, setListaInstituciones] = useState([]);
    const [institucion, setInstitucion] = useState('');
    // MIGRACION
    const [dataList, setDataList] = useState([]);

    let que = useQuery();
    const casoId = que.get("caso");

    // function convertTimestampToDate(timestamp) {
    //     var date = new Date(timestamp);
    //     var day = date.getDate() + 1;
    //     var month = date.getMonth() + 1;
    //     var year = date.getFullYear();
    //     return year + "-" + month + "-" + day;
    // }

    // function convertTimestampToDate(timestamp) {
    //     let date = new Date(timestamp);
    //     let day = String(date.getDate()).padStart(2, '0'); // ensure day is 2 digits
    //     let month = String(date.getMonth() + 1).padStart(2, '0'); // ensure month is 2 digits
    //     let year = date.getFullYear();
    //     return `${year}-${month}-${day}`;
    // }

    function convertTimestampToDate(timestamp) {
        // Crea una fecha en UTC basada en el timestamp
        let date = new Date(timestamp);

        // Obtiene el día, mes y año en UTC
        let day = String(date.getUTCDate()).padStart(2, '0'); // Asegura que el día tenga 2 dígitos
        let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Asegura que el mes tenga 2 dígitos
        let year = date.getUTCFullYear();

        // Retorna la fecha en formato 'YYYY-MM-DD'
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "instituciones", "lista");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data().instituciones);
                setListaInstituciones(docSnap.data().instituciones.sort());
            } else {
                console.log("No such document!");
            }
        }
        fetchData();
    }, []);

    const instList = listaInstituciones.map(data =>
        <option key={data} value={data}>{data}</option>
    );

    useEffect(() => {
        const docRef = doc(db, "legales", casoId);

        const unsubscribe = onSnapshot(docRef, async (docSnap) => {
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setAfiliado(docSnap.data().afiliado);
                setDemandado(docSnap.data().demandado);
                setExpediente(docSnap.data().expediente);
                setAbogado(docSnap.data().abogado);
                setProceso(docSnap.data().proceso);
                setAccion(docSnap.data().accion);
                setTipo(docSnap.data().tipo);
                setInstitucion(docSnap.data().institucion);
                setEtapa(docSnap.data().etapa);
                setEstado(docSnap.data().estado);
                setVisto(docSnap.data().visto);

                var fechaCreacion = convertTimestampToDate(docSnap.data().fechaCreacion.seconds * 1000);
                setFechaCreacion(fechaCreacion);

                var fechaImportanteData = docSnap.data().fechaImportante;
                if (fechaImportanteData) {
                    var fechaImportante = convertTimestampToDate(fechaImportanteData.seconds * 1000);
                    console.log("FECHA IMPORTANTE");
                    console.log(fechaImportante);
                    setFechaImportante(fechaImportante);
                } else {
                    console.log('No hay fecha importante');
                }

                setInitialObj({
                    afiliado: docSnap.data().afiliado,
                    demandado: docSnap.data().demandado,
                    expediente: docSnap.data().expediente,
                    abogado: docSnap.data().abogado,
                    proceso: docSnap.data().proceso,
                    accion: docSnap.data().accion,
                    tipo: docSnap.data().tipo,
                    institucion: docSnap.data().institucion,
                    etapa: docSnap.data().etapa,
                    estado: docSnap.data().estado,
                    fechaCreacion: fechaCreacion,
                    fechaImportante: fechaImportante
                });

                const afiliadoSnap = await getDoc(doc(db, "usuarios", docSnap.data().afiliado));
                if (afiliadoSnap.exists()) {
                    // console.log("Document data:", afiliadoSnap.data());
                    setNombre(afiliadoSnap.data().nombre);
                    setApellidos(afiliadoSnap.data().apellidos);
                    setEmail(afiliadoSnap.data().email);
                    setCelular(afiliadoSnap.data().contacto2);
                }
            } else {
                console.log("No such document!");
            }
        });

        // Cleanup function
        // return () => unsubscribe();
    }, []);

    const [adminList, setAdminList] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "admins"), where("level", "in", [8, 9]));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({
                    key: doc.id,
                    name: doc.data().name,
                });
            });
            setAdminList(docs);
        });
        return () => unsubscribe();
    }, []);

    const editTextField = async (field, value) => {
        console.log('Editando ' + field + ' con valor ' + value);
        try {

            if ((userLevel !== 8 && userLevel !== 0) && field === 'abogado') {
                alert('No tienes permisos para editar este campo');
                return;
            }

            const caseRef = doc(db, "legales", casoId);

            var obj = {};
            obj[field] = value;

            await updateDoc(caseRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            alert('Hubo un error al actualizar este dato');
        }
    }

    const editDateField = async (field, value) => {
        try {
            const userRef = doc(db, "legales", casoId);

            var obj = {};

            var d = new Date(value);
            obj[field] = Timestamp.fromDate(d);;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            alert('Hubo un error al actualizar este dato');
        }
    }

    const editBooleanField = async (field, value) => {
        try {
            const userRef = doc(db, "legales", casoId);

            var obj = {};
            obj[field] = value;

            if (abogado === userEmail) {
                await updateDoc(userRef, obj);
            } else {
                alert('Solo el abogado asignado puede marcar este caso como visto');
            }
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            alert('Hubo un error al actualizar este dato');
        }
    }

    function csvToObject(csv) {
        // Parse the file
        const lines = csv.split('\n');
        const result = [];
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentline = lines[i].split(',');

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j].trim()] = currentline[j].trim();
            }

            result.push(obj);
        }

        // result now contains the array of objects
        return result;
    }

    function convertTsvToArrayOfObjects(tsvData) {
        // Split the TSV data into lines
        const lines = tsvData.split('\n');

        // Extract the header line and split into fields
        const headers = lines.shift().split('\t');

        // Map each line to an object, using the headers as keys
        const objects = lines.map(line => {
            const data = line.split('\t');
            return headers.reduce((obj, nextKey, index) => {
                obj[nextKey] = data[index];
                return obj;
            }, {});
        });

        return objects;
    }


    function handleFile(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function (evt) {
            console.log(evt.target.result);
            const arrayOfObjects = convertTsvToArrayOfObjects(evt.target.result);
            console.log(arrayOfObjects);
            // Parse the file
            // const lines = evt.target.result.split('\n');
            // const result = [];
            // // const headers = lines[0].split(',');
            // const headers = lines[0].split('\t');

            // for (let i = 1; i < lines.length; i++) {
            //     const obj = {};
            //     // const currentline = lines[i].split(',');
            //     const currentline = lines[i].split('\t');

            //     for (let j = 0; j < headers.length; j++) {
            //         obj[headers[j].trim()] = currentline[j].trim();
            //     }

            //     result.push(obj);
            // }
            // console.log(result);
            setDataList(arrayOfObjects);
        };
        reader.readAsText(file);
    }



    // const uploadToFirebase = () => {
    //     dataList.forEach(async element => {
    //         try {
    //             console.log(element);
    //             const crearDocumento = await addDoc(collection(db, "legales"), {
    //                 afiliado: element.ID,
    //                 demandado: element.DEMANDADO,
    //                 estado: 'ACTIVO',
    //             });
    //             console.log(crearDocumento._key.path.segments[1]);
    //             // console.log(`${element.ID} : Documento creado con exito: ${element.ID} - ${element.DEMANDADO}`);
    //             const pRow = document.createElement('code');
    //             pRow.innerHTML = `${element.ID} : Documento creado con exito: ${element.ID} - ${element.DEMANDADO}`;
    //             document.getElementById('logs').appendChild(pRow);
    //         } catch (error) {
    //             console.log(error.message);
    //             const pRow = document.createElement('code');
    //             pRow.innerHTML = error.message;
    //             document.getElementById('logs').appendChild(pRow);
    //         }
    //     });
    // }

    function capitalizeNames(string) {
        return string.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(' ');
    }

    const updateToFirebase = async () => {
        for (let element of dataList) {
            try {
                const docRef = doc(db, "usuarios", element.ID);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    var message = `¡Hola ${capitalizeNames(docSnap.data().nombre)}! Felicidades 🎉, ya ingresaste a la nueva app de ANEP. Mi nombre es ${element.ABOGADO} y soy el abogado asignado a tu caso, puedes contactarme por este medio para saber novedades de este caso.`

                    const obj = {
                        uid: 'system',
                        message: message,
                        public: true,
                        type: 'text',
                        timestamp: Timestamp.now()
                    };

                    await addDoc(collection(db, `legales/${element.DOCID}/chat`), obj);

                    console.log(obj);

                } else {
                    console.log("No such document!");
                }

                // const casoRef = doc(db, "legales", element.DOCID);
                // await updateDoc(casoRef, {
                //     tipo: 'INDIVIDUAL'
                // });

                // const obj = {
                //     uid: 'system',
                //     message: 'Bienvenido/a al App ANEP al registro de actividad de este caso. Aquí podrá comunicarse con el abogado asignado.',
                //     public: true,
                //     type: 'text',
                //     timestamp: Timestamp.now()
                // };

                // await addDoc(collection(db, `legales/${element.DOCID}/chat`), obj);
                // console.log(crearDocumento._key.path.segments[1]);

                const pRow = document.createElement('code');
                pRow.innerHTML = `Chat creado: ${element.DOCID}`;
                document.getElementById('logs').appendChild(pRow);

            } catch (error) {
                console.log(error);

                const pRow = document.createElement('code');
                pRow.innerHTML = `Error: ${element.DOCID} ${error.message}`;
                document.getElementById('logs').appendChild(pRow);
            }
        }
    }

    const uploadToFirebase = async () => {
        let tsvContent = "ID\tFECHA\tEXPEDIENTE\tDEMANDADO\tINSTANCIA\tGESTION\tMOVIMIENTO\tABOGADO\tDOCID\n";

        let nameEmailMap = {
            "Rosa Rivera Bejarano": "alvarov@anep.or.cr",
            "Gustavo Hernandez Picado": "gustavohernandez@anep.or.cr",
            "Harry Flores Castillo": "harryflores@anep.or.cr",
            "José Pablo Quirós Picado": "jpquiros@anep.or.cr",
            "María del Mar López Delgado": "marialopez@anep.or.cr",
            "David Estrada Zeledón": "davidestrada@anep.or.cr",
            "Priscilla Castro Jiménez": "priscillac@anep.or.cr",
            "Diana Araya Carvajal": "dianaaraya@anep.or.cr",
            "Ronny": "ronnyf@anep.or.cr",
            "Karen Carvajal Loaiza": "karenc@anep.or.cr",
        };

        for (let element of dataList) {
            try {
                // console.log(element);

                // Convert the year to a date (January 1 of that year)
                let yearToDate = new Date(element.FECHA, 0, 1);

                const obj = {
                    afiliado: element.ID,
                    demandado: element.DEMANDADO,
                    expediente: element.EXPEDIENTE,
                    abogado: nameEmailMap[element.ABOGADO.trim()] || '',
                    estado: 'ACTIVO',
                    fechaCreacion: Timestamp.fromDate(yearToDate),
                };
                const crearDocumento = await addDoc(collection(db, "legales"), obj);
                // console.log(crearDocumento._key.path.segments[1]);
                // const generatedID = "DOCID";
                const generatedID = crearDocumento._key.path.segments[1];
                const pRow = document.createElement('code');
                pRow.innerHTML = `Documento creado con exito con el ID: ${generatedID} Asignado al afiliado: ${obj.afiliado} y al abogado: ${obj.abogado}\n`;
                document.getElementById('logs').appendChild(pRow);

                // Add the document ID to the TSV content
                tsvContent += `${element.ID}\t${element.FECHA}\t${element.EXPEDIENTE}\t${element.DEMANDADO}\t${element.INSTANCIA}\t${element.GESTION}\t${element.MOVIMIENTO}\t${element.ABOGADO}\t${generatedID}\n`;
            } catch (error) {
                console.log(error.message);
                const pRow = document.createElement('code');
                pRow.innerHTML = error.message;
                document.getElementById('logs').appendChild(pRow);
            }
        }

        // csvContent now contains the updated CSV data as a string
        console.log(tsvContent);

        // Create a blob from the CSV content
        // let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        let blob = new Blob([tsvContent], { type: 'text/tab-separated-values;charset=utf-8;' });

        // Create a link element
        let link = document.createElement("a");

        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        var d = new Date();
        link.setAttribute("download", `${d.toDateString()} ${d.toTimeString()} output.tsv`);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    let date = new Date(fechaCreacion);
    let costaRicaDateString = date.toLocaleDateString();


    return (
        <div className={`page ${styles.layout}`}>
            <section className={styles.main}>
                <div className={styles.header}>
                    <h1 style={{ margin: '0' }}>Caso Legal</h1>
                    <code><small>{casoId}</small></code>

                    {
                        !visto &&
                        <button className='elevated-button' onClick={() => editBooleanField('visto', true)}>
                            <div className={styles.icon_badge} />
                            Marcar como visto
                        </button>
                    }


                </div>

                <div className='segmented-button-container'>
                    <button className={tipo === 'INDIVIDUAL' ? 'segmented-button active' : 'segmented-button'} onClick={() => editTextField('tipo', 'INDIVIDUAL')}>
                        {
                            tipo === 'INDIVIDUAL' ?
                                <span className="material-symbols-rounded">check</span> :
                                <span className="material-symbols-rounded">person</span>
                        }
                        Individual
                    </button>
                    <button className={tipo === 'COLECTIVO' ? 'segmented-button active' : 'segmented-button'} onClick={() => editTextField('tipo', 'COLECTIVO')}>
                        {
                            tipo === 'COLECTIVO' ?
                                <span className="material-symbols-rounded">check</span> :
                                <span className="material-symbols-rounded">groups</span>
                        }
                        Colectivo
                    </button>
                </div>

                {
                    tipo === 'INDIVIDUAL' &&
                    <>
                        <h4 style={{ margin: '1rem 0', display: 'flex', gap: '0.5rem' }}>
                            <span className='material-symbols-rounded'>
                                link
                            </span>
                            Afiliado {afiliado}
                            <Link to={{ pathname: '/profile', search: '?id=' + afiliado }}>Ver perfil</Link>
                        </h4>
                        <div className={styles.form_grid}>
                            <div className='input-container'>
                                <input className='input-normal' id={"nombre"} type="text" value={nombre} onChange={e => setNombre(e.target.value.toLocaleUpperCase())} style={{ fontSize: '0.875rem' }} disabled />
                                <label className='input-label' htmlFor={"nombre"}>Nombre</label>
                            </div>
                            <div className='input-container'>
                                <input className='input-normal' id={"apellidos"} type="text" value={apellidos} onChange={e => setApellidos(e.target.value.toLocaleUpperCase())} style={{ fontSize: '0.875rem' }} disabled />
                                <label className='input-label' htmlFor={"apellidos"}>Apellidos</label>
                            </div>
                            <div className='input-container'>
                                <input className='input-normal' id={"email"} type="text" value={email} onChange={e => setEmail(e.target.value.toLocaleUpperCase())} style={{ fontSize: '0.875rem' }} disabled />
                                <label className='input-label' htmlFor={"email"}>Email</label>
                            </div>
                            <div className='input-container'>
                                <input className='input-normal' id={"celular"} type="text" value={celular} onChange={e => setCelular(e.target.value.toLocaleUpperCase())} style={{ fontSize: '0.875rem' }} disabled />
                                <label className='input-label' htmlFor={"celular"}>Celular</label>
                            </div>
                        </div>
                    </>
                }

                {
                    tipo === 'COLECTIVO' &&
                    <>
                        <h4 style={{ margin: '1rem 0', display: 'flex', gap: '0.5rem' }}>
                            <span className='material-symbols-rounded'>
                                link
                            </span>
                            Institucion {institucion}
                        </h4>
                        <div className='input-container'>
                            <select id="select-institucion" value={institucion} onChange={e => setInstitucion(e.target.value)} style={{ width: '200px' }}>
                                <option value="" disabled>---Selecciona una Institucion---</option>
                                {instList}
                            </select>
                            {
                                (initialObj.institucion !== institucion) &&
                                <button className='input-save-button' onClick={() => { editTextField('institucion', institucion) }}>
                                    <span className='material-symbols-rounded'>save</span>
                                </button>
                            }
                        </div>
                    </>
                }

                <h4>Caso</h4>

                <div className={styles.form_grid}>
                    <div className='input-container'>
                        <input className='input-normal' id={"demandado"} type="text" value={demandado} onChange={e => setDemandado(e.target.value.toLocaleUpperCase())} style={{ fontSize: '0.875rem' }} />
                        <label className='input-label' htmlFor={"demandado"}>Demandado</label>
                        {
                            (initialObj.demandado !== demandado) &&
                            <button style={{ right: '2.25rem' }} className='input-save-button' onClick={() => { editTextField('demandado', demandado) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>
                    <div className='input-container'>
                        <input className='input-normal' id={"expediente"} type="text" value={expediente} onChange={e => setExpediente(e.target.value.toLocaleUpperCase())} style={{ fontSize: '0.875rem' }} />
                        <label className='input-label' htmlFor={"expediente"}>Expediente</label>
                        {
                            (initialObj.expediente !== expediente) &&
                            <button style={{ right: '2.25rem' }} className='input-save-button' onClick={() => { editTextField('expediente', expediente) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>
                </div>

                <h4>Abogado asignado</h4>

                <div className='input-container'>
                    <select id="abogado" name="abogado" value={abogado} onChange={e => setAbogado(e.target.value)} disabled={(userLevel !== 8 && userLevel !== 0)}>
                        <option value="" disabled>---Selecciona Abogado---</option>
                        {
                            adminList.map(d =>
                                <option key={d.key} value={d.key}>{d.name}</option>
                            )
                        }
                    </select>
                    {
                        (initialObj.abogado !== abogado) &&
                        <button className='input-save-button' onClick={() => { editTextField('abogado', abogado) }}>
                            <span className='material-symbols-rounded'>save</span>
                        </button>
                    }
                </div>

                <h4>Estados</h4>

                <div className={styles.form_grid}>

                    <div className='input-container'>
                        <select id="estado" name="estado" value={estado} onChange={e => setEstado(e.target.value)}>
                            <option value="" disabled>---Selecciona un Estado---</option>
                            <option value="POR ASIGNAR">Por asignar</option>
                            <option value="ACTIVO">Activo</option>
                            <option value="ARCHIVADO">Archivado</option>
                        </select>
                        {
                            (initialObj.estado !== estado) &&
                            <button className='input-save-button' onClick={() => { editTextField('estado', estado) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>

                    <div className='input-container'>
                        <select id="proceso" name="proceso" value={proceso} onChange={e => setProceso(e.target.value)}>
                            <option value="" disabled>---Selecciona un Proceso---</option>
                            <optgroup label="Procesos judiciales">
                                <option value="A1">A1 - Ordinario laborales</option>
                                <option value="A2">A2 - Procesos especiales de protección</option>
                                <option value="A3">A3 - Infracciones a las leyes laborales</option>
                                <option value="A4">A4 - Acoso laboral</option>
                                <option value="A5">A5 - Riesgos de trabajo</option>
                                <option value="A6">A6 - Contencioso administrativo</option>
                                <option value="A7">A7 - Recursos de amparo</option>
                                <option value="A8">A8 - Acciones de inconstitucionalidad</option>
                            </optgroup>
                            <optgroup label="Vía administrativos">
                                <option value="B1">B1 - Disciplinario</option>
                                <option value="B2">B2 - Reclamos</option>
                                <option value="B3">B3 - Conciliaciones MTSS</option>
                            </optgroup>
                            <optgroup label="Criterios">
                                <option value="C1">C1 - En trámite</option>
                                <option value="C2">C2 - Realizado</option>
                            </optgroup>
                        </select>
                        {
                            (initialObj.proceso !== proceso) &&
                            <button className='input-save-button' onClick={() => { editTextField('proceso', proceso) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>

                    <div className='input-container'>
                        <select id="etapa" name="etapa" value={etapa} onChange={e => setEtapa(e.target.value)}>
                            <option value="">---Selecciona una Etapa---</option>
                            <optgroup label="Procesos judiciales">
                                <option value="EA1">Demanda</option>
                                <option value="EA2">Traslado de demanda</option>
                                <option value="EA3">Audiencia</option>
                                <option value="EA4">Sentencia primera instancia con lugar</option>
                                <option value="EA5">Sentencia de primera instancia sin lugar</option>
                                <option value="EA6">Sentencia segunda instancia con lugar</option>
                                <option value="EA7">Sentencia segunda instancia sin lugar</option>
                                <option value="EA8">Ejecución de sentencia</option>
                                <option value="EA9">Sentencia de ejecución primera instancia</option>
                                <option value="EA10">Sentencia de ejecución de segunda instancia</option>
                            </optgroup>
                            <optgroup label="Vía administrativos">
                                <option value="EB1">Traslado de cargos</option>
                                <option value="EB2">Audiencia</option>
                                <option value="EB3">Resolución final - Absuelto</option>
                                <option value="EB4">Resolución final - Suspensión</option>
                                <option value="EB5">Resolución final - Despido</option>
                                <option value="EB6">Fase recursiva</option>
                                <option value="EB7">Se presento</option>
                                <option value="EB8">Resolución</option>
                                <option value="EB9">Recurso</option>
                                <option value="EB10">Acuerdo</option>
                                <option value="EB11">No acuerdo</option>
                            </optgroup>
                        </select>
                        {
                            (initialObj.etapa !== etapa) &&
                            <button className='input-save-button' onClick={() => { editTextField('etapa', etapa) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>

                    <div className='input-container'>
                        <select id="accion" name="accion" value={accion} onChange={e => setAccion(e.target.value)}>
                            <option value="" disabled>---Selecciona cuál parte requiere acción---</option>
                            <option value="ANEP">Requiere accion de ANEP</option>
                            <option value="AFILIADO">Requiere accion de AFILIADO</option>
                        </select>
                        {
                            (initialObj.accion !== accion) &&
                            <button className='input-save-button' onClick={() => { editTextField('accion', accion) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>

                </div>

                <h4>Fechas</h4>

                <div className={styles.form_grid}>
                    <div>
                        <h5>Fecha de creacion</h5>
                        <p>{costaRicaDateString}</p>
                    </div>
                    <div className='input-container'>
                        <input className='input-normal' id="fechaImportante" type="date" value={fechaImportante} onChange={e => setFechaImportante(e.target.value)} />
                        <label className='input-label' htmlFor='fechaImportante'>Fecha importante</label>
                        {
                            (initialObj.fechaImportante !== fechaImportante) &&
                            <button style={{ right: '2.25rem' }} className='input-save-button' onClick={() => { editDateField('fechaImportante', fechaImportante) }}>
                                <span className='material-symbols-rounded'>save</span>
                            </button>
                        }
                    </div>
                </div>

                {/* <h1>Miagracion</h1>

                <input type="file" id="files" name="file" onChange={handleFile}></input>
                <button className='filled-button' onClick={updateToFirebase}>Upload to Firebase</button>

                <div id='logs'>
                    <h4>Logs:</h4>
                </div> */}
            </section>
            <section className={styles.chat}>
                <Chat caseId={casoId} />
            </section>
        </div>
    );
}