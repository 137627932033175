import { initializeApp } from "firebase/app"
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: "715798290168",
    appId: "1:715798290168:web:1a354c80ad32d629d7650d"
  };
  
  // Initialize Firebase
  export const firebaseApp = initializeApp(firebaseConfig);