import './TopAppBar.css';
import {
    NavigationRegular
} from "@fluentui/react-icons";
import { UserContext } from '../UserContext';
import { useContext } from 'react';

export default function TopAppBar() {
    const { userEmail, userLevel } = useContext(UserContext);

    var scrollTrigger = 64;

    window.onscroll = function () {
        if (window.scrollY >= scrollTrigger) {
            document.getElementById("top-app-bar-surface-tint").classList.add("top-app-bar-surface-tint-scroll");
        } else {
            document.getElementById("top-app-bar-surface-tint").classList.remove("top-app-bar-surface-tint-scroll");
        }
    };

    const abrir = () => {
        document.getElementById('modal-navigation-drawer').classList.add("modal-navigation-drawer-opened");
        document.getElementById('scrim').classList.add("scrim-visible");
    }

    return (
        <header className='top-app-bar'>
            <div className='top-app-bar-surface-tint' id='top-app-bar-surface-tint' style={{ display: 'flex', justifyContent: 'center' }}>
                <p>N{userLevel}</p>
                {/* <button className='icon-button' onClick={abrir}>
                    <NavigationRegular fontSize={24} />
                </button> */}
                <h3 className='top-app-bar-title'>Administrador ANEP</h3>

                <div className='top-app-bar-user'>
                    {/* <p><small><b>Admin Level 2</b></small></p> */}
                    <p><small>{userEmail}</small></p>
                </div>

            </div>
        </header>
    )
};