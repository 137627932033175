import { getFirestore, query, collection, orderBy, limit, onSnapshot, Timestamp, addDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';
import Post from '../components/Post';
import './Noticias.css';
import { set } from "firebase/database";
const db = getFirestore();

export default function Noticias() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [newsList, setNewsList] = useState([]);
    const [errorMessage, setErrorMesage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "news"), orderBy("timestamp", "desc"), limit(10));
            onSnapshot(q, (querySnapshot) => {
                var array = [];
                querySnapshot.forEach((doc) => {
                    array.push({
                        id: doc.id,
                        ...doc.data()
                        // title: doc.data().title,
                        // description: doc.data().description,
                        // thumbnail: doc.data().thumbnail,
                        // likesCounter: doc.data().likesCounter,
                        // url: doc.data().url,
                        // timestamp: doc.data().timestamp.seconds
                    });
                });
                console.log(array);
                setNewsList(array);
            });
        }
        fetchData();
    }, []);

    const createNewWithConfirmation = async () => {
        setErrorMesage('');
        if (title === '' || description === '') {
            setErrorMesage('Por favor, rellena todos los campos.');
            return;
        }

        // Display confirmation dialog
        const isConfirmed = window.confirm("Estas seguro que deseas agregar esta noticia?");
        if (isConfirmed) {
            // If the user confirmed, proceed with createNew
            await createNew();
        } else {
            // If the user canceled, you can optionally handle this case
            console.log("News creation was canceled by the user.");
        }
    };

    const createNew = async () => {
        setErrorMesage('');
        try {
            const docRef = await addDoc(collection(db, "news"), {
                title: title,
                description: description,
                thumbnail: "",
                likesCounter: 0,
                timestamp: Timestamp.now(),
                url: url
            });
            console.log("Document written with ID: ", docRef.id);
            setTitle('');
            setDescription('');
            setUrl('');
        } catch (error) {
            console.log(error);
            setErrorMesage('Code N01: Hubo un error en la creacion del documento.');
        }

    }

    return (
        <div className="page">
            <h1>Noticias</h1>

            <div className="news-form-container">
                <div className='input-container'>
                    <input className='input-normal' id="profile-celular" type="text" value={title} onChange={e => setTitle(e.target.value)} />
                    <label className='input-label' htmlFor='profile-celular'>Titulo</label>
                </div>

                <h4>Contenido</h4>

                <div className='input-container'>
                    <textarea id="w3review" style={{ width: '100%' }} name="w3review" rows="6" cols="50" value={description} onChange={e => setDescription(e.target.value)} />
                </div>

                <div className='input-container'>
                    <input className='input-normal' id="profile-celular" type="text" value={url} onChange={e => setUrl(e.target.value)} />
                    <label className='input-label' htmlFor='profile-celular'>URL (Ver más)</label>
                </div>

                <div>
                    <button style={{ marginLeft: 'auto' }} className="filled-button" onClick={createNewWithConfirmation}>Post</button>
                </div>

                <p style={{ color: 'var(--color-error)' }}>{errorMessage}</p>
            </div>


            <h3>Ultimas noticias</h3>
            {
                newsList.map(data =>
                    <Post key={data.id} id={data.id} title={data.title} description={data.description} thumbnail={data.thumbnail} likesCounter={data.likesCounter} />
                )
            }
        </div>
    )
}