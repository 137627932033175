import { useEffect, useState, useContext } from 'react';
import styles from './Chat.module.css';
import {
    SendFilled,
    AttachRegular
} from "@fluentui/react-icons";
import { collection, query, onSnapshot, getFirestore, addDoc, Timestamp, doc, orderBy, getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, connectStorageEmulator, getDownloadURL, getStorage } from 'firebase/storage';
import { UserContext } from '../UserContext';

const db = getFirestore();
const storage = getStorage();

export default function Chat({ caseId }) {
    // USER CONTEXT
    const { userEmail, userId } = useContext(UserContext);
    // CHAT
    const [messages, setMessages] = useState([]);
    // INPUT
    const [message, setMessage] = useState('');
    const [publicMessage, setPublicMessage] = useState(true);
    const [attachment, setAttachment] = useState(null);
    const [attachmentName, setAttachmentName] = useState('');
    // FILE UPLOAD
    const storageRef = ref(storage);
    const imagesRef = ref(storageRef, 'images');
    const fileName = 'space.jpg';
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    // DEBUG
    const [sending, setSending] = useState(false);

    useEffect(() => {
        const q = query(collection(db, `legales/${caseId}/chat`), orderBy('timestamp', 'desc'));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = [];
            querySnapshot.forEach((doc) => {
                msgs.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            console.log("Current messages: ", msgs.join(", "));
            setMessages(msgs);
        });

    }, []);

    const sendMessage = async () => {
        setSending(true);
        try {
            if (message === '') {
                console.log('Mensaje vacio');
                setSending(false);
                return;
            }
            if (window.confirm(`¿Estas seguro de registrar el mensaje de manera ${publicMessage ? 'PÚBLICA' : 'PRIVADA'}?`)) {
                await addDoc(collection(db, `legales/${caseId}/chat`), {
                    message: message,
                    timestamp: Timestamp.now(),
                    public: publicMessage,
                    uid: userId,
                    userEmail: userEmail,
                    type: 'text'
                });
                setMessage('');
            } else {
                console.log('Mensaje cancelado');
            }
            console.log('Mensaje enviado');
            setSending(false);
        } catch (error) {
            console.error("Error adding document: ", error);
            setSending(false);
        }
    }

    const handleFileChange = (event) => {
        var file = event.target.files[0];
        setFile(file);
        setMessage(file.name);
    };

    const handleUpload = () => {
        setSending(true);

        if (window.confirm(`¿Estas seguro de registrar el mensaje de manera ${publicMessage ? 'PÚBLICA' : 'PRIVADA'}?`)) {
            console.log('Mensaje confirmado');
            if (file) {
                const metadata = {
                    contentType: file.type,
                };
                const storageRef = ref(storage, `legales/${caseId}/chat/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                }, (error) => {
                    console.error(error);
                }, () => {
                    getDownloadURL(storageRef).then(async (url) => {
                        console.log('File available at', url);
                        await addDoc(collection(db, `legales/${caseId}/chat`), {
                            message: message,
                            file: url,
                            timestamp: Timestamp.now(),
                            public: publicMessage,
                            uid: userId,
                            userEmail: userEmail,
                            type: 'file'
                        });
                    });
                    console.log('Upload complete');
                    setProgress(0);
                    setMessage('');
                    setFile(null);
                    setSending(false);
                });
            }
        } else {
            console.log('Mensaje cancelado');
            setSending(false);
        }
    };

    const cancelUpload = () => {
        setFile(null);
        setMessage('');
        setProgress(0);
    }

    return (
        <div className={styles.container}>
            <div>
                <h3>Registro de actividad </h3>
            </div>
            <div className={styles.content}>
                {
                    messages.map((msg) =>
                        <Message
                            key={msg.id}
                            message={msg.message}
                            uid={msg.uid}
                            public={msg.public}
                            timestamp={msg.timestamp}
                            type={msg.type}
                            file={msg.file}
                            userEmail={msg.userEmail}
                        />
                    )
                }
            </div>
            <div className={styles.input}>
                <input type="file" id='file-input' onChange={handleFileChange} style={{ display: 'none' }} />
                {
                    file ?
                        <button className='icon-button' onClick={cancelUpload}>
                            <span className="material-symbols-rounded">
                                close
                            </span></button> :
                        <button className='icon-button' onClick={() => document.getElementById('file-input').click()}>
                            <span className="material-symbols-rounded">
                                attach_file
                            </span>
                        </button>
                }
                {/* <input className={styles.input_text_field} value={message} onChange={e => setMessage(e.target.value)} type="text" placeholder='Escribe aqui' /> */}
                <textarea className={styles.input_text_field} value={message} onChange={e => setMessage(e.target.value)} type="text" placeholder='Escribe aqui' />

                <button className='icon-button' onClick={() => setPublicMessage(!publicMessage)}>
                    {
                        publicMessage ?
                            <span className="material-symbols-rounded" style={{ color: 'var(--color-primary)' }}>
                                visibility
                            </span> :
                            <span className="material-symbols-rounded">
                                visibility_off
                            </span>
                    }
                </button>

                {
                    file ?
                        <button className='icon-button' onClick={handleUpload} disabled={sending}>
                            <span className="material-symbols-rounded">
                                upload
                            </span></button> :
                        <button className='icon-button' onClick={sendMessage} disabled={sending}>
                            <span className="material-symbols-rounded">
                                send
                            </span>
                        </button>
                }

                {progress > 0 && <progress className={styles.progress} value={progress} max="100" />}

            </div>
        </div>
    );
}

function Message(props) {
    const [user, setUser] = useState(null); // [name, avatar]

    const { userId } = useContext(UserContext);

    const uid = props.uid;
    const message = props.message;
    const isPublic = props.public;
    const isMe = uid === userId;
    const isSystem = uid === 'system';
    const timestamp = props.timestamp;
    const userEmail = props.userEmail;

    const dateFromTimestamp = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();
        return `${day}/${month}/${year} ${hour}:${minute}`;
    }

    const systemAvatarURL = "https://cdn3d.iconscout.com/3d/premium/thumb/humanoid-robot-9505141-7781242.png?f=webp";
    // const defaultAvatarURL = "https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/admins%2FKvS8r1mFsOjAnotYAzpQ%2Fthumbnail.jpg?alt=media&token=982f354b-4156-41a2-b6a5-c6600d22bc1e";
    const defaultAvatarURL = "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
    const userAvatarURL = user && user.photoURL ? user.photoURL : defaultAvatarURL;
    const userName = user && user.name ? user.name : 'Usuario';

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "admins", userEmail);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setUser({
                    name: docSnap.data().name ? docSnap.data().name : "Usuario",
                    photoURL: docSnap.data().photoURL ? docSnap.data().photoURL : defaultAvatarURL
                });
            } else {
                console.log("No such document!");
            }
        }
        fetchData();
    }, [])


    return (
        <div className={`${styles.message} ${isMe ? styles.message_me : ''} ${isSystem ? styles.message_system : ''}`}>
            <img className={styles.message_avatar} src={isSystem ? systemAvatarURL : userAvatarURL} alt="Avatar" />
            <div className={`${styles.message_content} ${isMe ? styles.message_content_me : ''}`}>
                <span className={styles.message_person_name}>
                    {
                        isMe ? userName :
                            isSystem ? 'Sistema' :
                                user ? user.name : userName
                    }
                </span>
                {
                    props.type === 'file' ?
                        <a className={styles.message_link} href={props.file} target='_blank'>
                            <span className="material-symbols-rounded">
                                draft
                            </span>
                            {message}
                        </a> :
                        <p>{message}</p>
                }
                <span className={styles.message_date}>{dateFromTimestamp(timestamp)}</span>
                <div className={styles.message_public}>
                    {
                        isPublic ?
                            <span className="material-symbols-rounded" style={{ color: 'var(--color-primary)' }}>
                                visibility
                            </span> :
                            <span className="material-symbols-rounded" style={{ opacity: '0.3' }}>
                                visibility_off
                            </span>
                    }
                </div>
            </div>
        </div>
    )

}