import { useEffect, useState } from "react";
import { getFirestore, collection, doc, onSnapshot, updateDoc, arrayRemove, arrayUnion } from "firebase/firestore";
import { DeleteRegular } from "@fluentui/react-icons";

const db = getFirestore();

export default function Dependencias() {
    const [instArray, setInstArray] = useState([]);
    const [addValue, setAddValue] = useState('');

    useEffect(() => {
        onSnapshot(doc(db, "instituciones", "lista"), (doc) => {
            setInstArray(doc.data().dependencias.sort());
        });
    }, []);

    const borrarInst = async (data) => {
        let text = `Seguro que desea eliminar la dependencia ${data} ?`;
        if (window.confirm(text) == true) {
            await updateDoc(doc(db, "instituciones", "lista"), {
                dependencias: arrayRemove(data)
            });
        } else {
            console.log("usuario ha cancelado");
        }
    };

    const agregar = async (e) => {
        e.preventDefault();
        setAddValue('');
        await updateDoc(doc(db, "instituciones", "lista"), {
            dependencias: arrayUnion(addValue)
        });
    }
    const list = instArray.map(data =>
        <tr key={data} className='data'>
            <td>{data}</td>
            <td><button className='filled-tonal-button' onClick={() => { borrarInst(data) }}><DeleteRegular fontSize={24} />Borrar</button></td>
        </tr>
    )

    return (
        <div className="page">
            <h1>Dependencias</h1>


            <div className="instituciones" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <p style={{ margin: "1rem 0" }}>En esta sección se puede agregar o eliminar las dependencias de las instituciones en las que laboran los afiliados de la ANEP.</p>
                <form onSubmit={agregar} style={{ display: 'flex', gap: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
                    <div className='input-container' style={{ width: '400px' }}>
                        <input className='input-normal' id="institucion-add" type="text" value={addValue} onChange={e => setAddValue(e.target.value.toLocaleUpperCase())} />
                        <label className='input-label' htmlFor='institucion-add'>Nombre de dependencia</label>
                    </div>
                    <div>
                        <button type='submit' className="filled-button">Agregar</button>
                    </div>

                </form>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Dependencia</th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {list}
                </tbody>
            </table>
        </div>
    );
}