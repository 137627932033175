import { useContext, useEffect, useState } from 'react';
import { addDoc, collection, deleteDoc, doc, documentId, getDoc, getDocs, getFirestore, onSnapshot, query, Timestamp, updateDoc, where } from "firebase/firestore";
import './Evento.css';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowLeftRegular, ArrowStepBackRegular, Calendar3DayRegular, CalendarAddRegular, CalendarLtrFilled, CalendarLtrRegular, ClockRegular, DeleteRegular, EditRegular, LinkFilled, LinkRegular, LocationFilled, LocationRegular, TimerRegular } from '@fluentui/react-icons';
import { UserContext } from '../UserContext';

const db = getFirestore();

export default function Evento() {
    const { userEmail } = useContext(UserContext);
    let [searchParams, setSearchParams] = useSearchParams();

    const [open, setOpen] = useState(false);

    const [eventObject, setEventObject] = useState({});
    const [selectedParticipant, setSelectedParticipant] = useState('');
    const [participantsList, setParticipantsList] = useState([]);
    const [participantesKeys, setParticipantesKeys] = useState([]);

    // const [now, setNow] = useState(new Date);
    const [fecha, setFecha] = useState(new Date);
    const [fechaInicio, setFechaInicio] = useState(new Date);
    const [fechaFin, setFechaFin] = useState(new Date);

    const [creador, setCreador] = useState('');

    const [usuario, setUsuario] = useState('');

    const [titulo, setTitulo] = useState('');
    const [hora, setHora] = useState('');
    const [duracion, setDuracion] = useState('15');
    const [lugar, setLugar] = useState('');
    const [link, setLink] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const [adminList, setAdminList] = useState([]);

    const dayNames = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];

    // const diffInMinutes = (fechaFin.getTime() - fechaInicio.getTime()) / (1000 * 60);
    // const hours = Math.floor(diffInMinutes / 60);
    // const minutes = diffInMinutes % 60;
    // console.log(hours + ' horas ' + minutes + ' minutos');
    // console.log(diffInMinutes);

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "eventos", searchParams.get('id'));
            const unsub = onSnapshot(docRef, async (doc) => {
                console.log("Current data: ", doc.data());
                var d = doc.data();

                var start = new Date(doc.data().inicio.seconds * 1000);
                var end = new Date(doc.data().fin.seconds * 1000);
                // console.log(start, end)
                // console.log(new Date(), new Date(Date.now() + 86400000))

                // DISABLE BUTTON
                if ((end.getTime() + 86400000) < Date.now()) {
                    console.log('Evento finalizo hace mas de 1 dia');
                    document.getElementById("event-submit-button").disabled = true;
                }
                // DISABLE BUTTON

                var diffInMinutes = (end.getTime() - start.getTime()) / (1000 * 60);

                setFechaInicio(start);
                setFechaFin(end);

                setTitulo(d.titulo);
                setDescripcion(d.descripcion);
                setLugar(d.lugar);
                setLink(d.link);
                setCreador(d.creador);
                setFecha(start.toISOString().slice(0, 10));
                setHora(start.toTimeString().slice(0, 8));
                setDuracion(diffInMinutes.toString());

                setParticipantesKeys(d.participantes);

                setFechaInicio(start);
                setFechaFin(end);

                if (doc.data().participantes.length > 0) {
                    const q = query(collection(db, "admins"), where(documentId(), "in", doc.data().participantes));
                    var array = [];
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((d) => {
                        var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                        var obj = {
                            key: d.id,
                            photoURL: d.data().photoURL ? d.data().photoURL : defaultPhoto,
                            name: d.data().name,
                            email: d.data().email
                        }
                        // console.log(doc.data().photoURL);
                        array.push(obj);
                    });
                    setParticipantsList(array);
                }
            });
        }
        fetchData();
    }, [])

    // console.log(fecha)

    // const diffInMinutes = (fechaFin.getTime() - fechaInicio.getTime()) / (1000 * 60);
    // const hours = Math.floor(diffInMinutes / 60);
    // const minutes = diffInMinutes % 60;
    // console.log(hours + ' horas ' + minutes + ' minutos');
    // console.log(diffInMinutes);

    useEffect(() => {
        if (selectedParticipant !== '') {
            if (!participantesKeys.includes(selectedParticipant)) {
                var admin = adminList.find(p => p.key === selectedParticipant);
                setParticipantsList([...participantsList, admin]);
                setParticipantesKeys([...participantesKeys, selectedParticipant]);
            }
            setSelectedParticipant('');
        }
    }, [selectedParticipant]);

    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "admins"));
            var array = [];
            querySnapshot.forEach((doc) => {
                var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                array.push({
                    key: doc.id,
                    name: doc.data().name,
                    photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto
                });
            });
            setAdminList(array);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (participantesKeys.length > 0) {
                const q = query(collection(db, "admins"), where(documentId(), "in", participantesKeys));
                var array = [];
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                    var obj = {
                        key: doc.id,
                        photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto,
                        name: doc.data().name,
                        email: doc.data().email
                    }
                    array.push(obj);
                });
                setParticipantsList(array);
            }
        }
        fetchData();
    }, []);

    const removeFromArray = (elementToRemove) => {
        setParticipantsList(participantsList.filter(element => element.key !== elementToRemove));
        setParticipantesKeys(participantesKeys.filter(element => element !== elementToRemove));
    }



    const submitEvent = async e => {
        e.preventDefault();
        try {
            let convertedDate = new Date(fecha + "T" + hora);
            let datePlusDuration = convertedDate.setMinutes(convertedDate.getMinutes() + parseInt(duracion));
            console.log(parseInt(duracion));
            console.log(convertedDate);
            console.log(new Date(datePlusDuration));

            const docRef = doc(db, "eventos", searchParams.get('id'));
            await updateDoc(docRef, {
                titulo: titulo,
                descripcion: descripcion,
                link: link,
                lugar: lugar,
                inicio: Timestamp.fromDate(new Date(fecha + "T" + hora)),
                fin: Timestamp.fromDate(convertedDate),
                participantes: participantesKeys,
            });
            setOpen(false);
        } catch (error) {
            alert('Hubo un error, verifica que todos los campos esten correctos.');
            console.log(error);
        }
    }

    const deleteEvent = async () => {
        try {
            let text = "Confirma la eliminacion del evento";
            if (window.confirm(text) == true) {
                await deleteDoc(doc(db, "eventos", searchParams.get('id')));
            } else {
                console.log('Usuario ha cancelado');
            }
            window.history.back();
        } catch (error) {
            alert('Hubo un error al eliminar el evento');
            console.log(error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const q = query(collection(db, "admins"), where("email", "==", userEmail));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    setUsuario(doc.id);
                });
            } catch (error) {
                console.log(error);
            }

        }
        fetchData();
    }, []);

    return (
        <div className='page'>
            <div className='evento-header'>
                <button className='icon-button' onClick={() => window.history.back()}><ArrowLeftRegular fontSize={24} /></button>
                <h1 style={{ margin: '0' }}>Evento</h1>
            </div>

            <div className='evento-card'>
                <div>
                    <h1 className='evento-titulo'>{titulo}</h1>
                </div>


                <div className='evento-fecha'>
                    <CalendarLtrFilled fontSize={24} />
                    <h3>{dayNames[fechaInicio.getDay()] + ' ' + fechaInicio.getDate() + ' ' + monthNames[fechaInicio.getMonth()] + ' ' + fechaInicio.getFullYear()}</h3>
                </div>

                <p>{descripcion}</p>

                {/* <div className='evento-duracion'>
                    <TimerRegular fontSize={24} />
                    <h4>{hours != 0 && hours + ' h '}{minutes + ' min'}</h4>
                </div> */}

                <div className='evento-locacion'>
                    <LocationFilled fontSize={24} />
                    <p>{lugar ? lugar : 'Lugar fisico no disponible'}</p>
                </div>

                <div className='evento-locacion'>
                    <LinkFilled fontSize={24} />
                    <p>{link ? link : 'Link no disponible'}</p>
                </div>

                <h4>Participantes</h4>

                <div className='evento-participantes-container'>
                    {
                        participantsList.map(d =>

                            <div key={d.key} className="evento-participante">
                                <img className='evento-participante-avatar' src={d.photoURL}></img>
                                <p>{d.name}</p>
                            </div>

                        )
                    }
                </div>

                <div className='evento-inicio-fin-container'>
                    <div>
                        <h6>INICIA</h6>
                        <h3>{fechaInicio.getHours() + ':' + String(fechaInicio.getMinutes()).padStart(2, '0')}</h3>
                    </div>
                    <div>
                        <h6>FINALIZA</h6>
                        <h3>{fechaFin.getHours() + ':' + String(fechaFin.getMinutes()).padStart(2, '0')}</h3>
                    </div>
                </div>
            </div>

            {
                usuario === creador &&
                <button className='extended-fab event-fab' onClick={() => setOpen(true)}>
                    <EditRegular fontSize={24} />
                    Editar Evento
                </button>
            }

            <div className="dialog-scrim" style={open ? { display: 'flex' } : { display: 'none' }}>
                <div className="dialog">
                    <div className="dialog-tint">
                        <div className="dialog-header">
                            <CalendarAddRegular fontSize={24} />
                            <h2>Editar evento</h2>
                        </div>

                        <form id='event-update-form' className="event-create-form" onSubmit={submitEvent}>

                            <div className='input-container'>
                                <input className='input-normal' id="event-create-title" type="text" value={titulo} onChange={e => { setTitulo(e.target.value) }} />
                                <label className='input-label' htmlFor='event-create-title'>Titulo</label>
                            </div>

                            <div className='input-container'>
                                <input className='input-normal' id="event-create-date" type="date" value={fecha} onChange={e => { setFecha(e.target.value) }} />
                            </div>

                            <div className='input-container'>
                                <input className='input-normal' id="event-create-time" type="time" step="900" value={hora} onChange={e => { setHora(e.target.value) }} />
                                {/* <label className='input-label' htmlFor='profile-apellidos'>Hora de inicio</label> */}
                            </div>

                            <div className='input-container'>
                                <select id="event-create-duration" value={duracion} placeholder='Duracion' onChange={e => setDuracion(e.target.value)}>
                                    <option value="" disabled>--- Duracion --</option>
                                    <option value='15'>15 min de duracion</option>
                                    <option value='30'>30 min de duracion</option>
                                    <option value='45'>45 min de duracion</option>
                                    <option value='60'>1 h de duracion</option>
                                    <option value='90'>1,5 h de duracion</option>
                                    <option value='120'>2 h de duracion</option>
                                    <option value='150'>2,5 h de duracion</option>
                                    <option value='180'>3 h de duracion</option>
                                    <option value='210'>3,5 h de duracion</option>
                                    <option value='240'>4 h de duracion</option>
                                    <option value='300'>5 h de duracion</option>
                                    <option value='360'>6 h de duracion</option>
                                    <option value='420'>7 h de duracion</option>
                                    <option value='480'>8 h de duracion</option>
                                </select>
                            </div>

                            <div className='input-container'>
                                <input className='input-normal' id="profile-apellidos" type="text" value={lugar} onChange={e => { setLugar(e.target.value) }} />
                                <label className='input-label' htmlFor='profile-apellidos'>Lugar</label>
                            </div>

                            <div className='input-container'>
                                <input className='input-normal' id="profile-apellidos" type="text" value={link} onChange={e => { setLink(e.target.value) }} />
                                <label className='input-label' htmlFor='profile-apellidos'>Link</label>
                            </div>

                            <div>
                                <textarea id="w3review" name="w3review" rows="6" cols="50" placeholder="Descripcion del evento" value={descripcion} onChange={e => { setDescripcion(e.target.value) }} />
                            </div>


                            <div>
                                <div className='input-container'>
                                    <select id="institucion" value={selectedParticipant} onChange={e => setSelectedParticipant(e.target.value)}>
                                        <option value="" disabled>---Selecciona Participantes---</option>
                                        {
                                            adminList.map(d =>
                                                <option key={d.key} value={d.key}>{d.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                {participantsList.map(d =>
                                    <div className="event-create-participant-chip" key={d.key}>
                                        <img className='event-create-avatar' src={d.photoURL}></img>
                                        <p>{d.name}</p>
                                        <button className="icon-button" onClick={() => removeFromArray(d.key)}><DeleteRegular fontSize={24} /></button>
                                    </div>
                                )}

                            </div>

                        </form>

                        <div className="dialog-buttons-container">
                            {
                                (fechaFin.getTime() + 86400000) < Date.now() ? <p style={{ marginRight: 'auto' }}><small>Evento finalizo hace mas de 1 dia, no se puede eliminar ni modificar</small></p>
                                    : <button style={{ color: 'var(--color-error)', marginRight: 'auto' }} className="text-button" onClick={deleteEvent}>Eliminar</button>
                            }

                            <button className="text-button" onClick={() => setOpen(false)}>Atras</button>
                            <button id='event-submit-button' className="filled-button" type="submit" form="event-update-form">Guardar cambios</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <EditEventDialog
                titulo={eventObject.titulo}
                descripcion={eventObject.descripcion}
                lugar={eventObject.lugar}
                link={eventObject.link}
                fecha={fecha}
                fechaFin={fechaFin}
                participantes={eventObject.participantes}
            /> */}

        </div>
    );
}

function EditEventDialog(props) {
    let [searchParams, setSearchParams] = useSearchParams();

    const { userEmail } = useContext(UserContext);

    const [titulo, setTitulo] = useState('');
    const [fecha, setFecha] = useState('');
    const [hora, setHora] = useState('');
    const [duracion, setDuracion] = useState('');
    const [lugar, setLugar] = useState('');
    const [link, setLink] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const [creador, setCreador] = useState('');

    const [selectedParticipant, setSelectedParticipant] = useState('');
    const [participantes, setParticipantes] = useState([]);
    const [participantesKeys, setParticipantesKeys] = useState([]);

    const [adminList, setAdminList] = useState([]);

    useEffect(() => {
        setTitulo(props.titulo);
        setLugar(props.lugar);
        setDescripcion(props.descripcion);
        setFecha(props.fecha.toISOString().slice(0, 10));
        // setParticipantesKeys(props.participantes);
    }, [props])

    useEffect(() => {
        async function fetchData() {
            try {
                const q = query(collection(db, "admins"), where("email", "==", userEmail));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    setCreador(doc.id);
                });
            } catch (error) {
                console.log(error);
            }

        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (participantes.length > 0) {
                const q = query(collection(db, "admins"), where(documentId(), "in", props.participantes));
                var array = [];
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                    var obj = {
                        key: doc.id,
                        photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto,
                        name: doc.data().name,
                        email: doc.data().email
                    }
                    array.push(obj);
                    console.log(doc.data())
                });
                setParticipantes(array);
            }
        }
        fetchData();
    }, [props]);

    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "admins"));
            var array = [];
            querySnapshot.forEach((doc) => {
                var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                array.push({
                    key: doc.id,
                    name: doc.data().name,
                    photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto
                });
            });
            setAdminList(array);
        }
        fetchData();
    }, []);


    useEffect(() => {
        if (selectedParticipant !== '') {
            if (!participantesKeys.includes(selectedParticipant)) {
                var admin = adminList.find(p => p.key === selectedParticipant);
                setParticipantes([...participantes, admin]);
                setParticipantesKeys([...participantesKeys, selectedParticipant]);
            }
            setSelectedParticipant('');
        }
    }, [selectedParticipant]);

    const removeFromArray = (elementToRemove) => {
        setParticipantes(participantes.filter(element => element.key !== elementToRemove));
        setParticipantesKeys(participantesKeys.filter(element => element !== elementToRemove));
    }

    const submitEvent = async e => {
        e.preventDefault();
        try {
            let convertedDate = new Date(fecha + "T" + hora);
            let datePlusDuration = convertedDate.setMinutes(convertedDate.getMinutes() + parseInt(duracion));
            var obj = {
                titulo: titulo,
                descripcion: descripcion,
                link: link,
                lugar: lugar,
                inicio: Timestamp.fromDate(new Date(fecha + "T" + hora)),
                fin: Timestamp.fromDate(new Date(datePlusDuration)),
                participantes: participantesKeys,
                creador: creador
            }
            const docRef = await addDoc(collection(db, "eventos"), obj);
            document.getElementById("event-create-form").reset();
            console.log("Document written with ID: ", docRef.id);
            window.history.back();
        } catch (error) {
            alert('Hubo un error, verifica que todos los campos esten correctos.');
            console.log(error);
        }
    }

    const resetFields = () => {
        setTitulo('');
        setDescripcion('');
        setLugar('');
        setLink('');
        setDuracion('');
    }

    return (
        <div id='event-create-dialog' className="dialog-scrim">
            <div className="dialog">
                <div className="dialog-tint">
                    <div className="dialog-header">
                        <CalendarAddRegular fontSize={24} />
                        <h2>Editar evento</h2>
                    </div>


                    <form id='event-create-form' className="event-create-form" onSubmit={submitEvent} onReset={resetFields}>

                        <div className='input-container'>
                            <input className='input-normal' id="event-create-title" type="text" value={titulo} onChange={e => { setTitulo(e.target.value) }} />
                            <label className='input-label' htmlFor='event-create-title'>Titulo</label>
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="event-create-date" type="date" value={fecha} onChange={e => { setFecha(e.target.value) }} />
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="event-create-time" type="time" step="900" value={hora} onChange={e => { setHora(e.target.value) }} />
                            {/* <label className='input-label' htmlFor='profile-apellidos'>Hora de inicio</label> */}
                        </div>

                        <div className='input-container'>
                            <select id="event-create-duration" value={duracion} placeholder='Duracion' onChange={e => setDuracion(e.target.value)}>
                                <option value="" disabled>--- Duracion --</option>
                                <option value='15'>15 min</option>
                                <option value='30'>30 min</option>
                                <option value='45'>45 min</option>
                                <option value='60'>1 h</option>
                                <option value='90'>1,5 h</option>
                                <option value='120'>2 h</option>
                                <option value='150'>2,5 h</option>
                                <option value='180'>3 h</option>
                                <option value='210'>3,5 h</option>
                                <option value='240'>4 h</option>
                                <option value='300'>5 h</option>
                                <option value='360'>6 h</option>
                                <option value='420'>7 h</option>
                                <option value='480'>8 h</option>
                            </select>
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="profile-apellidos" type="text" value={lugar} onChange={e => { setLugar(e.target.value) }} />
                            <label className='input-label' htmlFor='profile-apellidos'>Lugar</label>
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="profile-apellidos" type="text" value={link} onChange={e => { setLink(e.target.value) }} />
                            <label className='input-label' htmlFor='profile-apellidos'>Link</label>
                        </div>

                        <div>
                            <textarea id="w3review" name="w3review" rows="6" cols="50" placeholder="Descripcion del evento" value={descripcion} onChange={e => { setDescripcion(e.target.value) }} />
                        </div>


                        <div>
                            <div className='input-container'>
                                <select id="institucion" value={selectedParticipant} onChange={e => setSelectedParticipant(e.target.value)}>
                                    <option value="" disabled>---Selecciona Participantes---</option>
                                    {
                                        adminList.map(d =>
                                            <option key={d.key} value={d.key}>{d.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            {participantes.map(d =>
                                <div className="event-create-participant-chip" key={d.key}>
                                    <img className='event-create-avatar' src={d.photoURL}></img>
                                    <p>{d.name}</p>
                                    <button className="icon-button" onClick={() => removeFromArray(d.key)}><DeleteRegular fontSize={24} /></button>
                                </div>
                            )}

                        </div>

                    </form>

                    <div className="dialog-buttons-container">
                        <button className="text-button" onClick={() => window.history.back()}>Atras</button>
                        <button className="filled-button" type="submit" form="event-create-form">Crear evento</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
