import { getFirestore, collection, setDoc, onSnapshot, query, where, orderBy, limit, startAfter, getDoc, doc, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DeleteRegular } from "@fluentui/react-icons";

import './Promotores.css';

const db = getFirestore();

export default function Promotores() {
    const [promotoresList, setPromotoresList] = useState([]);

    const [idValue, setIdValue] = useState('');
    const [nombreValue, setNombreValue] = useState('');

    useEffect(() => {
        async function fetchData() {
            const q = query(collection(db, "promotores"));
            onSnapshot(q, (querySnapshot) => {
                var array = [];
                querySnapshot.forEach((doc) => {
                    array.push({ id: doc.id, nombre: doc.data().nombre })
                });
                setPromotoresList(array);
            });
        }
        fetchData();
    }, []);

    const add = async e => {
        e.preventDefault();
        let text = `Seguro que desea agregar al promotor ${idValue} ?`;
        if (window.confirm(text) == true) {
            try {
                await setDoc(doc(db, "promotores", idValue), {
                    nombre: nombreValue
                });
                setIdValue('');
                setNombreValue('');
            } catch (error) {
                console.log('Hubo un error en la creacion');
            }
        } else {
            console.log("usuario ha cancelado");
        }
    }

    const remove = async id => {
        console.log(id);
        let text = `Seguro que desea eliminar al promotor ${id} ?`;
        if (window.confirm(text) == true) {
            try {
                await deleteDoc(doc(db, 'promotores', id));
            } catch (error) {
                console.log('Hubo un error al eliminar');
                console.log(error);
            }
        } else {
            console.log("usuario ha cancelado");
        }
    }

    return (
        <div className="page">
            <h1>Promotores</h1>

            <p style={{ margin: "1rem 0" }}>A continuación puedes agregar o eliminar los promotores de la ANEP.</p>

            <form onSubmit={add} className="promotores-form">
                <div className='input-container'>
                    <input className='input-normal' id="promotores-id" type="text" value={idValue} onChange={e => setIdValue(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='promotores-id'>Id</label>
                </div>
                <div className='input-container'>
                    <input className='input-normal' id="promotores-name" type="text" value={nombreValue} onChange={e => setNombreValue(e.target.value)} />
                    <label className='input-label' htmlFor='promotores-name'>Nombre del promotor</label>
                </div>
                <div>
                    <button type='submit' className="filled-button">Agregar</button>
                </div>

            </form>

            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>

                {
                    promotoresList.map(data =>
                        <tbody key={data.id}>
                            <tr>
                                <td>{data.id}</td>
                                <td>{data.nombre}</td>
                                <td>
                                    <td>
                                        <button className='filled-tonal-button' onClick={() => { remove(data.id) }}><DeleteRegular fontSize={24} />Borrar</button>
                                    </td>
                                </td>
                            </tr>
                        </tbody>
                    )
                }

            </table>

        </div>
    );
}