import React, { useState, useEffect, useContext } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore, Timestamp, updateDoc, onSnapshot, getDocs, query, collection, arrayUnion, arrayRemove, where, setDoc } from "firebase/firestore";
import { ShareRegular, ThumbLikeRegular, ArrowUploadRegular, DeleteRegular, SaveRegular, ClockRegular, CheckRegular, EyeRegular, AddRegular, PersonAddRegular } from "@fluentui/react-icons";
import './Profile.css';
import { async } from '@firebase/util';
import { UserContext } from '../UserContext';
import gDriveIcon from '../assets/google-drive.png';
import axios from 'axios';

const db = getFirestore();

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Perfil() {
    const { userLevel } = useContext(UserContext);

    const [listaInstituciones, setListaInstituciones] = useState([]);
    const [listaDependencias, setListaDependencias] = useState([]);
    const [listaDepartamentos, setListaDepartamentos] = useState([]);

    const [initialObj, setInitialObj] = useState({});

    const [id, setId] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [genero, setGenero] = useState('');
    const [email, setEmail] = useState('');
    const [provincia, setProvincia] = useState('');
    const [canton, setCanton] = useState('');
    const [distrito, setDistrito] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [dependencia, setDependencia] = useState('');
    const [puesto, setPuesto] = useState('');
    const [salario, setSalario] = useState('');
    const [sector, setSector] = useState('');
    const [plaza, setPlaza] = useState('');
    const [poliza, setPoliza] = useState('');
    const [promotor, setPromotor] = useState('');
    const [telefono, setTelefono] = useState('');
    const [telOficina, setTelOficina] = useState('');
    const [telCasa, setTelCasa] = useState('');
    const [nacimiento, setNacimiento] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [referencia, setReferencia] = useState('');
    const [ingreso, setIngreso] = useState('');
    const [listaPromotores, setListaPromotores] = useState([]);
    const [nacionalidad, setNacionalidad] = useState('');
    const [moroso, setMoroso] = useState(true);
    const [active, setActive] = useState(null);
    const [desafiliacion, setDesafiliacion] = useState('');
    // POLIZAS
    const [polizasList, setPolizasList] = useState([]);
    const [userPolizas, setUserPolizas] = useState([]);
    const [usuarioPolizas, setUsuarioPolizas] = useState([]);
    const [beneficiariosList, setBeneficiariosList] = useState([]);

    const [inicioPoliza, setInicioPoliza] = useState('');
    const [finPoliza, setFinPoliza] = useState('');

    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    let que = useQuery();
    const ced = que.get("id");

    const goToHistory = () => {
        navigate({ pathname: '/profile/history', search: '?id=' + ced });
    }

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "instituciones", "lista");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data().instituciones);
                setListaInstituciones(docSnap.data().instituciones.sort());
                setListaDependencias(docSnap.data().dependencias.sort());
                setListaDepartamentos(docSnap.data().departamentos.sort());
            } else {
                console.log("No such document!");
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "promotores"));
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({ id: doc.id, nombre: doc.data().nombre })
            });
            setListaPromotores(array);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            onSnapshot(doc(db, "usuarios", ced), (doc) => {

                var data = doc.data();
                setActive(data.active);

                var birth = data.nacimiento && new Date(data.nacimiento.seconds * 1000);
                var parsedBirth = data.nacimiento && birth.getFullYear() + '-' + ('0' + (birth.getMonth() + 1)).slice(-2) + '-' + ('0' + birth.getDate()).slice(-2);
                var ing = data.ingreso && new Date(data.ingreso.seconds * 1000);
                var parsedIng = data.ingreso && ing.getFullYear() + '-' + ('0' + (ing.getMonth() + 1)).slice(-2) + '-' + ('0' + ing.getDate()).slice(-2);
                var out = data.desafiliacion && new Date(data.desafiliacion.seconds * 1000);
                var parsedOut = data.desafiliacion && out.getFullYear() + '-' + ('0' + (out.getMonth() + 1)).slice(-2) + '-' + ('0' + out.getDate()).slice(-2);

                if (data.polizas) { setUserPolizas(data.polizas) };
                if (data.beneficiarios) { setBeneficiariosList(data.beneficiarios); }

                setInitialObj({
                    nombre: data.nombre,
                    apellidos: data.apellidos,
                    email: data.email,
                    genero: data.genero,
                    contacto2: data.contacto2,
                    contacto3: data.contacto3,
                    contacto1: data.contacto1,
                    nacionalidad: data.nacionalidad,
                    provincia: data.provincia ? data.provincia : '',
                    canton: data.canton ? data.canton : '',
                    distrito: data.distrito ? data.distrito : '',
                    institucion: data.institucion,
                    dependencia: data.dependencia,
                    departamento: data.departamento,
                    puesto: data.puesto,
                    sector: data.sector,
                    plaza: data.plaza,
                    promotor: data.promotor,
                    nacimiento: data.nacimiento ? parsedBirth : '',
                    ingreso: data.ingreso ? parsedIng : '',
                    desafiliacion: data.desafiliacion ? parsedOut : '',
                    referencia: data.referencia,
                    promotor: data.promotor,
                    observaciones: data.observaciones
                });
            });
        }
        fetchData();
    }, [])

    useEffect(() => {

        async function fetchData() {
            const docRef = doc(db, "usuarios", ced);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                var data = docSnap.data();
                var expireDate = new Date(data.expira.seconds * 1000);
                var nowDate = Date.now();
                var isActive = expireDate.getTime() > nowDate;

                // DATE NOW DESAFILIACION
                var today = new Date();
                var nowToDateInputValue = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
                var birth = data.nacimiento && new Date(data.nacimiento.seconds * 1000);
                var parsedBirth = data.nacimiento && birth.getFullYear() + '-' + ('0' + (birth.getMonth() + 1)).slice(-2) + '-' + ('0' + birth.getDate()).slice(-2);

                var ing = data.ingreso && new Date(data.ingreso.seconds * 1000);
                var parsedIng = data.ingreso && ing.getFullYear() + '-' + ('0' + (ing.getMonth() + 1)).slice(-2) + '-' + ('0' + ing.getDate()).slice(-2);

                var out = data.desafiliacion && new Date(data.desafiliacion.seconds * 1000);
                var parsedOut = data.desafiliacion && out.getFullYear() + '-' + ('0' + (out.getMonth() + 1)).slice(-2) + '-' + ('0' + out.getDate()).slice(-2);

                setMoroso(isActive);
                setId(data.id);
                setNombre(data.nombre);
                setApellidos(data.apellidos);
                setGenero(data.genero);
                setEmail(data.email);
                setProvincia((typeof (data.provincia) === 'undefined') ? '' : data.provincia);
                setCanton((typeof (data.canton) === 'undefined') ? '' : data.canton);
                setDistrito((typeof (data.distrito) === 'undefined') ? '' : data.distrito);
                setInstitucion(data.institucion);
                setDepartamento(data.departamento);
                setDependencia(data.dependencia);
                setPuesto(data.puesto);
                setSalario(data.salario);
                setSector(data.sector);
                setPlaza(data.plaza);
                setPoliza(data.poliza);
                setPromotor(data.promotor);
                setTelefono(data.contacto2);
                setTelOficina(data.contacto1);
                setTelCasa(data.contacto3);
                setObservaciones(data.observaciones);
                setActive(data.active);
                setNacimiento(data.nacimiento ? parsedBirth : '');
                setIngreso(data.ingreso ? parsedIng : '');
                setDesafiliacion(data.desafiliacion ? parsedOut : '');
                setNacionalidad(data.nacionalidad);
                setReferencia(data.referencia);

            } else {
                console.log("No such document!");
                alert("No se encontro ningun asociado con esta cedula.")
            }

        }

        fetchData();

    }, []);

    const editTextField = async (field, value) => {
        setErrorMsg('');
        try {
            const userRef = doc(db, "usuarios", id.toString());

            var obj = {};
            obj[field] = value;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            setErrorMsg('Hubo un error al actualizar este dato');
        }
    }

    const editDateField = async (field, value) => {
        setErrorMsg('');
        try {
            const userRef = doc(db, "usuarios", id.toString());

            var obj = {};

            var d = new Date(value);
            d.setDate(d.getDate() + 1);
            obj[field] = Timestamp.fromDate(d);;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            setErrorMsg('Hubo un error al actualizar este dato');
        }
    }

    const toggleActive = async () => {
        let text = `¿Seguro que deseas cambair el estado del afiliado?`;
        if (window.confirm(text) == true) {
            try {
                var today = new Date();
                var nowToDateInputValue = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
                setDesafiliacion(nowToDateInputValue);

                var state = (active) ? false : true;
                const userRef = doc(db, "usuarios", ced);
                await updateDoc(userRef, { active: state });
                console.log('Creado con exito');
            } catch (error) {
                console.log(error);
                setErrorMsg('Hubo un error al actualizar este dato');
            }
        } else {

        }
    }

    useEffect(() => {
        async function fetchData() {
            const q = query(collection(db, "polizas"), where("active", "==", true));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach(async (poliza) => {
                array.push({
                    key: poliza.id,
                    id: poliza.data().id,
                    monto: poliza.data().monto,
                    active: poliza.data().active,
                    deduccion: poliza.data().deduccion
                });
            });
            setPolizasList(array);
        }
        fetchData();
    }, []);

    // useEffect(() => {
    //     var array = [];
    //     async function fetchData() {
    //         if (polizasList.length > 0) {
    //             console.log(polizasList);

    //             polizasList.forEach(async element => {
    //                 const docRef = doc(db, "polizas/" + element.key + "/usuarios", ced);
    //                 const docSnap = await getDoc(docRef);

    //                 if (docSnap.exists()) {
    //                     if (docSnap.data().active) {
    //                         var obj = {
    //                             poliza: element.key,
    //                             active: docSnap.data().active
    //                         }
    //                         setUsuarioPolizas([...usuarioPolizas, obj]);
    //                         array.push(obj);
    //                         console.log("Poliza activa", obj);
    //                     }
    //                 } else {
    //                     console.log("No such document!");
    //                 }
    //             });

    //         }
    //     }
    //     fetchData();

    // }, [polizasList]);

    // useEffect(() => {
    //     var poli = 'TO7LtGbsjm4XGgLuJ0Af';
    //     const docRef = doc(db, "polizas/" + poli + "/usuarios", ced);

    //     onSnapshot(docRef, (doc) => {
    //         if (doc.data().active) {
    //             var obj = {
    //                 poliza: poli,
    //                 active: doc.data().active
    //             }
    //             setUsuarioPolizas([...usuarioPolizas, obj]);
    //         } else {
    //             setUsuarioPolizas(
    //                 usuarioPolizas.filter(a => a.poliza !== usuarioPolizas.poliza)
    //             );
    //         }

    //         console.log("Current data: ", doc.data());
    //     });
    // }, []);


    useEffect(() => {
        if (polizasList.length > 0) {

            polizasList.forEach(element => {
                // console.log(element.key);
                const docRef = doc(db, "polizas/" + element.key + "/usuarios", ced);

                onSnapshot(docRef, (doc) => {
                    // console.log(doc.data());
                    setUsuarioPolizas([...usuarioPolizas, { poliza: element.key, active: true }]);

                    // if (doc.data().active) {
                    //     var obj = {
                    //         poliza: element.key,
                    //         active: doc.data().active
                    //     }
                    //     setUsuarioPolizas([...usuarioPolizas, obj]);
                    // } else {
                    //     setUsuarioPolizas(
                    //         usuarioPolizas.filter(a => a.poliza !== usuarioPolizas.poliza)
                    //     );
                    // }
                    // console.log("Current data: ", doc.data());
                });

            });
        }
    }, [polizasList]);

    const validatePoliza = (poliza) => {
        function checkAge(age) {
            return age.id === poliza;
        }

        const result = userPolizas.find(checkAge);

        if (result) {
            if (result.active) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    }

    const togglePoliza = async (poliza) => {

        // var exists = usuarioPolizas.includes(poliza);
        var exists = usuarioPolizas.find(p => p.poliza === poliza)

        const docRef = doc(db, "polizas/" + poliza + "/usuarios", ced);
        if (exists) {
            setDoc(docRef, {
                afiliado: ced,
                active: false,
                fin: Timestamp.now()
            }, { merge: true });
            // console.log('123');

        } else {
            setDoc(docRef, {
                afiliado: ced,
                active: true,
                inicio: Timestamp.now()
            }, { merge: true });
            // console.log('124');
        }
    }

    return (
        <div className='profile page'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='profile-header'>
                    <h2>Perfil ID: <code>{id}</code></h2>
                    {
                        moroso ?
                            <CheckRegular fontSize={24} /> :
                            <ClockRegular fontSize={24} />
                    }

                    {
                        moroso ? <h5>Moroso: No</h5> : <h5>Moroso: Si</h5>
                    }
                </div>

                <button className='filled-tonal-button' onClick={goToHistory}>Ver historial de pagos</button>
            </div>

            <div className='perfil-afiliacion-header'>
                <h1>Afiliacion</h1>
                <div>
                    {active ? <h5 style={{ marginBottom: '0.25rem' }}>Activo</h5> : <h5 style={{ marginBottom: '0.25rem' }}>Inactivo</h5>}

                    <button className={(active) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => { toggleActive() }}>
                        <div className={(active) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                    </button>
                </div>
            </div>



            <div className='profile-form-persona'>



                <div className='input-container'>
                    <input className='input-normal' id="profile-celular" type="date" value={ingreso} onChange={e => { setIngreso(e.target.value) }} />
                    <label className='input-label' htmlFor='profile-celular'>Fecha de Afiliacion</label>
                    {
                        (initialObj.ingreso !== ingreso) &&
                        <button className='input-save-button' onClick={() => { editDateField('ingreso', ingreso) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                {
                    (active === false) ?
                        <div className='input-container'>
                            <input className='input-normal' id="profile-desafiliacion" type="date" value={desafiliacion} onChange={e => { setDesafiliacion(e.target.value) }} />
                            <label className='input-label' htmlFor='profile-desafiliacion'>Fecha de desafiliacion</label>
                            {
                                (initialObj.desafiliacion !== desafiliacion) &&
                                <button className='input-save-button' onClick={() => { editDateField('desafiliacion', desafiliacion) }}>
                                    <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                                </button>
                            }
                        </div> :
                        null
                }
            </div>



            <h4>Persona</h4>

            <div className='profile-form-persona'>

                <EditableTextField label='Nombre' field='nombre' value={nombre} onChange={(newValue) => { setNombre(newValue) }} initialObj={initialObj} id={id} />

                <EditableTextField label='Apellidos' field='apellidos' value={apellidos} onChange={(newValue) => { setApellidos(newValue) }} initialObj={initialObj} id={id} />

                <div className='input-container'>
                    <select id="genero" value={genero} onChange={e => setGenero(e.target.value)}>
                        <option value="" disabled>---Selecciona un Genero---</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                    </select>
                    {
                        (initialObj.genero !== genero) &&
                        <button className='input-save-button' onClick={() => { editTextField('genero', genero) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                <EditableTextField label='email' field='email' value={email} onChange={(newValue) => { setEmail(newValue) }} initialObj={initialObj} id={id} />

                <EditableTextField label='Celular' field='contacto2' value={telefono} onChange={(newValue) => { setTelefono(newValue) }} initialObj={initialObj} id={id} />

                <EditableTextField label='Tel. Casa' field='contacto3' value={telCasa} onChange={(newValue) => { setTelCasa(newValue) }} initialObj={initialObj} id={id} />

                <EditableTextField label='Tel. Oficina' field='contacto1' value={telOficina} onChange={(newValue) => { setTelOficina(newValue) }} initialObj={initialObj} id={id} />

                <EditableTextField label='Nacionalidad' field='nacionalidad' value={nacionalidad} onChange={(newValue) => { setNacionalidad(newValue) }} initialObj={initialObj} id={id} />

                <div className='input-container'>
                    <input className='input-normal' id="profile-celular" type="date" value={nacimiento} onChange={e => { setNacimiento(e.target.value) }} />
                    <label className='input-label' htmlFor='profile-celular'>Fecha de Nacimiento</label>
                    {
                        (initialObj.nacimiento !== nacimiento) &&
                        <button className='input-save-button' onClick={() => { editDateField('nacimiento', nacimiento) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

            </div>

            <h4>Domicilio electoral</h4>

            <div className='profile-form-persona'>

                <div className='input-container'>
                    <select id="provincia" value={provincia} onChange={e => setProvincia(e.target.value)}>
                        <option value="" disabled>---Selecciona una Provincia---</option>
                        <option value="PUNTARENAS">PUNTARENAS</option>
                        <option value="SAN JOSE">SAN JOSE</option>
                        <option value="HEREDIA">HEREDIA</option>
                        <option value="LIMON">LIMON</option>
                        <option value="CARTAGO">CARTAGO</option>
                        <option value="GUANACASTE">GUANACASTE</option>
                        <option value="ALAJUELA">ALAJUELA</option>
                    </select>
                    {
                        (initialObj.provincia !== provincia) &&
                        <button className='input-save-button' onClick={() => { editTextField('provincia', provincia) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                <EditableTextField label='Canton' field='canton' value={canton} onChange={(newValue) => { setCanton(newValue) }} initialObj={initialObj} id={id} />

                <EditableTextField label='Distrito' field='distrito' value={distrito} onChange={(newValue) => { setDistrito(newValue) }} initialObj={initialObj} id={id} />

            </div>

            <h4>Trabajo</h4>

            <div className='profile-form-persona'>

                <div className='input-container'>
                    <label className='select-label' htmlFor='institucion'><small>Institucion</small></label>
                    <select id="institucion" value={institucion} onChange={e => setInstitucion(e.target.value)}>
                        <option value="" disabled>---Selecciona una Institucion---</option>
                        {listaInstituciones.map(data =>
                            <option key={data} value={data}>{data}</option>
                        )}
                    </select>
                    {
                        (initialObj.institucion !== institucion) &&
                        <button className='input-save-button' onClick={() => { editTextField('institucion', institucion) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                <div className='input-container'>
                    <label className='select-label' htmlFor='dependencia'><small>Dependencia: {dependencia}</small></label>
                    <select id="dependencia" value={dependencia} onChange={e => setDependencia(e.target.value)}>
                        <option value="" disabled>---Selecciona una Dependencia---</option>
                        {listaDependencias.map(data =>
                            <option key={data} value={data}>{data}</option>
                        )}
                    </select>
                    {
                        (initialObj.dependencia !== dependencia) &&
                        <button className='input-save-button' onClick={() => { editTextField('dependencia', dependencia) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                <div className='input-container'>
                    <label className='select-label' htmlFor='departamento'><small>Departamento</small></label>
                    <select id="departamento" value={departamento} onChange={e => setDepartamento(e.target.value)}>
                        <option value="" disabled>---Selecciona un Departamento---</option>
                        {listaDepartamentos.map(data =>
                            <option key={data} value={data}>{data}</option>
                        )}
                    </select>
                    {
                        (initialObj.departamento !== departamento) &&
                        <button className='input-save-button' onClick={() => { editTextField('departamento', departamento) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                <EditableTextField label='Puesto' field='puesto' value={puesto} onChange={(newValue) => { setPuesto(newValue) }} initialObj={initialObj} id={id} />

                <div className='input-container'>
                    <select id="sector" value={sector} onChange={e => setSector(e.target.value)}>
                        <option value="">--- Selecciona un SECTOR ---</option>
                        <option value="PRIVADO">Privado</option>
                        <option value="PUBLICO">Publico</option>
                        <option value="PENSIONADO">Pensionado</option>
                        <option value="DIRECTO">Pago directo</option>
                    </select>
                    {
                        (initialObj.sector !== sector) &&
                        <button className='input-save-button' onClick={() => { editTextField('sector', sector) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

                <div className='input-container'>
                    <select id="plaza" value={plaza} onChange={e => setPlaza(e.target.value)}>
                        <option value="">--- Selecciona un TIPO DE PLAZA ---</option>
                        <option value="INTERINO">Interino</option>
                        <option value="PROPIEDAD">Propiedad</option>
                    </select>
                    {
                        (initialObj.plaza !== plaza) &&
                        <button className='input-save-button' onClick={() => { editTextField('plaza', plaza) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>

            </div>
            <h4>Adicional</h4>

            <div className='profile-form-persona'>
                <EditableTextField label='Numero de referencia' field='referencia' value={referencia} onChange={(newValue) => { setReferencia(newValue) }} initialObj={initialObj} id={id} />

                <div className='input-container'>
                    <select id="promotores" value={promotor} onChange={e => setPromotor(e.target.value)}>
                        <option value="">---Sin Promotor---</option>
                        {listaPromotores.map(data =>
                            <optgroup key={data.id} label={data.id}>
                                <option value={data.id}>{data.nombre}</option>
                            </optgroup>
                        )}
                    </select>
                    {
                        (initialObj.promotor !== promotor) &&
                        <button className='input-save-button' onClick={() => { editTextField('promotor', promotor) }}>
                            <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                        </button>
                    }
                </div>
            </div>

            {
                (userLevel === 1) &&
                <div className='create-form'>

                    <h4>Observaciones</h4>

                    <div className='input-container'>
                        <textarea id="w3review" style={{ width: '100%' }} name="w3review" rows="6" cols="50" value={observaciones} onChange={e => { setObservaciones(e.target.value) }} />
                        {
                            (initialObj.observaciones !== observaciones) &&
                            <button className='input-save-button' onClick={() => { editTextField('observaciones', observaciones) }}>
                                <span style={{ fontSize: '18px' }} className="material-symbols-rounded">save</span>
                            </button>
                        }
                    </div>

                    <p><small style={{ color: 'var(--red)' }}>{errorMsg}</small></p>

                </div>
            }



            {/* <h2 style={{ marginBottom: '2rem' }}>Poliza</h2>

            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Id Unico</th>
                            <th>Display name</th>
                            <th>Monto de poliza</th>
                            <th>Deduccion</th>
                            <th>Beneficiarios</th>
                            <th>Estado</th>
                        </tr>

                    </thead>
                    {
                        polizasList.map(data =>
                            <tbody key={data.key}>
                                <tr>
                                    <td><code>{data.key}</code></td>
                                    <td>{data.id}</td>
                                    <td>{data.monto}</td>
                                    <td>{data.deduccion}</td>
                                    <td>Ver beneficiarios</td>
                                    <td>
                                        <button className={usuarioPolizas.find(p => p.poliza === data.key) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => { togglePoliza(data.key) }}>
                                            <div className={usuarioPolizas.find(p => p.poliza === data.key) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        )
                    }
                </table>
            </div>



            <h4>Beneficiarios</h4>

            {
                beneficiariosList.map(data =>
                    <div key={data.cedula}>
                        <h5>{data.poliza}</h5>
                        <h1>{data.cedula}</h1>
                        <h3>{data.nombre}</h3>
                    </div>
                )
            } */}

            <PolizasComponent />

            {/* {
                userPolizas.map(data =>
                    <div key={data.id}>
                        <h2>{data.id}</h2>
                        {
                            (
                                data.beneficiarios ?
                                    data.beneficiarios.map(d =>
                                        <div>
                                            <h3>{d.nombre}</h3>
                                            <p>{d.cedula}</p>
                                            <p>{d.parentesco}</p>
                                        </div>
                                    ) :
                                    <p>No se encontraron beneficiarios</p>
                            )

                        }
                    </div>
                )
            } */}

            {/* <h4>Casos de fallecimiento</h4>

            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Id de Caso</th>
                            <th>Numero de Documento</th>
                            <th>Estado</th>
                            <th>Mas informacion</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td><code>TO7LtGbsjm4XGgLuJ0Af</code></td>
                            <td>115912345</td>
                            <td>En proceso</td>
                            <td>
                                <button className='elevated-button'>Ir a caso</button>
                            </td>
                        </tr>
                        <tr>
                            <td><code>TO7LtGbsjm4XGgLuJ0Af</code></td>
                            <td>115912345</td>
                            <td>Finalizado</td>
                            <td>
                                <button className='elevated-button'>Ir a caso</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div> */}



        </div >
    )
}


function EditableTextField({ field, value, onChange, label, initialObj, id }) {

    const editTextField = async (field, value) => {
        try {
            const userRef = doc(db, "usuarios", id.toString());

            var obj = {};
            obj[field] = value;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='input-container'>
            <input className='input-normal' id="profile-celular" type="text" value={value} onChange={e => { onChange(e.target.value.toLocaleUpperCase()) }} />
            <label className='input-label' htmlFor='profile-celular'>{label}</label>
            {
                (initialObj[field] !== value) &&
                <button className='input-save-button' onClick={() => { editTextField(field, value) }}>
                    <SaveRegular fontSize={24} />
                </button>
            }
        </div>
    );
}

let nextId = 0;

function PolizasComponent() {
    const { userLevel } = useContext(UserContext);
    //
    const [initialObj, setInitialObj] = useState({});
    //
    const [polizasList, setPolizasList] = useState([]);
    const [userPolizas, setUserPolizas] = useState([]);
    const [usuarioPolizas, setUsuarioPolizas] = useState([]);
    const [beneficiariosList, setBeneficiariosList] = useState([]);
    // POLIZA INDIVIDUAL
    const [selectedPoliza, setSelectedPoliza] = useState('TO7LtGbsjm4XGgLuJ0Af');
    const [inicio, setInicio] = useState('');
    const [fin, setFin] = useState('');
    const [referencia, setReferencia] = useState('');
    // BENEFICIARIO
    const [cedula, setCedula] = useState('');
    const [fullname, setFullname] = useState('');
    const [parentesco, setParentesco] = useState('');
    const [porcentaje, setPorcentaje] = useState('');
    // NOTAS
    const [notas, setNotas] = useState('');
    // CASOS DE FALLECIMIENTO
    const [casosList, setCasosList] = useState([]);
    // GOOGLE DRIVE FILE ID
    const [driveFileID, setDriveFileID] = useState('');
    const [localeDriveFileID, setLocalDriveFileID] = useState('');

    let que = useQuery();
    const ced = que.get("id");

    useEffect(() => {
        async function fetchData() {
            const q = query(collection(db, "polizas"), where("active", "==", true));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach(async (poliza) => {
                array.push({
                    key: poliza.id,
                    id: poliza.data().id,
                    monto: poliza.data().monto,
                    active: poliza.data().active,
                    deduccion: poliza.data().deduccion
                });
            });
            setPolizasList(array);
        }
        fetchData();
    }, []);

    // useEffect(() => {
    //     async function fetchData() {
    //         if (polizasList.length > 0) {
    //             polizasList.forEach(async element => {
    //                 const docRef = doc(db, "polizas/" + element.key + "/usuarios", ced);
    //                 onSnapshot(docRef, (doc) => {
    //                     if (doc.data().active === true) {
    //                         const arr = [
    //                             {
    //                                 key: element.key,
    //                                 id: element.key,
    //                                 poliza: element.key,
    //                                 active: doc.data().active,
    //                                 inicio: doc.data().inicio.seconds,
    //                                 fin: doc.data().fin.seconds
    //                             }
    //                         ];
    //                         setUsuarioPolizas(prevState => [...prevState, ...arr]);
    //                         console.log('POLIZA ACTIVA ES ' + element.key);
    //                     }
    //                     // if (doc.data().active) {
    //                         const arr = [
    //                             {
    //                                 key: element.key,
    //                                 id: element.key,
    //                                 poliza: element.key,
    //                                 active: doc.data().active,
    //                                 inicio: doc.data().inicio.seconds,
    //                                 fin: doc.data().fin.seconds
    //                             }
    //                         ];
    //                         setUsuarioPolizas(prevState => [...prevState, ...arr]);
    //                         console.log('POLIZA ACTIVA ES ' + element.key);
    //                     // }
    //                 });
    //             });
    //         }
    //     }
    //     fetchData();
    // }, [polizasList]);

    useEffect(() => {
        async function fetchData() {
            if (polizasList.length > 0) {
                polizasList.forEach(async element => {
                    const docRef = doc(db, "polizas/" + element.key + "/usuarios", ced);
                    try {
                        onSnapshot(docRef, (doc) => {
                            console.log(doc.data());

                            // var beneficiariosPoliza = doc.data().beneficiarios ? doc.data().beneficiarios : [];
                            // setBeneficiariosList(beneficiariosPoliza);

                            if (doc.data().active) {
                                const arr = [
                                    {
                                        key: element.key,
                                        id: element.key,
                                        poliza: element.key,
                                        active: doc.data().active,
                                        // inicio: doc.data().inicio.seconds,
                                        // fin: doc.data().fin.seconds
                                    }
                                ];
                                setUsuarioPolizas(prevState => [...prevState, ...arr]);
                                console.log('POLIZA ACTIVA ES ' + element.key);
                            }
                            const arr = [
                                {
                                    key: element.key,
                                    id: element.key,
                                    poliza: element.key,
                                    active: doc.data().active,
                                    // inicio: doc.data().inicio.seconds,
                                    // fin: doc.data().fin.seconds
                                }
                            ];
                            setUsuarioPolizas(prevState => [...prevState, ...arr]);



                            console.log('POLIZA ACTIVA ES ' + element.key);
                        });
                    } catch (error) {
                        console.log('catch')
                        console.log(error);
                    }

                });
            }
        }
        fetchData();
    }, [polizasList]);


    const togglePoliza = async (poliza) => {
        // var exists = usuarioPolizas.find(p => p.poliza === poliza);
        var exists = usuarioPolizas.filter(s => s.active === true).find(p => p.poliza === poliza);

        const docRef = doc(db, "polizas/" + poliza + "/usuarios", ced);
        if (exists) {
            setDoc(docRef, {
                afiliado: ced,
                active: false,
                fin: Timestamp.now()
            }, { merge: true });
            console.log('TO FALSE');
            setUsuarioPolizas(usuarioPolizas.filter(p => p.poliza !== poliza));
        } else {
            setDoc(docRef, {
                afiliado: ced,
                active: true,
                inicio: Timestamp.now()
            }, { merge: true });
            console.log('TO TRUE OR CREATE');

        }
    }

    useEffect(() => {
        setBeneficiariosList([]);
        async function fetchData() {
            const docRef = doc(db, "polizas/" + selectedPoliza + "/usuarios", ced);
            try {

                onSnapshot(docRef, (doc) => {
                    var data = doc.data();
                    var inicioPoliza = data.inicio ? new Date(data.inicio.seconds * 1000).toLocaleString() : "No definido";
                    var finPoliza = data.fin ? new Date(data.fin.seconds * 1000).toLocaleString() : "No definido";
                    var beneficiariosPoliza = data.beneficiarios ? data.beneficiarios : [];

                    setDriveFileID(data.driveFileID);
                    setLocalDriveFileID(data.driveFileID);
                    setNotas(data.notas);

                    setBeneficiariosList(beneficiariosPoliza);
                    setInicio(inicioPoliza);
                    setFin(finPoliza);
                    // console.log(data.driveFileID)

                });

            } catch (error) {
                console.log(error);
            }

        }
        fetchData();
    }, [selectedPoliza])

    useEffect(() => {
        async function fetchData() {
            try {
                const docRef = doc(db, "polizas/" + selectedPoliza + "/usuarios", ced);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    var data = docSnap.data();
                    console.log(data);
                    setReferencia(data.referencia);
                    var inicioPoliza = data.inicio ? new Date(data.inicio.seconds * 1000).toLocaleString() : "No definido";
                    var finPoliza = data.fin ? new Date(data.fin.seconds * 1000).toLocaleString() : "No definido";
                    setInicio(inicioPoliza);
                    setFin(finPoliza);
                    setDriveFileID(data.driveFileID);
                    setLocalDriveFileID(data.driveFileID);
                } else {
                    setDriveFileID("");
                    setLocalDriveFileID("");
                    console.log("No such document!");
                    setReferencia("No definido");
                    setInicio("No definido");
                    setFin("No definido");
                    setNotas("");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [selectedPoliza])

    const selectPoliza = async poliza => {
        setSelectedPoliza(poliza);
    }

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "polizas/" + selectedPoliza + "/usuarios", ced);

            onSnapshot(docRef, (doc) => {
                var data = doc.data();
                setInitialObj({
                    notas: data.notas,
                });
                console.log("Current data: ", doc.data());
            });
        }
        fetchData();
    }, [selectedPoliza])

    const requestCedula = async e => {
        var value = e.target.value;
        setCedula(e.target.value);
        console.log(value);
        if (value.length === 9) {
            //var url = 'http://localhost:8080/';
            var url = 'https://padron.anep.app/';
            axios.get(url, {
                params: {
                    cedula: value
                }
            })
                .then(function (response) {
                    var data = response.data;
                    if (!data) {
                        // setErrorMsg('No se encontraron resultados');
                        console.log('No se encontraron resultados');
                    }
                    setFullname(data.nombre.replace('\ufffd', 'Ñ') + ' ' + data.apellido1.replace('\ufffd', 'Ñ') + ' ' + data.apellido2.replace('\ufffd', 'Ñ'));
                    console.log(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    }

    const addBeneficiario = async e => {
        e.preventDefault();
        try {
            const docRef = doc(db, "polizas/" + selectedPoliza + "/usuarios", ced);
            var obj = {
                id: cedula,
                nombre: fullname,
                parentesco: parentesco,
                porcentaje: porcentaje
            }
            await updateDoc(docRef, {
                beneficiarios: arrayUnion(obj)
            });
            setCedula('');
            setFullname('');
            setParentesco('');
            setPorcentaje('');
        } catch (error) {
            console.log(error);
        }
    }

    const deleteBeneficiario = async b => {
        const docRef = doc(db, "polizas/" + selectedPoliza + "/usuarios", ced);
        await updateDoc(docRef, {
            beneficiarios: arrayRemove(b)
        });
    }

    useEffect(() => {
        async function fetchData() {
            const q = query(collection(db, "casos"), where("linked", "==", ced));
            var array = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                var data = doc.data();
                var created = new Date(data.created.seconds * 1000).toLocaleString();

                array.push({
                    key: doc.id,
                    estado: data.estado,
                    created: created
                });
            });
            setCasosList(array);
        }
        fetchData();
    }, []);

    const editTextField = async (field, value) => {
        try {
            const docRef = doc(db, "polizas/" + selectedPoliza + "/usuarios", ced);

            var obj = {};
            obj[field] = value;

            await updateDoc(docRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <h1 style={{ margin: '2rem 0' }}>Polizas</h1>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Ver</th>
                            <th>Id Unico</th>
                            <th>Display name</th>
                            <th>Monto de poliza</th>
                            <th>Deduccion</th>
                            <th>Estado</th>
                        </tr>

                    </thead>
                    {
                        polizasList.map(data =>
                            <tbody key={data.key}>
                                <tr>
                                    <td><button className='icon-button' onClick={() => selectPoliza(data.key)}><EyeRegular fontSize={24} /></button></td>
                                    <td><code>{data.key}</code></td>
                                    <td>{data.id}</td>
                                    <td>{data.monto}</td>
                                    <td>{data.deduccion}</td>
                                    <td>
                                        <button className={usuarioPolizas.filter(s => s.active === true).find(p => p.poliza === data.key) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => { togglePoliza(data.key) }}>
                                            <div className={usuarioPolizas.filter(s => s.active === true).find(p => p.poliza === data.key) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        )
                    }
                </table>
            </div>

            <div>
                <h4>Poliza {selectedPoliza}</h4>
                <h5>Referencia de poliza: {referencia}</h5>
            </div>



            <div style={{ overflow: 'auto', margin: '1rem 0' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Inicio</th>
                            <th>Fin</th>
                            {/* <th>Estado</th> */}
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>{inicio}</td>
                            <td>{fin}</td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </table>
            </div>



            <h4>Beneficiarios</h4>


            {
                (userLevel === 0 || userLevel === 2) &&
                <>
                    <div className='perfil-beneficiarios-grid'>
                        <form onSubmit={addBeneficiario}>
                            <fieldset>
                                <legend>Agregar un Beneficiario:</legend>
                                <div className='input-container'>
                                    <input className='input-normal' id="profile-cedula-beneficiario" type="text" value={cedula} onChange={e => requestCedula(e)} />
                                    <label className='input-label' htmlFor='profile-cedula-beneficiario'>Cedula del beneficiario</label>
                                </div>

                                <div className='input-container'>
                                    <input className='input-normal' id="profile-nombre-beneficiario" type="text" value={fullname} onChange={e => { setFullname(e.target.value.toUpperCase()) }} />
                                    <label className='input-label' htmlFor='profile-nombre-beneficiario'>Nombre completo del beneficiario</label>
                                </div>

                                <div className='input-container'>
                                    <input className='input-normal' id="profile-parentesco-beneficiario" type="text" value={parentesco} onChange={e => { setParentesco(e.target.value.toUpperCase()) }} />
                                    <label className='input-label' htmlFor='profile-parentesco-beneficiario'>Parentesco del beneficiario</label>
                                </div>

                                <div className='input-container'>
                                    <input className='input-normal' id="profile-porcentaje-beneficiario" type="text" value={porcentaje} onChange={e => { setPorcentaje(e.target.value) }} />
                                    <label className='input-label' htmlFor='profile-porcentaje-beneficiario'>Porcentaje</label>
                                </div>

                                <button type='submit' className='filled-tonal-button'><PersonAddRegular fontSize={24} />Agregar beneficiario</button>
                            </fieldset>
                        </form>



                        <div className='perfil-beneficiarios-list'>
                            {
                                beneficiariosList.length > 0 ?
                                    beneficiariosList.map(data =>
                                        <div className='perfil-beneficiario-card'>
                                            <h4 style={{ margin: '0' }}>{data.nombre}</h4>
                                            <p>Cedula: {data.id}</p>
                                            <p>Parentesco: {data.parentesco}</p>
                                            <p>Porcentaje: {data.porcentaje}</p>
                                            <button type='submit' className='icon-button beneficiario-delete-button' onClick={() => deleteBeneficiario(data)}><DeleteRegular fontSize={24} /></button>
                                        </div>
                                    ) :
                                    <h4>No se encontraron beneficiarios.</h4>
                            }
                        </div>
                    </div>

                    {
                        driveFileID &&
                        <iframe src={"https://drive.google.com/file/d/" + driveFileID + "/preview"} style={{ border: 'none' }} width="100%" height="480" allow="autoplay"></iframe>
                    }

                    <div className='input-container' style={{ marginTop: '1rem', maxWidth: '45ch' }}>
                        <input className='input-normal' id="profile-celular" type="text" value={localeDriveFileID} onChange={e => setLocalDriveFileID(e.target.value)} />
                        <label className='input-label' htmlFor='profile-celular'>Google Drive File ID</label>
                        <img src={gDriveIcon} style={{ position: 'absolute', right: '-2rem', top: '0.75rem', height: '24px' }} />

                        {
                            localeDriveFileID !== driveFileID &&
                            <button className='input-save-button' onClick={() => { editTextField('driveFileID', localeDriveFileID) }}>
                                <SaveRegular fontSize={24} />
                            </button>
                        }
                    </div>

                    <p style={{ marginTop: '0.5rem' }}><b>Ej: </b>https://drive.google.com/file/d/<b>0B-eis5c3lHOhY2ZzOGZlaEhfR</b>/preview</p>
                </>

            }


            <h4>Notas</h4>

            <div className='input-container'>
                <textarea id="poliza-notas" style={{ width: '100%' }} name="poliza-notas" rows="6" cols="50" value={notas} onChange={e => { setNotas(e.target.value) }} />
                {
                    (initialObj.notas !== notas) &&
                    <button className='input-save-button' onClick={() => { editTextField('notas', notas) }}>
                        <span className="material-symbols-rounded">save</span>
                    </button>
                }
            </div>


            <h4>Casos de fallecimiento</h4>

            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Id de Caso</th>
                            <th>Fecha de creacion</th>
                            <th>Estado</th>
                            <th>Mas informacion</th>
                        </tr>

                    </thead>
                    <tbody>
                        {
                            casosList.map(data =>
                                <tr key={data.key}>
                                    <td><code>{data.key}</code></td>
                                    <td>{data.created}</td>
                                    <td>{data.estado}</td>
                                    <td>
                                        <Link to={{ pathname: '/caso', search: '?id=' + data.key }}><button className='elevated-button'>Ir a caso</button></Link>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>



        </div>
    );
}