import React, { useState, useEffect, useContext } from "react";
import { ArrowNextRegular, ArrowPreviousRegular, CalendarAddRegular, ChevronLeftRegular, ChevronRightRegular, CircleFilled, ClockRegular, DeleteRegular, PersonClock16Regular, PersonClockRegular, SearchRegular } from '@fluentui/react-icons';
import { collection, query, where, onSnapshot, getFirestore, Timestamp, doc, getDoc, FieldPath, getDocs, documentId, addDoc, orderBy } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import './Planificador.css';
// CONTEXT
import { UserContext } from './UserContext';
const db = getFirestore();

export default function Planificador() {
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    const [selectedDate, setSelectedDate] = useState(now.getFullYear() + "-" + month + "-" + day);

    const [listaPersonas, setListaPersonas] = useState([]);
    const [selectedCreador, setSelectedCreador] = useState('');
    const [selectedCreadorObject, setSelectedCreadorObject] = useState({});
    const defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';


    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "admins"));
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({
                    id: doc.id,
                    name: doc.data().name,
                    photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto,
                })
            });
            setListaPersonas(array);
        }
        fetchData();
    }, []);


    const incrementDate5 = () => {
        let currentDateObject = new Date(selectedDate);
        currentDateObject.setDate(currentDateObject.getDate() + 5);
        setSelectedDate(currentDateObject.toISOString().slice(0, 10));
    }

    const decrementDate5 = () => {
        let currentDateObject = new Date(selectedDate);
        currentDateObject.setDate(currentDateObject.getDate() - 5);
        setSelectedDate(currentDateObject.toISOString().slice(0, 10));
    }

    return (
        <div className='page'>
            <a href='#event-create-dialog'>
                <button className='extended-fab event-fab' style={{ zIndex: '1000' }}>
                    <CalendarAddRegular fontSize={24} />
                    Crear evento nuevo
                </button>
            </a>


            <div className='events-header'>
                <h1>Planificador</h1>

                <div className='events-date-selector'>
                    <button className='icon-button' onClick={decrementDate5}><ArrowPreviousRegular fontSize={24} /></button>
                    <div className='input-container'>
                        <input className='input-normal' id="event-date" type="date" value={selectedDate} onChange={e => { setSelectedDate(e.target.value) }} />
                    </div>
                    <button className='icon-button' onClick={incrementDate5}><ArrowNextRegular fontSize={24} /></button>
                </div>
            </div>

            <h5>Personas</h5>

            <div className='input-container' style={{ width: '360px', margin: '0.5rem 0 2rem 0' }}>
                <select id="personas" value={selectedCreador} onChange={e => {
                    setSelectedCreador(e.target.value)
                    setSelectedCreadorObject(listaPersonas.find(o => o.id === e.target.value))
                }}>
                    <option value="">---Todos---</option>
                    {listaPersonas.map(data =>
                        <optgroup key={data.id} label={data.id}>
                            <option value={data.id}>{data.name}</option>
                        </optgroup>
                    )}
                </select>
            </div>

            {
                selectedCreador &&
                <div className="events-person-card">
                    <img className="events-person-card-avatar" alt="" src={selectedCreadorObject.photoURL ? selectedCreadorObject.photoURL : defaultPhoto} />
                    <div>
                        <p>{selectedCreadorObject.name}</p>
                        <p>{selectedCreadorObject.id}</p>
                    </div>
                </div>

            }

            <div className='events-grid'>
                <EventsColumn
                    selectedCreador={selectedCreador}
                    date={new Date(selectedDate + 'T00:00:00')}
                    selectedDate={new Date(selectedDate + 'T00:00:00')}
                />

                {Array.from({ length: 4 }).map((_, i) => {
                    let date = new Date(selectedDate);
                    date.setDate(date.getDate() + i + 1);
                    return <EventsColumn
                        key={Math.random()}
                        selectedCreador={selectedCreador}
                        date={new Date(date.toISOString().slice(0, 10) + 'T00:00:00')}
                        selectedDate={new Date(selectedDate + 'T00:00:00')}
                    />
                })}

            </div>

            <div className='events-color-guide'>

                <CircleFilled fontSize={24} style={{ color: 'var(--color-secondary-container)' }} />
                <p>Eventos de otros</p>

                <CircleFilled fontSize={24} style={{ color: 'var(--color-primary)' }} />
                <p>Eventos en los que participo</p>

                <CircleFilled fontSize={24} style={{ color: 'var(--color-tertiary-container)' }} />
                <p>Eventos en curso</p>

            </div>

            <CreateEventDialog />

        </div >
    );
}

function CreateEventDialog() {
    const { userEmail } = useContext(UserContext);

    const [titulo, setTitulo] = useState('');
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    const [fecha, setFecha] = useState(now.getFullYear() + "-" + month + "-" + day);
    const [hora, setHora] = useState('07:00:00');
    const [duracion, setDuracion] = useState('');
    const [lugar, setLugar] = useState('');
    const [link, setLink] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const [creador, setCreador] = useState('');

    const [selectedParticipant, setSelectedParticipant] = useState('');
    const [participantes, setParticipantes] = useState([]);
    const [participantesKeys, setParticipantesKeys] = useState([]);

    const [adminList, setAdminList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const q = query(collection(db, "admins"), where("email", "==", userEmail));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    setCreador(doc.id);
                });
            } catch (error) {
                console.log(error);
            }

        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "admins"));
            var array = [];
            querySnapshot.forEach((doc) => {
                var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                array.push({
                    key: doc.id,
                    name: doc.data().name,
                    photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto
                });
            });
            setAdminList(array);
        }
        fetchData();
    }, []);


    useEffect(() => {
        if (selectedParticipant !== '') {
            if (!participantesKeys.includes(selectedParticipant)) {
                var admin = adminList.find(p => p.key === selectedParticipant);
                setParticipantes([...participantes, admin]);
                setParticipantesKeys([...participantesKeys, selectedParticipant]);
            }
            setSelectedParticipant('');
        }
    }, [selectedParticipant]);

    const removeFromArray = (elementToRemove) => {
        setParticipantes(participantes.filter(element => element.key !== elementToRemove));
        setParticipantesKeys(participantesKeys.filter(element => element !== elementToRemove));
    }

    const submitEvent = async e => {
        e.preventDefault();
        try {
            let convertedDate = new Date(fecha + "T" + hora);
            let datePlusDuration = convertedDate.setMinutes(convertedDate.getMinutes() + parseInt(duracion));
            var obj = {
                titulo: titulo,
                descripcion: descripcion,
                link: link,
                lugar: lugar,
                inicio: Timestamp.fromDate(new Date(fecha + "T" + hora)),
                fin: Timestamp.fromDate(new Date(datePlusDuration)),
                participantes: participantesKeys,
                creador: creador
            }
            const docRef = await addDoc(collection(db, "eventos"), obj);
            document.getElementById("event-create-form").reset();
            console.log("Document written with ID: ", docRef.id);
            window.history.back();
        } catch (error) {
            alert('Hubo un error, verifica que todos los campos esten correctos.');
            console.log(error);
        }
    }

    const resetFields = () => {
        setTitulo('');
        setDescripcion('');
        setLugar('');
        setLink('');
        setDuracion('');
    }

    return (
        <div id='event-create-dialog' className="dialog-scrim">
            <div className="dialog">
                <div className="dialog-tint">
                    <div className="dialog-header">
                        <CalendarAddRegular fontSize={24} />
                        <h2>Crear un evento</h2>
                    </div>


                    <form id='event-create-form' className="event-create-form" onSubmit={submitEvent} onReset={resetFields}>

                        <div className='input-container'>
                            <input className='input-normal' id="event-create-title" type="text" value={titulo} onChange={e => { setTitulo(e.target.value) }} />
                            <label className='input-label' htmlFor='event-create-title'>Titulo</label>
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="event-create-date" type="date" value={fecha} onChange={e => { setFecha(e.target.value) }} />
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="event-create-time" type="time" step="900" value={hora} onChange={e => { setHora(e.target.value) }} />
                            {/* <label className='input-label' htmlFor='profile-apellidos'>Hora de inicio</label> */}
                        </div>

                        <div className='input-container'>
                            <select id="event-create-duration" value={duracion} placeholder='Duracion' onChange={e => setDuracion(e.target.value)}>
                                <option value="" disabled>--- Duracion --</option>
                                <option value='15'>15 min</option>
                                <option value='30'>30 min</option>
                                <option value='45'>45 min</option>
                                <option value='60'>1 h</option>
                                <option value='90'>1,5 h</option>
                                <option value='120'>2 h</option>
                                <option value='150'>2,5 h</option>
                                <option value='180'>3 h</option>
                                <option value='210'>3,5 h</option>
                                <option value='240'>4 h</option>
                                <option value='300'>5 h</option>
                                <option value='360'>6 h</option>
                                <option value='420'>7 h</option>
                                <option value='480'>8 h</option>
                            </select>
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="profile-apellidos" type="text" value={lugar} onChange={e => { setLugar(e.target.value) }} />
                            <label className='input-label' htmlFor='profile-apellidos'>Lugar</label>
                        </div>

                        <div className='input-container'>
                            <input className='input-normal' id="profile-apellidos" type="text" value={link} onChange={e => { setLink(e.target.value) }} />
                            <label className='input-label' htmlFor='profile-apellidos'>Link</label>
                        </div>

                        <div>
                            <textarea id="w3review" name="w3review" rows="6" cols="50" placeholder="Descripcion del evento" value={descripcion} onChange={e => { setDescripcion(e.target.value) }} />
                        </div>


                        <div>
                            <div className='input-container'>
                                <select id="institucion" value={selectedParticipant} onChange={e => setSelectedParticipant(e.target.value)}>
                                    <option value="" disabled>---Selecciona Participantes---</option>
                                    {
                                        adminList.map(d =>
                                            <option key={d.key} value={d.key}>{d.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            {participantes.map(d =>
                                <div className="event-create-participant-chip" key={d.key}>
                                    <img className='event-create-avatar' src={d.photoURL}></img>
                                    <p>{d.name}</p>
                                    <button className="icon-button" onClick={() => removeFromArray(d.key)}><DeleteRegular fontSize={24} /></button>
                                </div>
                            )}

                        </div>

                    </form>

                    <div className="dialog-buttons-container">
                        <button className="text-button" onClick={() => window.history.back()}>Atras</button>
                        <button className="filled-button" type="submit" form="event-create-form">Crear evento</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function EventsColumn({ date, selectedDate, selectedCreador }) {

    const [eventsList, setEventsList] = useState([]);
    const dayNames = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];

    useEffect(() => {
        //robertozeledon@anep.or.cr
        var dateTomorrow = new Date(date);
        dateTomorrow.setDate(dateTomorrow.getDate() + 1);
        // console.log(selectedCreador)
        // const q = query(collection(db, "eventos"), where("inicio", ">", Timestamp.fromDate(date)), where("inicio", "<", Timestamp.fromDate(dateTomorrow)), orderBy('inicio', 'asc'));


        const eventRef = collection(db, "eventos");
        const q = selectedCreador ? query(
            eventRef,
            where("inicio", ">", Timestamp.fromDate(date)),
            where("inicio", "<", Timestamp.fromDate(dateTomorrow)),
            where("creador", "==", selectedCreador),
            orderBy('inicio', 'asc')
        ) :
            query(
                eventRef,
                where("inicio", ">", Timestamp.fromDate(date)),
                where("inicio", "<", Timestamp.fromDate(dateTomorrow)),
                orderBy('inicio', 'asc')
            );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const array = [];
            querySnapshot.forEach((doc) => {
                array.push({
                    key: doc.id,
                    titulo: doc.data().titulo,
                    inicio: doc.data().inicio,
                    fin: doc.data().fin,
                    creador: doc.data().creador,
                    participantes: doc.data().participantes ? doc.data().participantes : []
                });
            });
            // console.log("Events: ", array);
            setEventsList(array);
        });
    }, [date]);

    return (
        <div className='events-column'>
            <div className={date.toLocaleDateString() === selectedDate.toLocaleDateString() ? 'events-column-header events-column-header-highlight' : 'events-column-header'}>
                <p><small>{dayNames[date.getDay()]}</small></p>
                <h3>{date.getDate()}</h3>
                <p><small>{monthNames[date.getMonth()]}</small></p>
            </div>
            <div className='events-column-content'>
                {
                    eventsList.map(d =>
                        <Event
                            key={d.key}
                            id={d.key}
                            titulo={d.titulo}
                            inicio={d.inicio}
                            fin={d.fin}
                            creador={d.creador}
                            participantes={d.participantes}
                        />
                    )
                }
            </div>
        </div>
    );
}

function Event({ id, titulo, inicio, fin, creador, participantes }) {
    const { userEmail } = useContext(UserContext);

    const [creatorName, setCreatorName] = useState('');
    const [participantsList, setParticipantsList] = useState([]);

    const [inProgress, setInProgress] = useState(false);

    const inicioDate = new Date(inicio.seconds * 1000);
    const finDate = new Date(fin.seconds * 1000);
    const ahoraDate = new Date();

    useEffect(() => {
        if (ahoraDate >= inicioDate && ahoraDate <= finDate) {
            console.log('Fecha en rango')
            setInProgress(true);
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const docRef = doc(db, "admins", creador);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCreatorName(docSnap.data().name);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log(error);
            }

        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (participantes.length > 0) {
                const q = query(collection(db, "admins"), where(documentId(), "in", participantes));
                var array = [];
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    var defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/anep-d2f3a.appspot.com/o/default-user-photo.png?alt=media&token=0986f8cc-8065-43c0-a8b4-0abd0b46f756';
                    var obj = {
                        key: doc.id,
                        photoURL: doc.data().photoURL ? doc.data().photoURL : defaultPhoto,
                        name: doc.data().name,
                        email: doc.data().email
                    }
                    array.push(obj);
                });
                setParticipantsList(array);
            }
        }
        fetchData();
    }, []);

    function chooseClass() {
        if (participantsList.some(p => p.email === userEmail)) {
            return 'event-card event-card-mine';
        } else if (ahoraDate >= inicioDate && ahoraDate <= finDate) {
            return 'event-card event-card-in-progress';
        } else {
            return 'event-card'
        }
    }


    return (
        <Link to={{ pathname: '/evento', search: 'id=' + id }} className={chooseClass()}>

            <span className="event-card-time">
                <ClockRegular fontSize={18} />
                <p><small>{inicioDate.getHours() + ':' + String(inicioDate.getMinutes()).padStart(2, '0')} - {finDate.getHours() + ':' + String(finDate.getMinutes()).padStart(2, '0')}</small></p>
            </span>
            <p>{titulo}</p>

            <div className="event-card-creator">
                <PersonClockRegular fontSize={18} />
                <p><small>{creatorName}</small></p>
            </div>
            {
                participantsList.map(d =>

                    <div className="tooltip" key={d.key}>
                        <img className='event-card-avatar' src={d.photoURL}></img>
                        <span className="tooltiptext">{d.name}</span>
                    </div>

                )
            }
        </Link>
    );
}