import { collection, doc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import './PolizaEnRevision.css';

const db = getFirestore();

export default function PolizasEnRevision() {
    const [paymentList, setPaymentList] = useState([]);

    useEffect(() => {

        async function fetchData() {
            const citiesRef = collection(db, "usuarios");

            const q = query(citiesRef, where("polizaRevision", "==", true), where("poliza", "!=", 'NINGUNO'));

            const querySnapshot = await getDocs(q);
            var arr = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var data = doc.data();
                arr.push({
                    key: doc.id,
                    nombre: data.nombre,
                    apellidos: data.apellidos,
                    poliza: data.poliza,
                    email: data.email,
                    contacto1: data.contacto1,
                    contacto2: data.contacto2,
                    contacto3: data.contacto3,
                    active: data.active
                });
            });
            setPaymentList(arr);
        }

        fetchData();

    }, []);

    const mark = async (id) => {
        console.log(id)
        const washingtonRef = doc(db, "usuarios", id);

        await updateDoc(washingtonRef, {
            polizaRevision: false
        });
    }

    const list = paymentList.map(data =>
        <div key={data.key} className="poliza-solicitud-ticket">
            <p>Cedula</p>
            <h4>{data.key}</h4>
            <p>Nombre completo</p>
            <h4>{data.nombre + " " + data.apellidos}</h4>
            <p>Poliza</p>
            <h4>{data.poliza}</h4>
            <p>Correo</p>
            <h4>{data.email}</h4>
            <p>Celular</p>
            <h4>{data.contacto1}</h4>
            <p>Telefono Casa</p>
            <h4>{data.contacto2}</h4>
            <p>Telefono Oficina</p>
            <h4>{data.contacto3}</h4>
            {
                data.active ?
                    <h3>Solicitud</h3> :
                    <h3>Desafiliacion</h3>
            }
            <div className="poliza-solicitud-button">
                <button className='filled-tonal-button' onClick={() => { mark(data.key) }}>Marcar como completado</button>
            </div>
        </div>
    );



    return (
        <div className="page">
            <h1>Polizas en revision</h1>
            <p>Se muestran las nuevas afiliaciones que incluyeron una poliza basica, asi como informacion de contacto para dar seguimiento a la solicitud.</p>
            {list}
        </div>
    );
}