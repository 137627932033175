import './NavigationRail.css';
import {
    HomeRegular,
    HomeFilled,
    DocumentAddRegular,
    DocumentAddFilled,
    AppsListRegular,
    AppsListFilled,
    NewsRegular,
    NewsFilled,
    TagFilled,
    TagRegular,
    PersonAddRegular,
    PersonAddFilled,
    BuildingMultipleRegular,
    BuildingMultipleFilled,
    BuildingRegular,
    BuildingFilled,
    BuildingRetailRegular,
    BuildingRetailFilled,
    PeopleCommunityRegular,
    PeopleCommunityFilled,
    SearchRegular,
    DataAreaRegular,
    DataAreaFilled,
    ShieldTaskRegular,
    ShieldTaskFilled,
    VideoPersonStarRegular,
    VideoPersonStarFilled,
    TaskListSquareLtrRegular,
    TaskListSquareLtrFilled,
    TaskListSquareAddRegular,
    TaskListSquareAddFilled,
    ShareScreenPersonOverlayRegular,
    ShareScreenPersonOverlayFilled,
    DocumentTableRegular,
    DocumentTableFilled,
    InfoRegular,
    InfoFilled,
    CalendarRtlRegular,
    CalendarRtlFilled
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";

export default function NavigationRail() {

    return (
        <nav className='navigation-rail'>

            <Link to='/search'>
                <button className='navigation-rail-fab fab'>
                    <SearchRegular fontSize={24} />
                </button>
            </Link>


            <CustomLink
                to="/"
                state={{ title: 'enotar.io' }}
                icon={<HomeRegular fontSize={24} />}
                activeicon={<HomeFilled fontSize={24} />}>
                Inicio
            </CustomLink>

            <CustomLink
                to="planificador"
                state={{ title: 'Help' }}
                icon={<CalendarRtlRegular fontSize={24} />}
                activeicon={<CalendarRtlFilled fontSize={24} />}>
                Planifica...
            </CustomLink>

            <CustomLink
                to="padron"
                state={{ title: 'Help' }}
                icon={<ShareScreenPersonOverlayRegular fontSize={24} />}
                activeicon={<ShareScreenPersonOverlayFilled fontSize={24} />}>
                Padron Nacional
            </CustomLink>

            <CustomLink
                to="release-notes"
                icon={<InfoRegular fontSize={24} />}
                activeicon={<InfoFilled fontSize={24} />}>
                Notas de version
            </CustomLink>



            {/* <CustomLink
                to="noticias"
                state={{ title: 'Noticias' }}
                icon={<NewsRegular fontSize={24} />}
                activeicon={<NewsFilled fontSize={24} />}>
                Noticias
            </CustomLink>

            <CustomLink
                to="convenios"
                state={{ title: 'Convenios' }}
                icon={<TagRegular fontSize={24} />}
                activeicon={<TagFilled fontSize={24} />}>
                Convenios
            </CustomLink>


            <CustomLink
                to="create"
                state={{ title: 'Crear' }}
                icon={<PersonAddRegular fontSize={24} />}
                activeicon={<PersonAddFilled fontSize={24} />}>
                Crear afiliado
            </CustomLink>

            <CustomLink
                to="instituciones"
                state={{ title: 'Instituciones' }}
                icon={<BuildingMultipleRegular fontSize={24} />}
                activeicon={<BuildingMultipleFilled fontSize={24} />}>
                Instituc...
            </CustomLink>

            <CustomLink
                to="departamentos"
                state={{ title: 'Departamentos' }}
                icon={<BuildingRegular fontSize={24} />}
                activeicon={<BuildingFilled fontSize={24} />}>
                Depart...
            </CustomLink>

            <CustomLink
                to="dependencias"
                state={{ title: 'Dependencias' }}
                icon={<BuildingRetailRegular fontSize={24} />}
                activeicon={<BuildingRetailFilled fontSize={24} />}>
                Depend...
            </CustomLink>

            <CustomLink
                to="promotores"
                state={{ title: 'Promotores' }}
                icon={<PeopleCommunityRegular fontSize={24} />}
                activeicon={<PeopleCommunityFilled fontSize={24} />}>
                Promotores
            </CustomLink>

            <CustomLink
                to="reportes"
                state={{ title: 'Reportes' }}
                icon={<DocumentTableRegular fontSize={24} />}
                activeicon={<DocumentTableFilled fontSize={24} />}>
                Reportes
            </CustomLink>

            <CustomLink
                to="financiero"
                state={{ title: 'Help' }}
                icon={<DataAreaRegular fontSize={24} />}
                activeicon={<DataAreaFilled fontSize={24} />}>
                Reportes financiero
            </CustomLink>

            <CustomLink
                to="pagos"
                state={{ title: 'Records' }}
                icon={<DocumentAddRegular fontSize={24} />}
                activeicon={<DocumentAddFilled fontSize={24} />}>
                Subir pagos
            </CustomLink>

            <CustomLink
                to="polizas"
                state={{ title: 'Help' }}
                icon={<ShieldTaskRegular fontSize={24} />}
                activeicon={<ShieldTaskFilled fontSize={24} />}>
                Admin de polizas
            </CustomLink>

            <CustomLink
                to="casos-fallecimiento"
                state={{ title: 'Records' }}
                icon={<TaskListSquareLtrRegular fontSize={24} />}
                activeicon={<TaskListSquareLtrFilled fontSize={24} />}>
                Casos de fallec...
            </CustomLink>


            <CustomLink
                to="crear-caso-fallecimiento"
                state={{ title: 'Help' }}
                icon={<TaskListSquareAddRegular fontSize={24} />}
                activeicon={<TaskListSquareAddFilled fontSize={24} />}>
                Crear caso de fallec...
            </CustomLink>

            <CustomLink
                to="solicitudes-poliza"
                state={{ title: 'Records' }}
                icon={<AppsListRegular fontSize={24} />}
                activeicon={<AppsListFilled fontSize={24} />}>
                Solicitudes de poliza
            </CustomLink>

            <CustomLink
                to="padron"
                state={{ title: 'Help' }}
                icon={<ShareScreenPersonOverlayRegular fontSize={24} />}
                activeicon={<ShareScreenPersonOverlayFilled fontSize={24} />}>
                Padron Nacional
            </CustomLink>

            <CustomLink
                to="admins"
                state={{ title: 'Help' }}
                icon={<VideoPersonStarRegular fontSize={24} />}
                activeicon={<VideoPersonStarFilled fontSize={24} />}>
                Roles de administ...
            </CustomLink> */}

        </nav>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className="navigation-rail-item" style={{ textAlign: 'center' }}>
            <div className={match ? 'navigation-rail-active-indicator navigation-rail-active-indicator-match' : 'navigation-rail-active-indicator'}>
                {match ? props.activeicon : props.icon}
            </div>
            {children}
        </Link>
    );
}