import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { collection, query, where, getFirestore, getDocs } from "firebase/firestore";
import searchProfile from '../assets/searchProfile.png';

const db = getFirestore();

export default function ProfileSearch() {
    const [id, setId] = useState('');


    // SEARCH
    const [criteria, setCriteria] = useState('LASTNAME');
    const [searchValue, setSearchValue] = useState('');
    const [resultsList, setResultsList] = useState([]);

    let navigate = useNavigate();

    const search = async () => {
        setResultsList([]);
        try {
            // const userRef = collection(db, "usuarios");
            // const q = query(userRef, where("id", "==", searchValue));
            // const querySnapshot = await getDocs(q);
            // querySnapshot.forEach((doc) => {
            //     console.log(searchValue);
            //     // doc.data() is never undefined for query doc snapshots
            //     console.log(doc.id, " => ", doc.data());
            // });

            const citiesRef = collection(db, "usuarios");

            var searchBy = (criteria === 'LASTNAME') ? 'apellidos' : 'nombre';

            // Create a query against the collection.
            const q = query(citiesRef, where(searchBy, "==", searchValue));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                array.push({
                    id: doc.id,
                    nombre: doc.data().nombre,
                    apellidos: doc.data().apellidos
                })
            });
            setResultsList(array);
        } catch (error) {
            console.log(error);
        }
    }



    return (
        <div className='page' style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '600px', margin: '4rem auto' }}>
            {/* <img src={searchProfile} width='256px'/> */}
            <h2>Buscar un afiliado</h2>
            <p>A continuacion digita el numero de cedula del afiliado que quieras acceder directamente:</p>
            <div className='input-container'>
                <input className='input-normal' id="profile-id" type="number" value={id} onChange={e => { setId(e.target.value) }} />
                <label className='input-label' htmlFor='profile-id'>ID</label>
            </div>

            <button className='filled-button' onClick={() => { navigate({ pathname: '/profile', search: '?id=' + id }); }}>Ir directamente</button>

            <h2>Busqueda</h2>

            <select id="criteria" value={criteria} onChange={e => setCriteria(e.target.value)}>
                <option value="LASTNAME">Por apellidos</option>
                <option value="NAME">Por nombre</option>
            </select>

            <div className='input-container'>

                <input className='input-normal' id="profile-search" type="text" value={searchValue} onChange={e => { setSearchValue(e.target.value.toLocaleUpperCase()) }} />
                <label className='input-label' htmlFor='profile-search'>Busqueda por coincidencia exacta</label>
            </div>

            <button className='filled-tonal-button' onClick={search}>Buscar</button>

            <h3>Resultados</h3>

            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Perfil</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        resultsList.map(data =>
                            <tr key={data}>
                                <td>{data.id}</td>
                                <td>{data.nombre}</td>
                                <td>{data.apellidos}</td>
                                <td><Link to={{ pathname: '/profile', search: '?id=' + data.id }}>Ver Perfil</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>

            {
                resultsList.length === 0 && <h5>No hay resultados</h5>
            }
        </div>
    );
}