import { useContext, useEffect } from 'react';
import { Link, Navigate, Route, Routes } from "react-router-dom";
import {
  DocumentAddRegular,
  AppsListRegular,
  NewsRegular,
  TagRegular,
  PersonAddRegular,
  BuildingMultipleRegular,
  BuildingRegular,
  BuildingRetailRegular,
  PeopleCommunityRegular,
  DataAreaRegular,
  ShieldTaskRegular,
  VideoPersonStarRegular,
  TaskListSquareLtrRegular,
  TaskListSquareAddRegular,
  ShareScreenPersonOverlayRegular,
  DocumentTableRegular,
  ContentSettingsRegular,
  BuildingRetailShieldRegular,
  DocumentOnePageRegular,
  PinRegular,
  SearchRegular,
  InfoRegular,
  CalendarRtlRegular,
  NumberRowRegular,
  BriefcaseRegular
} from "@fluentui/react-icons";
import './Home.css';
import { UserContext } from '../UserContext';

export default function Home() {
  return (
    <div className='page'>
      <h1 style={{ marginBottom: '2rem' }}>Inicio</h1>

      {/* <h4 style={{ display: 'flex', gap: '0.5rem' }}><PinRegular fontSize={24} /> Tus Herramientas</h4> */}

      <h4>Herramientas</h4>
      <div className='home-links-rail'>
        <HomeItem to='search' allowedLevels={[0, 1, 2, 3, 4, 5, 8, 9]}>
          <SearchRegular fontSize={24} />
          Buscar perfil
        </HomeItem>
        <HomeItem to='planificador' allowedLevels={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
          <CalendarRtlRegular fontSize={24} />
          Registro de actividad diaria
        </HomeItem>
        <HomeItem to='padron' allowedLevels={[0, 1, 2, 3, 4, 5, 8, 9]}>
          <ShareScreenPersonOverlayRegular fontSize={24} />
          Padron Nacional
        </HomeItem>
        <HomeItem to='release-notes' allowedLevels={[0, 1, 2, 3, 4, 5]}>
          <InfoRegular fontSize={24} />
          Notas de version
        </HomeItem>
        <HomeItem to='planillas' allowedLevels={[0, 1, 2]}>
          <DocumentOnePageRegular fontSize={24} />
          Planillas
        </HomeItem>
      </div>

      <h4>Afiliacion</h4>
      <div className='home-links-rail'>
        <HomeItem to='create' allowedLevels={[0, 1]}>
          <PersonAddRegular fontSize={24} />
          Crear afiliado
        </HomeItem>
        <HomeItem to='instituciones' allowedLevels={[0, 1]}>
          <BuildingMultipleRegular fontSize={24} />
          Instituciones
        </HomeItem>
        <HomeItem to='departamentos' allowedLevels={[0, 1]}>
          <BuildingRegular fontSize={24} />
          Departamentos
        </HomeItem>
        <HomeItem to='dependencias' allowedLevels={[0, 1]}>
          <BuildingRetailRegular fontSize={24} />
          Dependencias
        </HomeItem>
        <HomeItem to='promotores' allowedLevels={[0, 1]}>
          <PeopleCommunityRegular fontSize={24} />
          Promotores
        </HomeItem>
        <HomeItem to='reportes' allowedLevels={[0, 1, 2]}>
          <DocumentTableRegular fontSize={24} />
          Reportes
        </HomeItem>
      </div>

      <h4>Financiero</h4>
      <div className='home-links-rail'>
        <HomeItem to='financiero' allowedLevels={[0, 1, 3]}>
          <DataAreaRegular fontSize={24} />
          Reportes financieros
        </HomeItem>
        <HomeItem to='pagos' allowedLevels={[0, 1]}>
          <DocumentAddRegular fontSize={24} />
          Subir pagos
        </HomeItem>
      </div>

      <h4>Legal</h4>
      <div className='home-links-rail'>
        <HomeItem to={'legal'} className="home-rail-item" allowedLevels={[0, 8, 9]}>
          <BriefcaseRegular fontSize={24} />
          Departamento legal
        </HomeItem>
      </div>

      <h4>Polizas</h4>
      <div className='home-links-rail'>
        <HomeItem to='polizas' allowedLevels={[0, 2]}>
          <ShieldTaskRegular fontSize={24} />
          Administrador de polizas
        </HomeItem>
        <HomeItem to='generador-ins' allowedLevels={[0, 2, 5]}>
          <BuildingRetailShieldRegular fontSize={24} />
          Generador INS
        </HomeItem>
        <HomeItem to='casos-fallecimiento' allowedLevels={[0, 2, 5]}>
          <TaskListSquareLtrRegular fontSize={24} />
          Casos de fallecimiento
        </HomeItem>
        <HomeItem to='crear-caso-fallecimiento' allowedLevels={[0, 2, 5]}>
          <TaskListSquareAddRegular fontSize={24} />
          Crear caso de fallecimiento
        </HomeItem>
        <HomeItem to='solicitudes-poliza' allowedLevels={[0, 2, 5]}>
          <AppsListRegular fontSize={24} />
          Solicitudes de poliza
        </HomeItem>
        <HomeItem to='solicitudes-poliza' allowedLevels={[0, 2]}>
          <NumberRowRegular fontSize={24} />
          Numero de referencia de polizas
        </HomeItem>
      </div>

      <h4>Asesores sindicales</h4>
      <div className='home-links-rail'>
        <Link to={'soon'} className="home-rail-item">
          <ContentSettingsRegular fontSize={24} />
          Proximamente...
        </Link>
      </div>

      <h4>App</h4>
      <div className='home-links-rail'>
        <HomeItem to='noticias' allowedLevels={[0, 7]}>
          <NewsRegular fontSize={24} />
          Noticias
        </HomeItem>
        <HomeItem to='convenios' allowedLevels={[0, 7]}>
          <TagRegular fontSize={24} />
          Convenios
        </HomeItem>
      </div>

      <h4>Admin</h4>
      <div className='home-links-rail'>
        <HomeItem to='admins' allowedLevels={[0]}>
          <VideoPersonStarRegular fontSize={24} />
          Roles de administrador
        </HomeItem>
      </div>
    </div>
  )
}

function HomeItem({ allowedLevels, to, children }) {
  const { userLevel } = useContext(UserContext);
  const isIncluded = allowedLevels.includes(userLevel);
  return (
    <Link to={to} className={isIncluded ? 'home-rail-item' : 'home-rail-item home-rail-item-disabled'}>
      {children}
    </Link >
  );
}