import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, addDoc, getDocs, query, where, orderBy, limit, startAfter, getDoc, doc, serverTimestamp, Timestamp } from "firebase/firestore";
import xlsx from '../assets/xlsx.svg';

const db = getFirestore();

export default function ReporteFinanciero() {
  const [dataList, setDataList] = useState([]);
  const [listaInstituciones, setListaInstituciones] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [institucion, setInstitucion] = useState('');
  const [loading, setLoading] = useState(false);
  const [months, setMonths] = useState(1);
  const listaMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];
  // A Y A 
  // GET CURRENT MOTNH
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const currentMonthString = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  const currentYearString = currentYear.toString();
  const currentDateString = `${currentYearString}-${currentMonthString}`;
  const [month, setMonth] = useState(currentDateString);

  const [cuerda, setCuerda] = useState('Inicial');

  function saveFile(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    }
  }

  function saveSomeData(string) {
    const blob = new Blob([string], {
      type: 'application/json'
    });
    saveFile(blob, "Reporte financiero.csv")
  }

  useEffect(() => {
    console.log(cuerda);
  }, [cuerda])

  useEffect(() => {

    console.log(dataList)

  }, [dataList])

  var list = dataList.map(data =>
    <tbody key={data.key}>
      <tr>
        <td>{data.id}</td>
        <td>{data.name}</td>
        <td>{data.lastname}</td>
        <td>{data.institucion}</td>
        <td>{data.dependencia}</td>
        <td>{data.fechaAfiliacion}</td>
        <td>{data.fechaDesafiliacion}</td>
      </tr>
    </tbody>
  );

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(db, "instituciones", "lista");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data().instituciones);
        setListaInstituciones(docSnap.data().instituciones.sort());
      } else {
        console.log("No such document!");
      }
    }
    fetchData();
  }, []);

  const instList = listaInstituciones.map(data =>
    <option key={data} value={data}>{data}</option>
  )


  const getReport2 = async () => {
    setLoading(true);

    console.log(month);
    let start = new Date(`${month}-01T00:00:00-06:00`);
    start.setHours(0, 0, 0, 0);
    // Get end date
    let refDate = new Date(`${month}-01T00:00:00-06:00`);
    const monthRange = searchValue ? months : 1;
    // Get the last day of start month
    refDate.setMonth(refDate.getMonth() + 1);
    refDate.setDate(0); // Set date to 0 to get the last day of the previous month
    refDate.setHours(23, 59, 59, 999); // Set to the end of the day

    let end = new Date(refDate);

    console.log(start);
    console.log(end);

    const pagosRef = collection(db, "pagos");
    const q = query(pagosRef, where("emitido", ">=", start), where("emitido", "<=", end));

    const querySnapshot = await getDocs(q);
    let pagos = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      var obj = {
        emitido: new Date(doc.data().emitido.seconds * 1000).toLocaleDateString(),
        id: doc.data().id,
        monto: doc.data().monto,
        vigencia: new Date(doc.data().vigencia.seconds * 1000).toLocaleDateString(),
        periodo: doc.id.slice(-10)
      }
      pagos.push(obj);
    });
    console.log(pagos);

    const userRef = collection(db, "usuarios");
    const que = query(userRef, where("institucion", "==", searchValue));

    let querySnapshot2;

    if (searchValue) {
      console.log(searchValue);
      querySnapshot2 = await getDocs(que);
    } else {
      querySnapshot2 = await getDocs(userRef);
    }

    let usuarios = [];
    querySnapshot2.forEach((doc) => {
      var obj = {
        id: doc.data().cedula,
        'Cedula': doc.data().cedula,
        'Nombre': doc.data().nombre + ' ' + doc.data().apellidos,
        'Institucion': doc.data().institucion,
        'Fecha Afiliacion': doc.data().ingreso ? new Date(doc.data().ingreso.seconds * 1000).toLocaleDateString() : '',
        'Fecha Desafiliacion': doc.data().desafiliacion ? new Date(doc.data().desafiliacion.seconds * 1000).toLocaleDateString() : '',
        'Estado': doc.data().active ? 'ACTIVO' : 'INACTIVO',
        'Vigencia (Expira)': doc.data().expira ? new Date(doc.data().expira.seconds * 1000).toLocaleDateString() : '',
      }
      usuarios.push(obj)
    });
    console.log(usuarios)

    // Primero, determinamos todos los periodos únicos en los datos de pago.
    let periodos = [...new Set(pagos.map(pago => pago.periodo))];

    // Luego, para cada usuario, buscamos los pagos que correspondan a cada periodo.
    let datosCombinados = usuarios.map(usuario => {
      let pagosUsuario = pagos.filter(pago => pago.id === usuario.id);
      let fila = { ...usuario };
      for (let periodo of periodos) {
        let pagoPeriodo = pagosUsuario.find(pago => pago.periodo === periodo);
        fila[periodo] = pagoPeriodo ? pagoPeriodo.monto : '';
      }
      return fila;
    });

    function aCSV(datos) {
      let header = ["Cedula", "Nombre", "Institucion", "Fecha Afiliacion", "Fecha Desafiliacion", "Estado", "Vigencia (Expira)", ...periodos];
      let csv = datos.map(row => header.map(fieldName => row[fieldName]).join(";")).join("\n");
      return header.join(";") + "\n" + csv;
    }

    let csv = aCSV(datosCombinados);
    saveSomeData(csv);
    setLoading(false);
  }

  return (
    <div className='page'>
      <h1>Reporte financiero</h1>
      <p style={{ marginBottom: '1rem' }}>A continuación puedes generar informes financieros generales. Selecciona un mes y el archivo se descargará en formato CSV. Si no selecciona una institucion, se creara un reporte general.</p>

      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
        {/* <label htmlFor="select-institucion">Institucion:</label> */}
        <select id="select-institucion" value={searchValue} onChange={e => setSearchValue(e.target.value)} style={{ width: '206px' }}>
          <option value="" disabled>---Selecciona una Institucion---</option>
          {instList}
        </select>
      </div>



      <div style={{ margin: '1rem 0', gap: '1rem', display: 'flex' }}>
        {/* <label htmlFor='mes'>Mes</label> */}
        <div>
          <input className='input-normal' type="month" id="mes" name="mes" value={month} onChange={e => setMonth(e.target.value)}></input>
        </div>
      </div>

      {
        searchValue && (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
            <label htmlFor="select-cuerda" style={{ marginBottom: '1rem' }}>Rango de meses:</label>
            <select id="select-cuerda" value={months} onChange={e => setMonths(parseInt(e.target.value))} style={{ width: '206px' }}>
              <option value="1">1 mes</option>
              <option value="3">3 meses</option>
              <option value="6">6 meses</option>
            </select>
          </div>
        )
      }

      {
        (searchValue && months > 1) && (
          <p style={{ marginBottom: '1rem' }}>
            {`Meses incluidos: ${listaMeses[(parseInt(month.slice(-2)) - 1) % 12]} hasta ${listaMeses[(parseInt(month.slice(-2)) - 1 + months - 1) % 12]}`}
          </p>
        )
      }

      <button className='filled-button' onClick={getReport2} disabled={loading}>Obtener pagos en rango de mes</button>


      {loading && <h3 style={{ marginTop: '1rem' }}>Procesando, por favor espere...</h3>}

      {
        loading &&
        <img src={'https://www.downgraf.com/wp-content/uploads/2014/09/01-progress.gif?e44397'} style={{ height: '96px', width: '96px' }} />
      }

    </div>
  );
}