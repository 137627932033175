import React, { createContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink, getIdToken } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import axios from 'axios';

export const UserContext = createContext();

const db = getFirestore();

export function UserProvider({ children }) {
    const [authState, setAuthState] = useState(null);
    const [verified, setVerified] = useState(false);
    const [authenticating, setAuthenticating] = useState(false);
    const [authError, setAuthError] = useState('');
    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhotoUrl, setUserPhotoUrl] = useState("");
    const [userLevel, setUserLevel] = useState(99);

    const auth = getAuth();

    useEffect(() => {

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid);
                setUserEmail(user.email);
                setUserPhotoUrl(user.photoURL);
                const idTokenResult = await auth.currentUser.getIdTokenResult(true);
                setVerified(idTokenResult.claims.verified);
                setUserLevel(idTokenResult.claims.level);
                setAuthState(true);
                console.log('User Signed in: ', user.uid, user.email, idTokenResult.claims);
            } else {
                setAuthState(false);
                console.log('User Signed out');
            }
        });

    }, []);

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            setAuthenticating(true);

            signInWithEmailLink(auth, email, window.location.href)
                .then(async (result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    const idToken = await getIdToken(result.user);
                    // var url = 'http://localhost:5001/anep-d2f3a/us-central1/setCustomClaims';
                    // var url = 'http://localhost:5001/anep-d2f3a/us-central1/setCustomClaimsTwo';
                    var url = 'https://us-central1-anep-d2f3a.cloudfunctions.net/setCustomClaims';
                    axios.post(url, {
                        idToken: idToken
                    })
                        .then(async (response) => {
                            console.log(response);

                            const ux = await auth.currentUser.getIdToken(true);
                            console.log(ux);

                            const idTokenResult = await auth.currentUser.getIdTokenResult();
                            setVerified(idTokenResult.claims.verified);
                            setAuthenticating(false);
                        })
                        .catch((error) => {
                            console.log(error);

                            setAuthError('CODE: 701. No se pudo verificar este usuario, si crees que es un error por favor contacta al soporte tecnico.');
                            if (error.response.data === 'ineligible') {
                                setAuthError('CODE: 703. Este correo no es elegible.');
                            }
                            setAuthenticating(false);
                        })

                })
                .catch((error) => {
                    console.log(error.message);
                    // setAuthError('CODE: 702. No se pudo verificar este usuario, si crees que es un error por favor contacta al soporte tecnico.');
                    setAuthError(error.message);
                    setAuthenticating(false);
                });
        }
    }, []);

    return (
        <UserContext.Provider value={{
            userId,
            authState,
            verified,
            userEmail,
            userLevel,
            userPhotoUrl,
            authenticating,
            authError,
        }}>
            {children}
        </UserContext.Provider>
    );
}