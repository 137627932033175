import './Post.css';
import { ShareRegular, ThumbLikeRegular, ArrowUploadRegular, DeleteRegular } from "@fluentui/react-icons";
import { getFirestore, doc, deleteDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useEffect, useRef, useState } from 'react';

const db = getFirestore();

export default function Post({ title, description, thumbnail, likesCounter, id }) {
    const [counter, setCounter] = useState(likesCounter);

    useEffect(() => {
        var docRef = doc(db, 'news', id);
        onSnapshot(docRef, (doc) => {
            setCounter(doc.data().likesCounter);
        });
    }, []);


    const share = () => {
        const shareData = {
            title: title,
            text: description,
            url: 'https://anep.app/noticias',
            // url: 'https://anep.app/news?id='+id,
        }
        navigator.share(shareData);
    }

    const inputFile = useRef(null);

    function handleChange(event) {
        var file = event.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, 'news/' + id + '/thumbnail.jpg');

        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            getDownloadURL(ref(storage, snapshot.metadata.fullPath)).then(async url => {
                await updateDoc(doc(db, "news", id), {
                    thumbnail: url
                });
                console.log('URL', url);
            })
        });
    }

    const deleteNew = async () => {
        if (window.confirm("¿Estás seguro/a que quieres borrar esto?")) {
            const docRef = await deleteDoc(doc(db, "news", id));
            console.log('Document deleted', docRef)
        }
    }

    return (
        <div className="post-card">
            <div className="post-card-tint">
                {
                    thumbnail ?
                        <img src={thumbnail} className="post-card-img" /> :
                        <button className='post-upload' onClick={() => inputFile.current.click()}>
                            <ArrowUploadRegular fontSize={24} />
                            <p>Upload an image</p>
                        </button>
                }

                <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleChange} />

                <div className="post-card-content">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                        <button className="icon-button"><ThumbLikeRegular fontSize={24} /></button>
                        <p><b>{counter}</b></p>
                        {/* <button className="icon-button" onClick={share}><ShareRegular fontSize={24} /></button> */}
                    </div>
                    <button className='icon-button post-delete-button' onClick={deleteNew}>
                        <DeleteRegular fontSize={24} />
                    </button>
                </div>
            </div>
        </div>
    );
}