import { LinkRegular } from '@fluentui/react-icons';
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CasosAbiertos.css';

const db = getFirestore();

export default function CasosAbiertos() {
    const [ultimosCasos, setUltimosCasos] = useState([]);
    const [selection, setSelection] = useState('RECIENTES');

    useEffect(() => {
        async function fetchData() {
            var q = query(collection(db, "casos"), orderBy("created", "desc"), limit(5));
            switch (selection) {
                case 'RECIENTES':
                    q = query(collection(db, "casos"), orderBy("created", "desc"), limit(5));
                    break;
                case 'CASO EN TRAMITE':
                    q = query(collection(db, "casos"), where("estado", "==", "CASO EN TRAMITE"), orderBy("created", "desc"), limit(5));
                    break;
                case 'CASO EN INS':
                    q = query(collection(db, "casos"), where("estado", "==", "CASO EN INS"), orderBy("created", "desc"), limit(5));
                    break;
                case 'PAGO PENDIENTE':
                    q = query(collection(db, "casos"), where("estado", "==", "PAGO PENDIENTE"), orderBy("created", "desc"), limit(5));
                    break;
                case 'SUCESORIO':
                    q = query(collection(db, "casos"), where("estado", "==", "SUCESORIO"), orderBy("created", "desc"), limit(5));
                    break;
                case 'FINALIZADO':
                    q = query(collection(db, "casos"), where("estado", "==", "FINALIZADO"), orderBy("created", "desc"), limit(5));
                    break;
                default:
                    break;
            }
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach((doc) => {
                var data = doc.data();
                var created = new Date(data.created.seconds * 1000).toLocaleString();

                array.push({
                    key: doc.id,
                    estado: data.estado,
                    created: created,
                    linked: data.linked
                });
            });
            setUltimosCasos(array);
        }
        fetchData();
    }, [selection]);

    return (
        <div className='page'>
            <h1>Casos de fallecimiento</h1>
            <p style={{ marginBottom: '1rem' }}>Se muestran los casos activos y pendientes.</p>

            <div className='casos-tabs'>
                <button className={(selection === 'RECIENTES') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('RECIENTES')}>
                    {/* <span className='material-symbols-rounded'>history</span> */}
                    Recientes
                </button>
                <button className={(selection === 'CASO EN TRAMITE') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('CASO EN TRAMITE')}>En tramite</button>
                <button className={(selection === 'CASO EN INS') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('CASO EN INS')}>En INS</button>
                <button className={(selection === 'PAGO PENDIENTE') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('PAGO PENDIENTE')}>Pendientes de pago</button>
                <button className={(selection === 'SUCESORIO') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('SUCESORIO')}>En sucesorio</button>
                <button className={(selection === 'FINALIZADO') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('FINALIZADO')}>Finalizados</button>
            </div>

            <h4>Resultados</h4>

            <div className='casos-table-container'>
                <table>
                    <thead>
                        <tr>
                            <th>Id de caso</th>
                            <th>Creado</th>
                            <th>Estado</th>
                            <th><LinkRegular fontSize={24} /></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {
                            ultimosCasos.map(data =>
                                <tr key={data.key}>
                                    <td><code>{data.key}</code></td>
                                    <td>{data.linked}</td>
                                </tr>

                            )
                        } */}

                        {
                            ultimosCasos.map(data =>
                                <tr key={data.key}>
                                    <td><code>{data.key}</code></td>
                                    <td>{data.created}</td>
                                    <td>{data.estado}</td>
                                    <td>{data.linked}</td>
                                    <td>
                                        <Link to={{ pathname: '/caso', search: '?id=' + data.key }}><button className='elevated-button'>Ir a caso</button></Link>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}