import { useEffect, useState } from "react";
import { collection, doc, getDocs, getFirestore, updateDoc, query, onSnapshot, addDoc } from "firebase/firestore";
import { AddRegular, CheckmarkRegular, DismissRegular, EditRegular, PersonAddRegular } from "@fluentui/react-icons";
import './Polizas.css';

const db = getFirestore();

export default function Polizas() {
    const [dataList, setDataList] = useState([]);
    // CREAR POLIZA
    const [displayName, setDisplayName] = useState('');
    const [monto, setMonto] = useState('');
    const [deduccion, setDeduccion] = useState('');

    useEffect(() => {
        const q = query(collection(db, "polizas"));
        onSnapshot(q, (querySnapshot) => {
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({
                    key: doc.id,
                    id: doc.data().id,
                    monto: doc.data().monto,
                    active: doc.data().active,
                    deduccion: doc.data().deduccion
                });
            });
            setDataList(array);
            console.log(array);
        });

    }, []);

    const addPoliza = async e => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "polizas"), {
                id: displayName,
                monto: monto,
                deduccion: deduccion,
                active: true
            });
            console.log("Document written with ID: ", docRef.id);
            setDisplayName('');
            setMonto('');
            setDeduccion('');
        } catch (error) {
            console.log(error);
            alert(error.code);
        }


    }

    return (
        <div className="page">
            <h1>Administrador de Polizas</h1>

            <p>En esta pagina se podran administrar las polizas, se pueden editar los Display Name, Monto de poliza, Deduccion y el Estado. El ID Unico no se puede modificar, en tal caso se debera crear una nueva poliza.</p>

            <div style={{ overflowX: 'auto', marginTop: '1rem' }}>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Id Unico</th>
                            <th>Display name</th>
                            <th>Monto de poliza</th>
                            <th>Deduccion</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>

                    </thead>
                    {
                        dataList.map(data =>
                            <PolizaRow key={data.key} document={data.key} id={data.id} monto={data.monto} deduccion={data.deduccion} active={data.active} />
                        )
                    }
                </table>
            </div>

            <form onSubmit={addPoliza}>
                <fieldset className="polizas-create">
                    <legend>Agregar una Poliza:</legend>
                    <div className='input-container'>
                        <input className='input-normal' id="profile-cedula-beneficiario" type="text" value={displayName} onChange={e => { setDisplayName(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-cedula-beneficiario'>Display name</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-nombre-beneficiario" type="text" value={monto} onChange={e => { setMonto(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-nombre-beneficiario'>Monto</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-parentesco-beneficiario" type="text" value={deduccion} onChange={e => { setDeduccion(e.target.value) }} />
                        <label className='input-label' htmlFor='profile-parentesco-beneficiario'>Deduccion</label>
                    </div>

                    <button type='submit' className='filled-tonal-button'><AddRegular fontSize={24} />Agregar Poliza</button>
                </fieldset>
            </form>

        </div>
    )
}


function PolizaRow(props) {
    const [enabled, setEnabled] = useState(false);

    const [id, setId] = useState(props.id);
    const [monto, setMonto] = useState(props.monto);
    const [deduccion, setDeduccion] = useState(props.deduccion);
    const [active, setActive] = useState(props.active);

    useEffect(() => {
        console.log(active)
    }, [active])

    const updateDocument = async () => {
        try {
            await updateDoc(doc(db, "polizas", props.document), {
                id: id,
                monto: monto,
                deduccion: deduccion,
                active: active
            });
            setEnabled(false)
        } catch (error) {
            alert(error.code);
        }
    }

    return (
        <tbody>
            <tr>
                <td><button className="icon-button" onClick={() => setEnabled(!enabled)}> {enabled ? <DismissRegular fontSize={24} /> : <EditRegular fontSize={24} />}</button></td>
                <td><code>{props.document}</code></td>
                <td>
                    {
                        enabled ?
                            <div className='input-container'>
                                <input className='input-normal' id={document + ' ' + 'id'} type="text" value={id} onChange={e => setId(e.target.value)} />
                            </div> :
                            props.id
                    }
                </td>
                <td>
                    {
                        enabled ?
                            <div className='input-container'>
                                <input className='input-normal' id={document + ' ' + 'monto'} type="number" value={monto} onChange={e => setMonto(e.target.value)} />
                            </div> :
                            props.monto
                    }
                </td>
                <td>
                    {
                        enabled ?
                            <div className='input-container'>
                                <input className='input-normal' id={document + ' ' + 'deduccion'} type="number" value={deduccion} onChange={e => setDeduccion(e.target.value)} />
                            </div> :
                            props.deduccion
                    }
                </td>
                <td>
                    {
                        enabled ?
                            <button className={(active) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => { setActive(!active) }}>
                                <div className={(active) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                            </button> :
                            <button className={(props.active) ? "toggle-switch-on" : "toggle-switch-off"}>
                                <div className={(props.active) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                            </button>
                    }
                </td>
                <td>
                    {
                        enabled && <button className="btn-icon" onClick={updateDocument}><CheckmarkRegular fontSize={24} /></button>
                    }
                </td>
            </tr>
        </tbody>
    );
}
