import './ModalNavigationDrawer.css';
import {
    HomeRegular,
    HomeFilled,
    DocumentAddRegular,
    DocumentAddFilled,
    AppsListRegular,
    AppsListFilled,
    InfoRegular,
    InfoFilled,
    SignOutRegular
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";
import { useEffect } from 'react';


export default function ModalNavigationDrawer() {

    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', window.innerHeight + 'px');
        }
        window.addEventListener('resize', appHeight);
        appHeight();
    }, []);

    const close = e => {
        if (e.target.id === "scrim") {
            document.getElementById('modal-navigation-drawer').classList.remove("modal-navigation-drawer-opened");
            document.getElementById('scrim').classList.remove("scrim-visible");
        }
    }

    const closeOnLink = e => {
        document.getElementById('modal-navigation-drawer').classList.remove("modal-navigation-drawer-opened");
        document.getElementById('scrim').classList.remove("scrim-visible");
    }

    return (

        <div className='scrim' id="scrim" onClick={close}>
            <nav className='modal-navigation-drawer' id="modal-navigation-drawer">

                <CustomLink
                    to="/"
                    state={{ title: 'enotar.io' }}
                    icon={<HomeRegular fontSize={24} />}
                    activeicon={<HomeFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Home
                </CustomLink>

                <CustomLink
                    to="register"
                    state={{ title: 'Register' }}
                    icon={<DocumentAddRegular fontSize={24} />}
                    activeicon={<DocumentAddFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Register
                </CustomLink>

                <CustomLink
                    to="records"
                    state={{ title: 'Records' }}
                    icon={<AppsListRegular fontSize={24} />}
                    activeicon={<AppsListFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Records
                </CustomLink>

                <CustomLink
                    to="help"
                    state={{ title: 'Help' }}
                    icon={<InfoRegular fontSize={24} />}
                    activeicon={<InfoFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Help
                </CustomLink>

            </nav>
        </div>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className={match ? "navigation-drawer-item navigation-drawer-item-match" : "navigation-drawer-item"}>
            {match ? props.activeicon : props.icon}
            {children}
        </Link>
    );
}