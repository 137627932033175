import { getFirestore, query, collection, orderBy, limit, getDocs, onSnapshot, doc, deleteDoc, Timestamp, addDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useEffect, useRef, useState } from 'react';
import { ShareRegular, ThumbLikeRegular, ArrowUploadRegular, DeleteRegular } from "@fluentui/react-icons";
import './Convenios.css';

const db = getFirestore();

export default function Convenios() {
    const [newsList, setNewsList] = useState([]);
    const [errorMessage, setErrorMesage] = useState('');

    const [brand, setBrand] = useState('');
    const [promo, setPromo] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "agreements"), orderBy("promo", "desc"));
            onSnapshot(q, (querySnapshot) => {
                var array = [];
                querySnapshot.forEach((doc) => {
                    array.push({
                        id: doc.id,
                        brand: doc.data().brand,
                        promo: doc.data().promo,
                        description: doc.data().description,
                        shortDescription: doc.data().shortDescription,
                        logo: doc.data().logo,
                        phone: doc.data().phone,
                    });
                });
                console.log(array);
                setNewsList(array);
            });
        }
        fetchData();
    }, []);

    const createNew = async () => {
        setErrorMesage('');
        try {
            const docRef = await addDoc(collection(db, "agreements"), {
                brand: brand,
                promo: promo,
                description: description,
                shortDescription: shortDescription,
                logo: "",
                phone: phone,
                timestamp: Timestamp.now()
            });
            console.log("Document written with ID: ", docRef.id);
            setBrand('');
            setPromo('');
            setDescription('');
            setShortDescription('');
            setPhone('');
        } catch (error) {
            console.log(error);
            setErrorMesage('Code N01: Hubo un error en la creacion del documento.');
        }
    }




    return (
        <div className="page">
            <h1>Convenios</h1>

            <div className="convenio-form-container">

                <div className="convenio-form-grid">
                    <div className='input-container'>
                        <input className='input-normal' id="profile-celular" type="text" value={brand} onChange={e => setBrand(e.target.value)} />
                        <label className='input-label' htmlFor='profile-celular'>Marca</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-celular" type="text" value={promo} onChange={e => setPromo(e.target.value)} />
                        <label className='input-label' htmlFor='profile-celular'>Promocion</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-celular" type="text" value={shortDescription} onChange={e => setShortDescription(e.target.value)} />
                        <label className='input-label' htmlFor='profile-celular'>Descripcion corta</label>
                    </div>

                    <div className='input-container'>
                        <input className='input-normal' id="profile-celular" type="text" value={phone} onChange={e => setPhone(e.target.value)} />
                        <label className='input-label' htmlFor='profile-celular'>Telefono</label>
                    </div>

                </div>


                <h4>Descripcion</h4>

                <div className='input-container'>
                    <textarea id="w3review" style={{ width: '100%' }} name="w3review" rows="6" cols="50" value={description} onChange={e => setDescription(e.target.value)} />
                </div>

                <div>
                    <button style={{ marginLeft: 'auto' }} className="filled-button" onClick={createNew}>Post</button>
                </div>



                <p style={{ color: 'var(--color-error)' }}>{errorMessage}</p>
            </div>




            <h3>Todos los convenios</h3>
            {
                newsList.map(data =>
                    <Convenio
                        key={data.id}
                        id={data.id}
                        logo={data.logo}
                        brand={data.brand}
                        promo={data.promo}
                        shortDescription={data.shortDescription}
                        description={data.description}
                        phone={data.phone}
                    />
                )
            }

        </div>
    )
}

function Convenio({ id, logo, brand, promo, shortDescription, description, phone }) {

    const inputFile = useRef(null);

    function handleChange(event) {
        var file = event.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, 'agreements/' + id + '/logo.jpg');

        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            getDownloadURL(ref(storage, snapshot.metadata.fullPath)).then(async url => {
                await updateDoc(doc(db, "agreements", id), {
                    logo: url
                });
                console.log('URL', url);
            })
        });
    }

    const deleteConvenio = async () => {
        console.log(id);
        if (window.confirm("¿Estás seguro/a que quieres borrar esto?")) {
            try {
                const docRef = await deleteDoc(doc(db, "agreements", id));
                console.log('Document deleted', docRef)
            } catch (error) {
                console.log('Error en borrar documento', error)
            }

        }
    }

    return (
        <div className="convenio-card" key={id}>
            <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleChange} />
            {
                logo ?
                    <img className="convenio-logo" src={logo} /> :
                    <button className='convenio-logo-upload' onClick={() => inputFile.current.click()}>
                        <ArrowUploadRegular fontSize={24} />
                        <p><small>Upload an image</small></p>
                    </button>
            }
            <h3>{brand}</h3>
            <h4>{promo}</h4>
            <h4>Descripcion corta</h4>
            <p>{shortDescription}</p>
            <h4>Descripcion</h4>
            <p>{description}</p>
            <h4>{phone}</h4>

            <button className='icon-button convenio-delete-button' onClick={deleteConvenio}>
                <DeleteRegular fontSize={24} />
            </button>
        </div>
    );
}