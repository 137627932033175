import { LinkRegular, SaveRegular } from '@fluentui/react-icons';
import { doc, getDoc, getFirestore, onSnapshot, Timestamp, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './CasoPoliza.css';
import gDriveIcon from '../assets/google-drive.png';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const db = getFirestore();

export default function CasoPoliza() {
    const [initialObj, setInitialObj] = useState({});

    ///////// CASO /////////
    const [creationDate, setCreationDate] = useState('');
    // L Linked
    const [lCedula, setLCedula] = useState('');
    // Q Quien
    const [qCedula, setQCedula] = useState('');
    const [qNombre, setQnombre] = useState('');
    const [qApellidos, setQApellidos] = useState('');
    const [qParentesco, setQParentesco] = useState('');
    const [qTelefono, setQtelefono] = useState('');
    const [qEmail, setQEmail] = useState('');
    // F Fallecido
    const [fCedula, setFCedula] = useState('');
    const [fNombre, setFnombre] = useState('');
    const [fApellidos, setFApellidos] = useState('');
    const [fechaReclamo, setFechaReclamo] = useState('');
    const [sobreFallecido, setSobreFallecido] = useState('');
    const [fechaFallecimiento, setFechaFallecimiento] = useState('');
    // Causa
    const [causaPrimaria, setCausaPrimaria] = useState('');
    const [causaSecundaria, setCausaSecundaria] = useState('');
    // Pago
    const [referencia, setReferencia] = useState('');
    const [fechaPago, setFechaPago] = useState('');
    const [montoIndemnizacionINS, setMontoIndemnizacionINS] = useState('');
    const [montoIndemnizacionANEP, setMontoIndemnizacionANEP] = useState('');
    const [tipoPago, setTipoPago] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [ampo, setAmpo] = useState("");
    // Observaciones
    const [observaciones, setObservaciones] = useState('');
    // Estado
    const [estado, setEstado] = useState('');
    ///////// CASO /////////
    // GOOGLE DRIVE FILE ID
    const [driveFileID, setDriveFileID] = useState('');
    const [localeDriveFileID, setLocalDriveFileID] = useState('');

    // Troubleshoot
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    let que = useQuery();
    const caso = que.get("id");


    // INITIAL

    useEffect(() => {
        async function fetchData() {
            onSnapshot(doc(db, "casos", caso), (doc) => {
                var data = doc.data();
                var birth = data.created && new Date(data.created.seconds * 1000);
                var parsedBirth = data.created && birth.getFullYear() + '-' + ('0' + (birth.getMonth() + 1)).slice(-2) + '-' + ('0' + birth.getDate()).slice(-2);

                var pago = data.fechaPago && new Date(data.fechaPago.seconds * 1000);
                var parsedPago = data.fechaPago && pago.getFullYear() + '-' + ('0' + (pago.getMonth() + 1)).slice(-2) + '-' + ('0' + pago.getDate()).slice(-2);

                var reclamo = data.fechaReclamo && new Date(data.fechaReclamo.seconds * 1000);
                var parsedReclamo = data.fechaReclamo && reclamo.getFullYear() + '-' + ('0' + (reclamo.getMonth() + 1)).slice(-2) + '-' + ('0' + reclamo.getDate()).slice(-2);

                var fallecimiento = data.fechaFallecimiento && new Date(data.fechaFallecimiento.seconds * 1000);
                var parsedFallecimiento = data.fechaFallecimiento && fallecimiento.getFullYear() + '-' + ('0' + (fallecimiento.getMonth() + 1)).slice(-2) + '-' + ('0' + fallecimiento.getDate()).slice(-2);

                // console.log(data.driveFileID)
                setDriveFileID(data.driveFileID);
                setLocalDriveFileID(data.driveFileID);
                setInitialObj({
                    linked: data.linked,
                    created: parsedBirth,
                    qCedula: data.qCedula,
                    qNombre: data.qNombre,
                    qApellidos: data.qApellidos,
                    qParentesco: data.qParentesco,
                    qTelefono: data.qTelefono,
                    qEmail: data.qEmail,
                    sobreFallecido: data.sobreFallecido,
                    fCedula: data.fCedula,
                    fNombre: data.fNombre,
                    fApellidos: data.fApellidos,
                    fechaFallecimiento: parsedFallecimiento,
                    fechaReclamo: parsedReclamo,
                    causaPrimaria: data.causaPrimaria,
                    causaSecundaria: data.causaSecundaria,
                    referencia: data.referencia,
                    fechaPago: parsedPago,
                    montoIndemnizacionINS: data.montoIndemnizacionINS,
                    montoIndemnizacionANEP: data.montoIndemnizacionANEP,
                    tipoPago: data.tipoPago,
                    numeroDocumento: data.numeroDocumento,
                    ampo: data.ampo,
                    observaciones: observaciones,
                    estado: data.estado,
                });
            });
        }
        fetchData();
    }, []);

    // INITIAL END

    // GET DOC

    useEffect(() => {

        async function fetchData() {
            const docRef = doc(db, "casos", caso);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                var data = docSnap.data();
                var created = new Date(data.created.seconds * 1000).toLocaleString();
                var pago = data.fechaPago && new Date(data.fechaPago.seconds * 1000);
                var parsedPago = data.fechaPago && pago.getFullYear() + '-' + ('0' + (pago.getMonth() + 1)).slice(-2) + '-' + ('0' + pago.getDate()).slice(-2);
                var reclamo = data.fechaReclamo && new Date(data.fechaReclamo.seconds * 1000);
                var parsedReclamo = data.fechaReclamo && reclamo.getFullYear() + '-' + ('0' + (reclamo.getMonth() + 1)).slice(-2) + '-' + ('0' + reclamo.getDate()).slice(-2);

                var fallecimiento = data.fechaFallecimiento && new Date(data.fechaFallecimiento.seconds * 1000);
                var parsedFallecimiento = data.fechaFallecimiento && fallecimiento.getFullYear() + '-' + ('0' + (fallecimiento.getMonth() + 1)).slice(-2) + '-' + ('0' + fallecimiento.getDate()).slice(-2);

                console.log(data);
                setCreationDate(created);
                // L
                setLCedula(data.linked);
                // Q
                setQCedula(data.qCedula);
                setQnombre(data.qNombre);
                setQApellidos(data.qApellidos);
                setQParentesco(data.qParentesco);
                setQtelefono(data.qTelefono);
                setQEmail(data.qEmail);
                // F
                setFCedula(data.fCedula);
                setFnombre(data.fNombre);
                setFApellidos(data.fApellidos);
                setFechaReclamo(parsedReclamo);
                setSobreFallecido(data.sobreFallecido);
                setFechaFallecimiento(parsedFallecimiento);
                // Causa
                setCausaPrimaria(data.causaPrimaria);
                setCausaSecundaria(data.causaSecundaria);
                // INS
                setMontoIndemnizacionINS(data.montoIndemnizacionINS);
                setReferencia(data.referencia);
                setFechaPago(parsedPago);
                // ANEP
                setMontoIndemnizacionANEP(data.montoIndemnizacionANEP);
                setNumeroDocumento(data.numeroDocumento);
                setAmpo(data.ampo);
                setTipoPago(data.tipoPago);
                // ESTADO
                setEstado(data.estado);


            } else {
                console.log("No such document!");
                alert("No se encontro ningun asociado con esta cedula.")
            }

        }

        fetchData();

    }, []);

    // GET DOC END

    const editTextField = async (field, value) => {
        setErrorMsg('');
        try {
            const userRef = doc(db, "casos", caso);

            var obj = {};
            obj[field] = value;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            setErrorMsg('Hubo un error al actualizar este dato');
        }
    }

    const editDateField = async (field, value) => {
        setErrorMsg('');
        try {
            const userRef = doc(db, "casos", caso);

            var obj = {};

            var d = new Date(value);
            d.setDate(d.getDate() + 1);
            obj[field] = Timestamp.fromDate(d);;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
            setErrorMsg('Hubo un error al actualizar este dato');
        }
    }

    return (
        <div className='page'>
            <div className='caso-poliza-header'>
                <h1 style={{ margin: '0' }}>Caso</h1>
                <p>{caso}</p>
                <p>{creationDate}</p>
            </div>

            <h4 style={{ margin: '1rem 0', display: 'flex', gap: '0.5rem' }}><LinkRegular fontSize={24} />Afiliado vinculado</h4>

            <EditableTextField label='Afiliado Vinculado' field='linked' value={lCedula} onChange={(newValue) => { setLCedula(newValue) }} initialObj={initialObj} id={caso} />

            <h4 style={{ margin: '1rem 0' }}>Quien apertura</h4>

            <div className="caso-form-grid">
                <EditableTextField label='Cedula' field='qCedula' value={qCedula} onChange={(newValue) => { setQCedula(newValue) }} initialObj={initialObj} id={caso} />
                <EditableTextField label='Nombre' field='qNombre' value={qNombre} onChange={(newValue) => { setQnombre(newValue) }} initialObj={initialObj} id={caso} />
                <EditableTextField label='Apellidos' field='qApellidos' value={qApellidos} onChange={(newValue) => { setQApellidos(newValue) }} initialObj={initialObj} id={caso} />

                <div className='input-container'>
                    <select id="caso-sobre-fallecido" value={qParentesco} onChange={e => setQParentesco(e.target.value)}>
                        <option value="">---Parentesco---</option>
                        <option value="PADRE">PADRE</option>
                        <option value="MADRE">MADRE</option>
                        <option value="HERMANO/A">HERMANO/A</option>
                        <option value="HIJO/A">HIJO/A</option>
                        <option value="CONYUGE">CONYUGE</option>
                        <option value="OTROS">OTROS</option>
                    </select>
                    {
                        (initialObj.qParentesco !== qParentesco) &&
                        <button className='input-save-button' onClick={() => { editTextField('qParentesco', qParentesco) }}>
                            <SaveRegular fontSize={24} />
                        </button>
                    }
                </div>

                <EditableTextField label='Telefono' field='qTelefono' value={qTelefono} onChange={(newValue) => { setQtelefono(newValue) }} initialObj={initialObj} id={caso} />
                <EditableTextField label='Email' field='qEmail' value={qEmail} onChange={(newValue) => { setQEmail(newValue) }} initialObj={initialObj} id={caso} />
            </div>

            <h4 style={{ margin: '1rem 0' }}>Fallecido</h4>


            <div className="caso-form-grid">

                <div className='input-container'>
                    <select id="caso-sobre-fallecido" value={sobreFallecido} onChange={e => setSobreFallecido(e.target.value)}>
                        <option value="">---Sobre fallecido---</option>
                        <option value="TITULAR">Titular</option>
                        <option value="CONYGUE">Conyugue</option>
                        <option value="HIJOS">Hijos</option>
                        <option value="TERMINAL">Enfermedad terminal</option>
                    </select>
                    {
                        (initialObj.sobreFallecido !== sobreFallecido) &&
                        <button className='input-save-button' onClick={() => { editTextField('sobreFallecido', sobreFallecido) }}>
                            <SaveRegular fontSize={24} />
                        </button>
                    }
                </div>

                <EditableTextField label='Cedula' field='fCedula' value={fCedula} onChange={(newValue) => { setFCedula(newValue) }} initialObj={initialObj} id={caso} />
                <EditableTextField label='Nombre' field='fNombre' value={fNombre} onChange={(newValue) => { setFnombre(newValue) }} initialObj={initialObj} id={caso} />
                <EditableTextField label='Apellidos' field='fApellidos' value={fApellidos} onChange={(newValue) => { setFApellidos(newValue) }} initialObj={initialObj} id={caso} />

                <div className='input-container'>
                    <input className='input-normal' id="caso-fechaFallecimiento" type="date" value={fechaFallecimiento} onChange={e => setFechaFallecimiento(e.target.value)} />
                    <label className='input-label' htmlFor='caso-fechaFallecimiento'>Fecha de fallecimiento</label>
                    {
                        (initialObj.fechaFallecimiento !== fechaFallecimiento) &&
                        <button style={{ right: '2.25rem' }} className='input-save-button' onClick={() => { editDateField('fechaFallecimiento', fechaFallecimiento) }}>
                            <SaveRegular fontSize={24} />
                        </button>
                    }
                </div>
            </div>

            <h4 style={{ margin: '1rem 0' }}>Causa de fallecimiento</h4>

            <div className="caso-form-grid">
                <EditableTextField label='Causa de muerte Primaria' field='causaPrimaria' value={causaPrimaria} onChange={(newValue) => { setCausaPrimaria(newValue) }} initialObj={initialObj} id={caso} />
                <EditableTextField label='Causa de muerte Secundaria' field='causaSecundaria' value={causaSecundaria} onChange={(newValue) => { setCausaSecundaria(newValue) }} initialObj={initialObj} id={caso} />
            </div>

            <h4 style={{ margin: '1rem 0' }}>INS</h4>

            <div className="caso-form-grid">
                <EditableTextField label='Numero de referencia pago' field='referencia' value={referencia} onChange={(newValue) => { setReferencia(newValue) }} initialObj={initialObj} id={caso} />

                <div className='input-container'>
                    <input className='input-normal' id="caso-fechaPago" type="date" value={fechaPago} onChange={e => { setFechaPago(e.target.value.toLocaleUpperCase()) }} />
                    <label className='input-label' htmlFor='caso-fechaPago'>Fecha pago INS</label>
                    {
                        (initialObj.fechaPago !== fechaPago) &&
                        <button style={{ right: '2.25rem' }} className='input-save-button' onClick={() => { editDateField('fechaPago', fechaPago) }}>
                            <SaveRegular fontSize={24} />
                        </button>
                    }
                </div>

                {/* <div className='input-container'>
                    <input className='input-normal' id="caso-fechaPago" type="date" value={fechaPago} onChange={e => setFechaPago(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor='caso-fechaPago'>Fecha pago INS</label>
                </div> */}

                <EditableTextField label='Monto indemnizacion INS' field='montoIndemnizacionINS' value={montoIndemnizacionINS} onChange={(newValue) => { setMontoIndemnizacionINS(newValue) }} initialObj={initialObj} id={caso} />
            </div>

            <h4 style={{ margin: '1rem 0' }}>ANEP</h4>

            <div className="caso-form-grid">
                <EditableTextField label='Monto indemnizacion ANEP' field='montoIndemnizacionANEP' value={montoIndemnizacionANEP} onChange={(newValue) => { setMontoIndemnizacionANEP(newValue) }} initialObj={initialObj} id={caso} />

                <div className='input-container'>
                    <select id="caso-tipo-pago" value={tipoPago} onChange={e => setTipoPago(e.target.value)}>
                        <option value="">---Selecciona un Tipo de pago---</option>
                        <option value="M">Cheque</option>
                        <option value="F">Transferencia</option>
                        <option value="D">Deposito</option>
                    </select>
                    {
                        (initialObj.tipoPago !== tipoPago) &&
                        <button className='input-save-button' onClick={() => { editTextField('tipoPago', tipoPago) }}>
                            <SaveRegular fontSize={24} />
                        </button>
                    }
                </div>

                <EditableTextField label='Numero de Documento' field='numeroDocumento' value={numeroDocumento} onChange={(newValue) => { setNumeroDocumento(newValue) }} initialObj={initialObj} id={caso} />

                <EditableTextField label='Ampo' field='ampo' value={ampo} onChange={(newValue) => { setAmpo(newValue) }} initialObj={initialObj} id={caso} />
            </div>

            <h4 style={{ margin: '1rem 0' }}>Estado caso</h4>

            <div className="caso-form-grid">

                <div className='input-container'>
                    <select id="caso-estado" value={estado} onChange={e => setEstado(e.target.value)}>
                        <option value="">---Selecciona un Estado de Caso---</option>
                        <option value="CASO EN TRAMITE">CASO EN TRAMITE</option>
                        <option value="CASO EN INS">CASO EN INS</option>
                        <option value="PAGO PENDIENTE">PAGO PENDIENTE</option>
                        <option value="SUCESORIO">SUCESORIO</option>
                        <option value="FINALIZADO">FINALIZADO</option>
                    </select>
                    {
                        (initialObj.estado !== estado) &&
                        <button className='input-save-button' onClick={() => { editTextField('estado', estado) }}>
                            <SaveRegular fontSize={24} />
                        </button>
                    }

                </div>
            </div>


            <h4 style={{ margin: '1rem 0' }}>Observaciones</h4>

            <div className='input-container'>
                <textarea id="w3review" style={{ width: '100%' }} name="w3review" rows="6" cols="50" value={observaciones} onChange={e => { setObservaciones(e.target.value) }} />
            </div>

            {
                driveFileID &&
                <iframe src={"https://drive.google.com/file/d/" + driveFileID + "/preview"} style={{ border: 'none' }} width="100%" height="480" allow="autoplay"></iframe>
            }

            <div className='input-container' style={{ marginTop: '1rem', maxWidth: '45ch' }}>
                <input className='input-normal' id="profile-celular" type="text" value={localeDriveFileID} onChange={e => setLocalDriveFileID(e.target.value)} />
                <label className='input-label' htmlFor='profile-celular'>Google Drive File ID</label>
                <img src={gDriveIcon} style={{ position: 'absolute', right: '-2rem', top: '0.75rem', height: '24px' }} />

                {
                    localeDriveFileID !== driveFileID &&
                    <button className='input-save-button' onClick={() => { editTextField('driveFileID', localeDriveFileID) }}>
                        <SaveRegular fontSize={24} />
                    </button>
                }
            </div>

            <p style={{ marginTop: '0.5rem' }}><b>Ej: </b>https://drive.google.com/file/d/<b>0B-eis5c3lHOhY2ZzOGZlaEhfR</b>/preview</p>

        </div>
    );
}


function EditableTextField({ field, value, onChange, label, initialObj, id }) {

    const editTextField = async (field, value) => {
        try {
            const userRef = doc(db, "casos", id.toString());

            var obj = {};
            obj[field] = value;

            await updateDoc(userRef, obj);
            console.log('Creado con exito');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='input-container'>
            <input className='input-normal' id={"caso-" + label} type="text" value={value} onChange={e => { onChange(e.target.value.toLocaleUpperCase()) }} />
            <label className='input-label' htmlFor={"caso-" + label}>{label}</label>
            {
                (initialObj[field] !== value) &&
                <button className='input-save-button' onClick={() => { editTextField(field, value) }}>
                    <SaveRegular fontSize={24} />
                </button>
            }
        </div>
    );
}