import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// AUTHENTICATE
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { UserContext, UserProvider } from './UserContext';
import SignUp from './routes/SignUp';

function Root() {
  const { authState } = useContext(UserContext);
  return (
    <Routes>
      <Route path='*' element={
        authState ?
          <App /> :
          <Loading />
      } />
      <Route path='/signup' element={<SignUp />} />
    </Routes>
  );
}

function Loading() {
  const { authState } = useContext(UserContext);
  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
  if (authState === false) { return <Navigate to="/signup" replace={true} />; }
  return (
    <div style={styles}>
      <h1>Autenticando...</h1>
    </div>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <Root />
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
