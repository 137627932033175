import { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc, getDocs, query, where, orderBy, limit, startAfter, getDoc, doc, Timestamp } from "firebase/firestore";
import { parseMuniSanJose, parseAya, parseCcss, parseHacienda, parseContraloria, parseCenCinai, parseJPS, parseImas, parseIct, parseSinac, parseMd, parseCd } from '../funciones.js';
import { ClockRegular, CheckRegular } from "@fluentui/react-icons";
import './Report.css';
import xlsx from '../assets/xlsx.svg';

// const firebaseApp = initializeApp({
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
// });

const db = getFirestore();

export default function Report() {
  const [dataList, setDataList] = useState([]);
  const [generalDataList, setGeneralDataList] = useState([]);
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [lastV, setLastV] = useState();
  const [searchCriteria, setSearchCriteria] = useState('institucion');
  // const [searchValue, setSearchValue] = useState('ANEP');
  const [searchValue, setSearchValue] = useState('SERVICIOS AGRICOLAS PALMA TICA');

  // const [searchValue, setSearchValue] = useState('CCSS');
  const [listaInstituciones, setListaInstituciones] = useState([]);

  // INTEGRA MD
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');
  // MUNI SAN JOSE
  const [muniSanJosefecha, setMuniSanJoseFecha] = useState('2022-01');
  // A Y A 
  const [periodoAya, setPeriodoAya] = useState('');
  const [rigeDesdeAya, setRigeDesdeAya] = useState('');
  // CCSS
  const [rigeDesdeCcss, SetRigeDesdeCcss] = useState('');
  const [rigeHastaCcss, SetRigeHastaCcss] = useState('');
  // HACIENDA
  const [fechaFormalizacion, setfechaFormalizacion] = useState('');
  // CENCINAI
  const [fechaDesdeCinai, setFechaDesdeCinai] = useState('');
  const [fechaHastaCinai, setFechaHastaCinai] = useState('');
  // JPS
  const [periodoJps, setPeriodoJps] = useState('');
  // IMAS 
  const [periodoImas, setPeriodoImas] = useState('');

  // POLIZAS
  const [polizasList, setPolizasList] = useState([]);



  const objectToCSVRow = (dataObject) => {
    var dataArray = new Array;
    for (var o in dataObject) {
      var innerValue = dataObject[o] === null ? '' : dataObject[o].toString();
      var result = innerValue.replace(/"/g, '""');
      result = '"' + result + '"';
      dataArray.push(result);
    }
    return dataArray.join(' ') + '\r\n';
  }

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(db, "instituciones", "lista");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data().instituciones);
        setListaInstituciones(docSnap.data().instituciones.sort());
      } else {
        console.log("No such document!");
      }
    }
    fetchData();
  }, []);

  const instList = listaInstituciones.map(data =>
    <option key={data} value={data}>{data}</option>
  )

  const getEveryone = async () => {
    setLoadingGeneral(true);
    setDataList([]);
    try {
      const q = query(collection(db, "usuarios"), orderBy("apellidos", "asc"));
      // const q = query(collection(db, "usuarios"));

      const querySnapshot = await getDocs(q);
      var array = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        var provincia = (typeof (data.provincia) === 'undefined') ? '' : data.provincia;
        var canton = (typeof (data.provincia) === 'undefined') ? '' : data.canton;
        var distrito = (typeof (data.provincia) === 'undefined') ? '' : data.distrito;
        var ingreso = data.ingreso ? new Date(data.ingreso.seconds * 1000).toLocaleDateString() : '';
        var out = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000).toLocaleDateString() : '';
        var birth = data.nacimiento ? new Date(data.nacimiento.seconds * 1000).toLocaleDateString() : '';

        // FECHA AFILIACION
        // FECHA DESAFILIACION
        // NUMERO REFERENCIA
        var dataObject = {
          key: doc.id,
          id: data.id,
          cedula: data.id,
          // name: data.nombre,
          lastname: data.apellidos,
          nombre: data.nombre,
          institucion: data.institucion,
          dependencia: data.dependencia,
          departamento: data.departamento,
          puesto: data.puesto,
          fechaAfiliacion: ingreso,
          fechaDesafiliacion: out,
          email: data.email,
          tel_casa: data.contacto1,
          tel_celular: data.contacto2,
          tel_oficina: data.contacto3,
          estado: data.active ? 'ACTIVO' : 'INACTIVO',
          referencia: data.referencia,
          fechaNacimiento: birth,
          nacionalidad: data.nacionalidad,
          provincia: provincia,
          canton: canton,
          distrito: distrito,
          sector: data.sector,
          plaza: data.plaza,
          // poliza: data.poliza,
          observaciones: data.observaciones.replace(/(?:\r\n|\r|\n)/g, " "),
          genero: data.genero,
          promotor: data.promotor
        }

        array.push(dataObject);
      });
      console.log(array);

      setGeneralDataList(array);
      setLoadingGeneral(false);
    } catch (error) {
      console.log(error);
      setLoadingGeneral(false);
    }

  }

  const search = async e => {
    e.preventDefault();

    try {
      const value = (searchCriteria === 'id') ? parseInt(searchValue) : searchValue;

      // const q = query(collection(db, "usuarios"), where("active", "==", true), where(searchCriteria, "==", value), orderBy("ingreso", "desc"));
      const q = query(collection(db, "usuarios"), where(searchCriteria, "==", value), orderBy("apellidos", "asc"));
      // const q = query(collection(db, "usuarios"), where(searchCriteria, "==", value));

      const querySnapshot = await getDocs(q);

      var array = [];

      querySnapshot.forEach((doc) => {
        var data = doc.data();

        var startDate = new Date(data.ingreso.seconds * 1000);
        var expireDate = new Date(data.expira.seconds * 1000);
        var birthDate = data.nacimiento ? new Date(data.nacimiento.seconds * 1000).toLocaleDateString() : '';
        console.log(birthDate);
        var nowDate = Date.now();
        var isActive = expireDate.getTime() > nowDate;

        var out = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000).toLocaleDateString() : '';

        var provincia = (typeof (data.provincia) === 'undefined') ? '' : data.provincia;
        var canton = (typeof (data.provincia) === 'undefined') ? '' : data.canton;
        var distrito = (typeof (data.provincia) === 'undefined') ? '' : data.distrito;

        console.log(provincia);

        var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

        var dataObject = {
          key: doc.id,
          id: data.id,
          cedula: data.id,
          lastname: data.apellidos,
          nombre: data.nombre,
          institucion: data.institucion,
          dependencia: data.dependencia,
          departamento: data.departamento,
          puesto: data.puesto,
          fechaAfiliacion: parsedStartDate,
          fechaDesafiliacion: out,
          email: data.email,
          tel_casa: data.contacto1,
          tel_celular: data.contacto2,
          tel_oficina: data.contacto3,
          estado: data.active ? 'ACTIVO' : 'INACTIVO',
          referencia: data.referencia,
          nacimiento: birthDate,
          pago_al_dia: isActive,
          fechExpiracion: expireDate.toLocaleDateString(),
          nacionalidad: data.nacionalidad,
          provincia: provincia,
          canton: canton,
          distrito: distrito,
          sector: data.sector,
          plaza: data.plaza,
          // poliza: data.poliza,
          observaciones: data.observaciones.replace(/(?:\r\n|\r|\n)/g, " "),
          genero: data.genero,
          promotor: data.promotor
        }

        array.push(dataObject);

        // array.forEach(async element => {

        //   polizasList.forEach(async ele => {
        //     // console.log('KEY => ', ele.key);
        //     const docRef = doc(db, "polizas", ele.key);
        //     const docSnap = await getDoc(docRef);

        //     if (docSnap.exists()) {
        //       console.log("Document data:", docSnap.data());
        //     } else {
        //       // doc.data() will be undefined in this case
        //       console.log("No such document!");
        //     }
        //   });

        // });
      });
    } catch (error) {
      console.log(error);
    }

    setDataList(array);
  }

  var list = dataList.map(data =>
    <tbody key={data.key}>
      <tr>
        <td>{data.id}</td>
        <td>{
          data.pago_al_dia ?
            <CheckRegular fontSize={24} /> :
            <ClockRegular fontSize={24} />
        }</td>

        <td>{data.nombre}</td>
        <td>{data.lastname}</td>
        <td>{data.institucion}</td>
        <td>{data.dependencia}</td>
        <td>{data.departamento}</td>
        <td>{data.puesto}</td>
        <td>{data.nacimiento}</td>
        <td>{data.fechaAfiliacion}</td>
        <td>{data.email}</td>
        <td>{data.tel_casa}</td>
        <td>{data.tel_celular}</td>
        <td>{data.tel_oficina}</td>
        {/* <td>{"₡" + data.salario}</td>
          <td>{"₡" + data.cuota}</td> */}
        <td>{data.fechExpiracion}</td>
        <td>{data.provincia}</td>
        <td>{data.canton}</td>
        <td>{data.distrito}</td>
        <td><Link to={{ pathname: '/profile', search: '?id=' + data.id }}>Ver perfil</Link></td>
      </tr>
    </tbody>
  );

  const generateNormal = () => {
    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvContent = "";

    dataList.forEach((element, index) => {
      var objLength = Object.keys(element).length;
      var row = "";

      if (index === 0) {
        var header = Object.keys(element).join(';');
        csvContent += header + "\r\n";
      }

      var i = 0;

      for (const property in element) {
        var propiedad = element[property] ? element[property].toString() : '';

        i++
        if (i !== objLength) {
          row += propiedad + ";";
        } else {
          row += propiedad;
        }
      }

      csvContent += row + "\r\n";

    });

    saveSomeData(csvContent);
  }

  function saveFile(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.charset = 'utf-8';
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    }
  }

  // function saveSomeData(string) {
  //   const blob = new Blob([string], {
  //     type: 'text/csv;charset=utf-8'
  //   });
  //   saveFile(blob, "datafile.csv")
  // }

  function saveSomeData(string) {
    const csvContent = "\uFEFF" + string; // Agrega el BOM (Byte Order Mark) para indicar que el archivo está en formato UTF-8
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
    saveFile(blob, "Reporte.csv");
  }
  

  const generateNormalGeneral = () => {
    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvContent = "";

    setLoadingGeneral(true);

    for (let index = 0; index < generalDataList.length; index++) {
      const element = generalDataList[index];
      var objLength = Object.keys(element).length;
      var row = "";

      if (index === 0) {
        var header = Object.keys(element).join(';');
        csvContent += header + "\r\n";
      }

      var i = 0;

      for (const property in element) {
        console.log(element[property]);

        var propiedad = element[property] ? element[property].toString() : '';

        i++
        if (i !== objLength) {
          row += propiedad + ";";
        } else {
          row += propiedad;
        }

      }
      csvContent += row + "\r\n";

    }

    saveSomeData(csvContent);
    setLoadingGeneral(false);
  }

  const generate = () => {
    switch (searchValue) {
      case 'MUNICIPALIDAD DE SAN JOSE':
        parseMuniSanJose(dataList, muniSanJosefecha);
        break;
      case 'A Y A':
        parseAya(dataList, periodoAya, rigeDesdeAya);
        break;
      case 'CCSS':
        parseCcss(dataList, rigeDesdeCcss, rigeHastaCcss);
        break;
      case 'MINISTERIO DE HACIENDA':
        parseHacienda(dataList, fechaFormalizacion);
        break;
      case 'INTEGRA':
        // parseMd(dataList, fechaFormalizacion);
        parseCd(dataList, fechaFormalizacion);
        break;
      case 'CONTRALORIA GENERAL DE LA REPUBLICA':
        parseContraloria(dataList);
        break;
      case 'DIRECCION NACIONAL DE CEN CINAI':
        parseCenCinai(dataList, fechaDesdeCinai, fechaHastaCinai);
        break;
      case 'JUNTA DE PROTECCION SOCIAL':
        parseJPS(dataList, periodoJps);
        break;
      case 'IMAS':
        parseImas(dataList, periodoImas);
        break;
      case 'ICT':
        parseIct(dataList);
        break;
      case 'SINAC MINAE':
        parseSinac(dataList);
        break;
      case 'AEMAI,SANTA ANA':
        // parseCd(dataList, fechaFormalizacion);
        parseMd(dataList, fechaFormalizacion);
        break;
      default:
        generateNormal(dataList);
        break;
    }
  }

  const generateIntegra = async () => {

    setSearchValue('INTEGRA');

    const q1 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'ASAMBLEA LEGISLATIVA')
    );

    const q2 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'DEFENSORIA DE LOS HABITANTES')
    );

    const q3 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'JUNTA DE PENSIONES MAGISTERIO NACIONAL')
    );

    const q4 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE AGRICULTURA Y GANADERIA')
    );

    const q5 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE CIENCIA Y TECNOLOGIA')
    );

    const q6 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE CULTURA Y JUVENTUD')
    );

    const q7 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE GOBERNACION Y POLICIA')
    );

    const q8 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE ECONOMIA INDUSTRIA Y COMERCIO')
    );
    const q9 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE EDUCACION PUBLICA')
    );
    const q10 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE HACIENDA')
    );
    const q11 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE JUSTICIA Y PAZ')
    );
    const q12 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE LA PRESIDENCIA')
    );
    const q13 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE OBRAS PUBLICAS Y TRANSPORTE')
    );
    const q14 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE RELACIONES EXTERIORES Y CULTO')
    );
    const q15 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE SALUD')
    );
    const q16 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE SEGURIDAD PUBLICA')
    );
    const q17 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE TRABAJO Y SEGURIDAD SOCIAL')
    );
    const q18 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE VIVIENDA Y ASENTAMIENTOS HUMANOS')
    );
    const q19 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'PRESIDENCIA DE LA REPUBLICA')
    );
    const q20 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'REGISTRO NACIONAL (PRESUPUESTO JUNTA ADMTIVA)')
    );
    const q21 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'TRIBUNAL SUPREMO ELECCIONES')
    );
    const q22 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'DIRECCION NACIONAL PENSIONES')
    );
    const q23 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'DIRECCION GENERAL DE MIGRACION Y EXTRANJERIA')
    );
    const q24 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'IMPRENTA NACIONAL')
    );



    var res = await Promise.all([
      getDocs(q1),
      getDocs(q2),
      getDocs(q3),
      getDocs(q4),
      getDocs(q5),
      getDocs(q6),
      getDocs(q7),
      getDocs(q8),
      getDocs(q9),
      getDocs(q10),
      getDocs(q11),
      getDocs(q12),
      getDocs(q13),
      getDocs(q14),
      getDocs(q15),
      getDocs(q16),
      getDocs(q17),
      getDocs(q18),
      getDocs(q19),
      getDocs(q20),
      getDocs(q21),
      getDocs(q22),
      getDocs(q23),
      getDocs(q24)
    ]);

    var array = []

    res.forEach(snap => {
      console.log(snap);
      snap.forEach(d => {
        // console.log(d.data());

        var data = d.data();

        var startDate = new Date(data.ingreso.seconds * 1000);
        var expireDate = new Date(data.expira.seconds * 1000);
        var nowDate = Date.now();
        var isActive = expireDate.getTime() > nowDate;

        var cuota = data.salario * 0.0125;

        var provincia = (typeof (data.provincia) === 'undefined') ? '' : data.provincia;
        var canton = (typeof (data.provincia) === 'undefined') ? '' : data.canton;
        var distrito = (typeof (data.provincia) === 'undefined') ? '' : data.distrito;

        var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

        // console.log(provincia);
        var dataObject = {
          key: doc.id,
          id: data.id,
          cedula: data.id,
          type: 'AFIL',
          // carne: data.carne,
          // name: data.nombre,
          nombre: data.nombre,
          lastname: data.apellidos,
          email: data.email,
          dependencia: data.dependencia,
          departamento: data.departamento,
          institucion: data.institucion,
          estado: isActive,
          // salario: data.salario,
          // cuota: cuota,
          fechaAfiliacion: parsedStartDate,
          fechExpiracion: expireDate.toLocaleDateString(),
          puesto: data.puesto,
          // telCel: data.telCel,
          provincia: provincia,
          canton: canton,
          distrito: distrito
        }
        array.push(dataObject);

      })
    });

    console.log(array);
    setDataList(array);
    // const querySnapshot = await getDocs(q1);

    // querySnapshot.forEach(d => {
    //   console.log(d.data());
    // })
  }

  const generateMD = async () => {

    setSearchValue('INTEGRA');

    var d = new Date(fechaDesde);
    var e = new Date(fechaHasta);

    const q1 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'ASAMBLEA LEGISLATIVA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q2 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'DEFENSORIA DE LOS HABITANTES'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q3 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'JUNTA DE PENSIONES MAGISTERIO NACIONAL'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q4 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE AGRICULTURA Y GANADERIA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q5 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE CIENCIA Y TECNOLOGIA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q6 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE CULTURA Y JUVENTUD'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q7 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE GOBERNACION Y POLICIA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );

    const q8 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE ECONOMIA INDUSTRIA Y COMERCIO'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q9 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE EDUCACION PUBLICA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q10 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE HACIENDA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q11 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE JUSTICIA Y PAZ'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q12 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE LA PRESIDENCIA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q13 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE OBRAS PUBLICAS Y TRANSPORTE'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q14 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE RELACIONES EXTERIORES Y CULTO'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q15 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE SALUD'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q16 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE SEGURIDAD PUBLICA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q17 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE TRABAJO Y SEGURIDAD SOCIAL'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q18 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'MINISTERIO DE VIVIENDA Y ASENTAMIENTOS HUMANOS'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q19 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'PRESIDENCIA DE LA REPUBLICA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q20 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'REGISTRO NACIONAL (PRESUPUESTO JUNTA ADMTIVA)'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q21 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'TRIBUNAL SUPREMO ELECCIONES'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q22 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'DIRECCION NACIONAL PENSIONES'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q23 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'DIRECCION GENERAL DE MIGRACION Y EXTRANJERIA'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );
    const q24 = query(collection(db, "usuarios"),
      where("active", "==", true),
      where('institucion', "==", 'IMPRENTA NACIONAL'),
      where("ingreso", ">", Timestamp.fromDate(d)),
      where("ingreso", "<", Timestamp.fromDate(e))
    );



    var res = await Promise.all([
      getDocs(q1),
      getDocs(q2),
      getDocs(q3),
      getDocs(q4),
      getDocs(q5),
      getDocs(q6),
      getDocs(q7),
      getDocs(q8),
      getDocs(q9),
      getDocs(q10),
      getDocs(q11),
      getDocs(q12),
      getDocs(q13),
      getDocs(q14),
      getDocs(q15),
      getDocs(q16),
      getDocs(q17),
      getDocs(q18),
      getDocs(q19),
      getDocs(q20),
      getDocs(q21),
      getDocs(q22),
      getDocs(q23),
      getDocs(q24)
    ]);

    var array = []

    res.forEach(snap => {
      console.log(snap);
      snap.forEach(d => {
        console.log(d.data());

        var data = d.data();

        var startDate = new Date(data.ingreso.seconds * 1000);
        var expireDate = new Date(data.expira.seconds * 1000);
        var nowDate = Date.now();
        var isActive = expireDate.getTime() > nowDate;

        var cuota = data.salario * 0.0125;

        var provincia = (typeof (data.provincia) === 'undefined') ? '' : data.provincia;
        var canton = (typeof (data.provincia) === 'undefined') ? '' : data.canton;
        var distrito = (typeof (data.provincia) === 'undefined') ? '' : data.distrito;

        var parsedStartDate = data.ingreso && startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);

        console.log(provincia);
        var dataObject = {
          key: doc.id,
          id: data.id,
          cedula: data.id,
          type: 'AFIL',
          // carne: data.carne,
          // name: data.nombre,
          nombre: data.nombre,
          lastname: data.apellidos,
          email: data.email,
          dependencia: data.dependencia,
          departamento: data.departamento,
          institucion: data.institucion,
          estado: isActive,
          // salario: data.salario,
          // cuota: cuota,
          fechaAfiliacion: parsedStartDate,
          fechExpiracion: expireDate.toLocaleDateString(),
          puesto: data.puesto,
          // telCel: data.telCel,
          provincia: provincia,
          canton: canton,
          distrito: distrito
        }
        array.push(dataObject);

      })
    });

    console.log(array);
    setDataList(array);
    // const querySnapshot = await getDocs(q1);

    // querySnapshot.forEach(d => {
    //   console.log(d.data());
    // })
  }

  useEffect(() => {
    async function fetchData() {

      try {

        var polizaVTM525 = "TO7LtGbsjm4XGgLuJ0Af";

        const docSnap = await getDoc(doc(db, "polizas", polizaVTM525));

        if (docSnap.exists()) {
          var array = [];
          console.log("Document data:", docSnap.data());

          const q = query(collection(db, "polizas/" + polizaVTM525 + "/usuarios"));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            console.log(doc.id);
            array.push({
              key: doc.id,
              id: parseInt(doc.data().afiliado),
              ref: docSnap.data().id,
              monto: docSnap.data().monto,
              deduccion: docSnap.data().deduccion,
              active: doc.data().active,
              beneficiarios: doc.data().beneficiarios
            });
          });

        } else {
          console.log("No such document!");
        }



      } catch (error) {
        console.log(error)
      }
      setTimeout(() => {
        console.log(array)
      }, 5000);

      setPolizasList(array);

    }
    fetchData();
  }, []);

  return (
    <div className='page'>

      <div className='data-header'>
        <div className='report-header'>
          <h1>Reportes</h1>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <button className='filled-tonal-button' onClick={generateNormal}><img src={xlsx} height="24px" style={{ marginRight: '0.5rem' }} />Descargar</button>
          </div>
        </div>
      </div>
      <p style={{ margin: "1rem 0" }}>Con esta herramienta puedes descargar la informacion de la base de datos de los afiliacios de forma masiva, por institucion y de forma general.</p>

      <h2 style={{ margin: '1rem 0' }}>Institucion</h2>

      <form className='data-search-form' onSubmit={search}>

        <div className='report-search-grid'>

          {/* <div>
            <label htmlFor="criteria">Criterio:</label>

            <select id="criteria" value={searchCriteria} onChange={e => setSearchCriteria(e.target.value)}>
              <option value="institucion">Institucion</option>
              <option value="id">ID</option>
              <option value="puesto">Puesto</option>
              <option value="provincia">Provincia</option>
              <option value="promotor">Promotor</option>
            </select>
          </div> */}
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
            <label htmlFor="select-institucion">Institucion:</label>
            <select id="select-institucion" value={searchValue} onChange={e => setSearchValue(e.target.value)} style={{ width: '200px' }}>
              <option value="" disabled>---Selecciona una Institucion---</option>
              {instList}
            </select>
          </div>
        </div>
        <button type='submit' className='filled-button'>Generar institucion</button>

      </form>

      <h2 style={{ marginTop: '1rem' }}>General</h2>

      <div style={{ display: 'flex', gap: '1rem', margin: '1rem 0', flexWrap: 'wrap' }}>

        <button className='filled-button' onClick={getEveryone}>Generar general</button>

        <button className='filled-tonal-button' onClick={generateNormalGeneral}>Descargar solo para general</button>

      </div>

      {loadingGeneral && <h3>Procesando, por favor espere...</h3>}

      <div style={{ overflowX: 'auto' }}>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Al dia</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Institucion</th>
              <th>Dependencia</th>
              <th>Departamento</th>
              <th>Puesto</th>
              <th>Nacimiento</th>
              <th>Inicio Afiliacion</th>
              <th>Email</th>
              <th>Tel. Casa</th>
              <th>Tel. Celular</th>
              <th>Tel. Oficina</th>
              <th>Expira</th>
              <th>Provincia</th>
              <th>Canton</th>
              <th>Distrito</th>
              <th>Perfil</th>
            </tr>
          </thead>
          {list}
        </table>
      </div>

      {
        generalDataList.length > 0 && <h3>{generalDataList.length} datos fueron consultados y estan listos para descargar</h3>
      }

    </div>
  );
}